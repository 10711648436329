<!--
时间:2023/9/8/008
用法:登陆提示的弹窗
-->

<template>
  <PopupTemplate :show="loginShow" @close="close">
    <template v-slot:default>
      <div class="box">
        <!-- A8的LOGO登陆才显示-->
        <img v-if="status <= 2" class="logo" src="../assets/icon/logo@2x.png" alt="">
        <span v-if="status > 2" class="title">{{ $t(titleText) }}</span>
        <div class="tab" v-if="status < 3 && status !== 1">
          <div class="tabBtn" :class="{'tabActive':status === 2}" @click="changeLogin(2)">
            {{ $t(`nav.passwordLogin`) }}
          </div>
          <div class="tabBtn" :class="{'tabActive':status === -1}" @click="changeLogin(-1)">
            {{ $t(`nav.verificationCdLogin`) }}
          </div>
        </div>
        <!-- 注册-->
        <div class="register">
          <!-- 昵称-->
          <input v-if="status === 3" type="text" :placeholder="$t(`nav.pleaseEntrANickname`)" v-model="userNickName">
            <!-- 实名-->
<!--            <input v-if="status === 3" type="text" :placeholder="$t(`nav.pleaseEntrARealname`)" v-model="userRealName">-->
          <!--邮箱-->
          <input v-if="status > 1 || status === -1" type="text" maxlength="11"
                 :placeholder="$t(`nav.pleaseEntrYourEmailAddr`)"
                 v-model="eMail">
          <!--验证码-->
          <div class="verificationCode" v-if="status > 2 || status === -1">
            <input type="text" :placeholder="$t(`nav.pleaseEntrTheVerificationCd`)" v-model="userCode" maxlength="6">
            <div class="cntDn" :class="{'not':ss !== 0}" @click="getVerification">
              <span class="ss" v-if="ss">{{ ss }}S</span>
              <span class="give" v-else>{{ $t(`nav.obtainVerificationCd`) }}</span>
            </div>
          </div>
          <!--密码-->
          <input v-if="status > 1" type="password" :placeholder="$t(`nav.pleaseOutputTheInptPsw`)"
                 v-model="password" maxlength="20">
          <!--确认密码-->
          <input v-if="status > 2" type="password" :placeholder="$t(`nav.pleaseConfThePsw`)" v-model="confPassword"
                 maxlength="20">
          <!--邀请码-->
          <input v-if="status === 3" type="text" :placeholder="$t(`nav.pleaseEntrTheInvtCd`)" v-model="inviteCode" disabled
                 maxlength="6">
        </div>

        <!--勾选的用户协议-->
        <div v-show="status === 1" class="tips">{{ $t(`nav.loginTitle`) }}</div>
        <div v-if="status <= 2" class="usrAgreement">
          <div class="checkBox" @click="agree(1)">
          <span class="check"
                :class="{'active':adult}">
          </span>
            <span>
            {{ $t(`nav.my18`) }}
          </span>
          </div>
          <div class="checkBox" @click="agree(2)">
          <span class="check"
                :class="{'active':pact}">
          </span>
            <span>{{ $t(`nav.agree`) }}</span>
            <span class="link" @click.stop="goPath('/rule/usrAgreement')">{{ $t(`nav.usrAgreement`) }}</span>
            <span class="link" @click.stop="goPath('/rule/privacyAgreement')">{{ $t(`nav.privacyAgreement`) }}</span>
            <!--            <span class="link">{{ $t(`nav.antiMoneyLaundering`) }}</span>-->
          </div>
        </div>
        <div class="btn" :class="{'other':status>2}" @click="login">
          <!--steam登陆才显示的logo-->
          <img v-show="status === 1" class="steamImg" src="../assets/icon/steam@2x.png" alt="">
          <span>{{ $t(btnText) }}</span>
        </div>

        <div class="change" v-if="status === 1">
          <div class="changeLeft">
            <span>{{ $t(`nav.noSteam`) }}</span>
            <span class="orange click" @click="changeLogin(2)">{{ $t(`nav.emailLogin`) }}</span>
          </div>
          <div class="changeRight click" @click="changeLogin(3)">
            {{ $t(`nav.register`) }}
          </div>
        </div>
        <div class="change" v-else-if="status === 2 || status === -1">
          <div class="changeLeft">
<!--          <span class="status2" @click="changeLogin(1)">-->
<!--            <SvgIcon icon-class="steam" class-name="steam"/>-->
<!--            <span class="click">-->
<!--              {{ $t(`nav.steamLogin`) }}-->
<!--            </span>-->
<!--          </span>-->
            <span class="click" @click="changeLogin(3)">{{ $t(`nav.register`) }}</span>
          </div>
          <div class="changeRight click" @click="changeLogin(4)">
            {{ $t(`nav.forgetPsw`) }}
          </div>
        </div>
        <div class="change" v-else-if="status === 3">
<!--          <div class="changeLeft">-->
<!--          <span @click="changeLogin(1)">-->
<!--           <SvgIcon icon-class="steam" class-name="steam"/>-->
<!--            <span class="click">{{ $t(`nav.steamLogin`) }}</span>-->
<!--          </span>-->
<!--          </div>-->
          <div class="changeRight click" @click="changeLogin(2)">
            {{ $t(`nav.emailLogin`) }}
          </div>
        </div>
        <div class="change" v-else-if="status === 4">
          <div class="changeLeft">
<!--            <span class="status2" @click="changeLogin(1)">-->
<!--            <SvgIcon icon-class="steam" class-name="steam"/>-->
<!--            <span class="click">-->
<!--              {{ $t(`nav.steamLogin`) }}-->
<!--            </span>-->
<!--          </span>-->
            <span class="click" @click="changeLogin(2)">{{ $t(`nav.emailLogin`) }}</span>
          </div>
          <div class="changeRight click" @click="changeLogin(3)">
            {{ $t(`nav.register`) }}
          </div>
        </div>
      </div>
    </template>
  </PopupTemplate>
</template>

<script>
import {mapMutations, mapState} from "vuex";
import {getSteamLoginUrl, getVerification, phoneRegister, phoneLogin, phoneForget} from "@/axios/auth";
import {countDown, randomName} from "@/utils/math";
import {userNickname, az, AZ, To9} from "@/common/config";
import SvgIcon from "@/components/IconComponents/SvgIcon.vue";
import {goPath} from "@/router/goPath";
import PopupTemplate from "@/components/popup/PopupTemplate.vue";

const regex = /^1[3456789]\d{9}$/;

export default {
  name: "LoginPopup",
  components: {PopupTemplate, SvgIcon},
  computed: {
    ...mapState(['loginShow']),
    btnText() {
      switch (this.status) {
        case 1:
          return `nav.login`
        case 2:
          return `nav.login`
        case 3:
          return "nav.register"
        case 4:
          return "nav.conf"
        case -1:
          return `nav.login`
        default:
          return ""
      }
    },
    titleText() {
      switch (this.status) {
        case 3:
          return "nav.register"
        case 4:
          return "nav.retrievePsw"
        default:
          return ""
      }
    }
  },
  watch:{
    loginShow(news){
      if(news){
        this.urlParams = JSON.parse(localStorage.getItem('urlParams')) || {}
      }
    }
  },
  data() {
    return {
      adult: true,//是否满18岁
      pact: true,//
      userNickName: '',//昵称
      // userRealName: '',//真实姓名

      eMail: '',//邮箱
      password: '',//密码
      confPassword: "",//确认密码
      theSrvCode: "",//服务器验证码
      userCode: "",//用户输入验证码
      inviteCode: '',//用户邀请码
      status: 2,// 1、steam登陆 2、邮箱登陆 3、邮箱注册 4、找回密码 -1:手机验证码登陆
      ss: 0,
      urlParams:JSON.parse(localStorage.getItem('urlParams')) || {}
    }
  },
  methods: {
    goPath(path) {
      goPath(path)
      this.close()
    },
    ...mapMutations(['setLoginShow']),
    getUrlParams(){
      this.urlParams = JSON.parse(localStorage.getItem('urlParams')) || {}
      if(!this.inviteCode) this.inviteCode = this.urlParams.invitationCode
    },
    async phoneForget() {
      try {
        const params = {
          mobile: this.eMail,
          passWord: this.password,
          code: this.userCode
        }
        let data = await phoneForget(params)
        this.$Msg.success(this.$t(`confirm.pswModSuccessful`))
        this.reset()
        this.status = 2
        console.log("phoneForget", data)
      } catch (e) {
        console.log(e)
      }
    },
    // 手机号登陆
    async phoneLogin() {
      try {
        const params = {
          ...this.urlParams,
          mobile: this.eMail,
          passWord: this.password,
          code: this.userCode,
        }
        let data = await phoneLogin(params)
        localStorage.setItem('token', data.token)
        if(data.registerFlag !== 0){
          localStorage.setItem('fromPath', '/information')
        }
        // this.$store.dispatch('actionUserInfo')
        if (this.$isAndroid()) {
          console.log('安卓')
          AndroidFunction.login(params.email)
        } else {
          console.log('不是安卓')
        }
        let information = localStorage.getItem('information')
        let fromPath = localStorage.getItem('fromPath')
        localStorage.removeItem('information')
        localStorage.removeItem('fromPath')
        if (information) {
         await this.$router.push(information)
        }else if(fromPath){
          await this.$router.push(fromPath)
        }
        location.reload()
        this.$Msg.success(this.$t(`confirm.loginSuccessful`))
        console.log("phoneLogin", data)
      } catch (e) {
        console.log(e)
      }
    },
    //手机号注册
    async phoneRegister() {
      try {
        const params = {
          ...this.urlParams,
          nickName: this.userNickName,
          mobile: this.eMail,
          passWord: this.password,
          code: this.userCode,
          invitationCode: this.inviteCode,
        }
        console.log(params)
        let data = await phoneRegister(params)
        this.changeLogin(2)
        this.$Msg.success(this.$t(`confirm.loginWasSuccessful`))
        localStorage.setItem('information', '/information')
        console.log("phoneRegister", data)
      } catch (e) {
        console.log(e)
      }
    },
    async getVerification() {
      if (this.ss !== 0) return
      if (!this.eMail || !regex.test(this.eMail)) return this.$Msg.error(this.$t(`confirm.emailFmtErr`))
      try {
        const params = {
          mobile: this.eMail
        }
        let data = await getVerification(params)
        this.ss = 60
        this.countDown(59000)
        this.$Msg.success(this.$t(`confirm.vSntSuccess`))
        console.log("getVerification", data)
      } catch (e) {
        console.log(e)
      }
    },
    reset() {
      this.randomName()
      // this.eMail = ''
      this.password = ''
      this.confPassword = ''
      this.userCode = ''
      // this.inviteCode = ''
    },
    verification() {
      // 登陆验证是否勾选协议
      if (this.status <= 2) {
        if (!this.adult || !this.pact) {
          return this.$Msg.warn(this.$t(`confirm.agreeToATreaty`))
        }
      }
      //注册和找回密码验证邮箱、密码、验证码
      if (this.status > 2) {
        if (!this.eMail || !regex.test(this.eMail)) {
          return this.$Msg.error(this.$t(`confirm.emailFmtErr`))
        }
        if (this.password.length < 6) {
          return this.$Msg.error(this.$t(`confirm.theMinPswLenIs6`))
        }
        if (this.password !== this.confPassword) {
          return this.$Msg.error(this.$t(`confirm.pswInconsistency`))
        }
        if (!this.userCode) {
          return this.$Msg.error(this.$t(`confirm.entrTheVerificationCd`))
        }
      }

      //邮箱登陆登陆验证格式
      if (this.status === 2) {
        if (!this.eMail || !regex.test(this.eMail)) {
          return this.$Msg.error(this.$t(`confirm.emailFmtErr`))
        }

      }
      //注册验证用户昵称
      if (this.status === 3) {
        if (!this.userNickName) {
          return this.$Msg.error(this.$t(`confirm.pleaseEntrANickname`))
        }
      }

      return true
    },
    randomName() {
      this.userNickName = randomName(userNickname, az, AZ, To9)
    },
    countDown(duration) {
      countDown(duration, (dd, hh, mm, ss) => {
        this.ss = ss
      })
    },
    changeLogin(status) {
      this.reset()
      this.getUrlParams()
      this.status = status
    },
    agree(index) {
      switch (index) {
        case 1:
          this.adult = !this.adult
          break;
        case 2:
          this.pact = !this.pact
          break;
      }
    },
    async steamLogin() {
      try {
        const params = {
          ...this.urlParams
        }

        let data = await getSteamLoginUrl(params)
        console.log(params, data)
        debugger
        window.location.href = data
      } catch (e) {
        console.log(e)
      }
    },
    login() {
      if (!this.verification()) return
      this.getUrlParams()
      switch (this.status) {
        case 1://steam登陆
          // this.steamLogin()
          localStorage.setItem('fromPath', this.$route.fullPath);
          break;
        case 2:
          this.phoneLogin()
          break;
        case 3:
          this.phoneRegister()
          break;
        case 4:
          this.phoneForget()
          break;
        case -1:
          this.phoneLogin()
      }
    },
    close() {
      this.reset()
      this.status = 2
      this.setLoginShow(false)
    }
  },
}
</script>

<style scoped lang="scss">
@import "@/style/input";

.LoginPopup {
  z-index: 999;

  .loginContainer {
    width: 25rem;
    //height: 17.98rem;
    border-radius: 0.42rem;
    padding: 3rem 5.5rem;
    box-sizing: border-box;
    background: url('../assets/home/loginbg.jpg') no-repeat center/cover;
    font-weight: 400;
    @include center;
    flex-direction: column;

    .close {
      width: 0.69rem;
      position: absolute;
      top: 0.63rem;
      right: 0.63rem;
      cursor: pointer;
    }
  }

  @media screen and (max-width: $windowSize-md) {
    .loginContainer {
      width: 26.46rem;
      height: auto;
      border-radius: 0.42rem;
      padding: 2.9rem 3.5rem 1.9rem 3.5rem;
      box-sizing: border-box;
      background: url('../assets/home/loginbg.jpg') no-repeat center/cover;
      font-weight: 400;
      max-height: 100%;
      overflow-y: auto;
      @include center;

      .close {
        width: 1.19rem;
        position: absolute;
        top: 1rem;
        right: 1rem;
        cursor: pointer;
      }


    }

  }
}

.box {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: 25rem;
  //height: 17.98rem;
  border-radius: 0.42rem;
  padding: 2rem 3rem;
  box-sizing: border-box;

  .logo {
    width: 13.75rem;
    margin: 0 auto 1.5rem auto;

  }

  .title {
    font-size: 1rem;
    font-weight: bold;
    margin-bottom: 0.88rem;
  }

  .tab {
    @include center;
    font-weight: bold;
    font-size: 0.75rem;
    margin-bottom: 0.5rem;
    align-self: center;

    .tabBtn {
      margin-right: 0.5rem;
      cursor: pointer;
    }

    .tabActive {
      color: #FBC600;

      &::after {
        content: '';
        display: block;
        height: 2px;
        width: 100%;
        background-color: #FBC600;

      }
    }
  }

  .register {
    width: 100%;
  }

  .tips {
    margin-bottom: 1rem;
    font-size: 0.67rem;
  }

  .checkBox {
    font-size: 0.58rem;
    color: #CDCDCD;
    /* display: flex;
     align-items: center;*/
    margin-bottom: 0.2rem;
    cursor: pointer;
    user-select: none;

    .check {
      width: 0.6rem;
      height: 0.6rem;
      border: 2px solid #747580;
      border-radius: 0.17rem;
      box-sizing: border-box;
      margin-right: 0.54rem;
      cursor: pointer;
      flex-shrink: 0;
      display: inline-block;
      transform: translateY(1px);
    }

    .active {
      background: #FBC600 url('../assets/icon/check.png') no-repeat center/0.33rem 0.3rem;
      border: 0;
    }

    .link {
      color: #FBC600;
      cursor: pointer;
      text-decoration: underline;
      margin: 0 0.2rem;
    }
  }

  .btn {
    width: 100%;
    height: 2.11rem;
    background: #FBC600;
    border-radius: 0.17rem;
    @include center;
    font-weight: bold;
    font-size: 0.67rem;
    margin-top: 1rem;
    color: #191B29;

    .steamImg {
      width: 0.79rem;
      height: 0.79rem;
      margin-right: 0.46rem;
    }
  }

  .other {
    margin-top: 0;
  }

  .change {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    width: 100%;
    margin-top: 0.88rem;
    font-size: 0.58rem;


    .click {
      cursor: pointer;
      transition: color 0.3s linear;

      &:hover {
        color: #FBC600;
      }
    }

    .status2 {
      position: relative;
      padding-right: 0.5rem;
      margin-right: 0.5rem;

      &::after {
        content: '';
        display: block;
        width: 2px;
        height: 0.54rem;
        background-color: #4B4D54;
        @include p-ab-centerY;
        right: 0;

      }
    }

    .steam {
      width: 0.79rem;
      height: 0.79rem;
      margin-right: 0.25rem;
    }
  }

  @media screen and (max-width: $windowSize-md) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding: 2rem;
    .logo {
      width: 13.75rem;
      margin: 0.63rem auto 1.5rem auto;

    }
    .tab {
      @include center;
      font-weight: bold;
      font-size: 1.2rem;
      margin-bottom: 1rem;
      align-self: center;

      .tabBtn {
        margin-right: 0.5rem;
        cursor: pointer;
      }
    }

    .tips {
      margin-bottom: 1rem;
      font-size: 1rem;
    }

    .checkBox {
      font-size: 1rem;
      color: #CDCDCD;
      margin-bottom: 0.2rem;

      .check {
        width: 0.99rem;
        height: 0.99rem;
        border: 1px solid #747580;
        border-radius: 0.17rem;
        box-sizing: border-box;
        margin-right: 0.54rem;
        cursor: pointer;

      }


      .active {
        background: #FBC600 url('../assets/icon/check.png') no-repeat center/0.54rem 0.48rem;
        border: 0;
      }

      .link {
        color: #FBC300;
      }
    }

    .btn {
      width: 100%;
      height: 3rem;
      background: #FBC600;
      border-radius: 0.33rem;
      @include center;
      font-weight: bold;
      font-size: 1.08rem;
      margin-top: 1rem;
      color: #191B29;

      .steamImg {
        width: 1.17rem;
        height: 1.17rem;
        margin-right: 0.46rem;
      }
    }

    .other {
      margin-top: 0;
    }

    .change {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      margin-top: 1.2rem;
      font-size: 1rem;

      .changeLeft {
        margin-bottom: 0.6rem;
      }

      .click {
        cursor: pointer;
        transition: color 0.3s linear;

        &:hover {
          color: #FBC600;
        }
      }

      .status2 {
        position: relative;
        padding-right: 0.5rem;
        margin-right: 0.5rem;

        &::after {
          content: '';
          display: block;
          width: 2px;
          height: 1.13rem;
          background-color: #4B4D54;
          @include p-ab-centerY;
          right: 0;

        }
      }

      .steam {
        width: 1.04rem;
        height: 1.04rem;
        margin-right: 0.33rem;
      }
    }
  }
}


.verificationCode {
  width: 100%;
  box-sizing: border-box;
  padding-right: 4.4rem;
  position: relative;
  font-size: 0.58rem;

  .cntDn {
    position: absolute;
    top: 0;
    right: 0;
    width: 4rem;
    padding: 0.2rem 0;
    text-align: center;
    box-sizing: border-box;
    color: #FBC600;
    border: 2px solid #FBC600;
    height: 1.83rem;
    @include center;
    cursor: pointer;
    user-select: none;
    transition: background-color 0.3s linear;
    border-radius: 0.17rem;

    &:hover {
      background-color: rgba(251, 198, 0, 0.05);
    }
  }

  .not {
    cursor: not-allowed;
  }

  @media screen and (max-width: $windowSize-md) {
    padding-right: 8rem;
    font-size: 1rem;
    .cntDn {
      height: 2.99rem;
      width: 7rem;
      border-radius: 0.17rem;
    }
  }
}

.orange {
  color: #FBC600;
}
</style>
