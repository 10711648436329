<!--
时间:2023/9/12/012
用法:手机端底部导航栏
-->

<template>
  <div class='TabBar _mobile' id="TabBar">
    <div class="item"
         v-for="(item,index) in list"
         :class="{
           'active':($route.path.indexOf(item.path) > -1 && item.path) ||  $route.path === item.alias,
         }"
         @click="goPath(item.path)"
         :key="index">
      <div class="imgBox">
        <img :src="item.path && $route.path.indexOf(item.path) > -1 ||  $route.path === item.alias ? item.imgActive : item.img" alt="">
      </div>
      <div class="name">
        {{$t(`nav.${item.name}`)}}
      </div>
    </div>
  </div>
</template>

<script>
import {goPath} from "@/router/goPath";

export default {
  name: "TabBar",
  data() {
    return {
      list: [
        // {
        //   name: 'battle',
        //   path: '/battle',
        //   img: require('../assets/mobileIcon/battle@2x.png'),
        //   imgActive: require('../assets/mobileIcon/BattlesActive.png'),
        // },

        {
          name: 'dream',
          path: '/dreams',
          img: require('../assets/mobileIcon/Dreams@2x.png'),
          imgActive: require('../assets/mobileIcon/DreamsActive.png'),
        },
        {
          name: 'blindBox',
          path: '/homes',
          alias:'/',
          img: require('../assets/mobileIcon/box.png'),
          imgActive: require('../assets/mobileIcon/boxActive.png'),
        },
        // {
        //   name: 'rollRoom',
        //   path: '/roll',
        //   img: require('../assets/mobileIcon/roll@2x.png'),
        //   imgActive: require('../assets/mobileIcon/rollActive.png'),
        // },
        {
          name: 'information',
          path: '/profile',
          img: require('../assets/mobileIcon/information.png'),
          imgActive: require('../assets/mobileIcon/informationActive.png'),
        },
      ]
    }
  },
  methods: {
    goPath
  }
}
</script>

<style scoped lang="scss">
.TabBar {
  height: var(--mobil-tabbar-height);
  background: #191B23;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  display: flex;
  justify-content: space-between;
  z-index: 666;
  padding-bottom: var(--mobil-tabbar-padding-bottom);
  box-sizing: border-box;
  .item {
    width: 20%;
    display: flex;
    flex-direction: column;
    padding-top: 0.5rem;
    justify-content: center;
    align-items: center;

    .imgBox {
      @include center;
      transition: all 0.2s linear;
      img {
        height: 2rem;
      }
    }

    .name {
      /*display: none;*/
      font-size: 1rem;
      color:$font-color;
      font-weight: bold;
      margin-top: 0.1rem;
      text-align: center;
    }
  }

  .active {

    .imgBox {
      width: 3.79rem;
      height: 3.33rem;
      background: url('../assets/mobileIcon/bgBox.png') no-repeat center/cover;
      transform: translateY(-30%);
    }
    .name {
      color: $theme-color-f600;
      margin-top: -0.9rem;
      display: block;
    }
  }


}
</style>
