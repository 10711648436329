<!--
时间:2023/8/15/015
用法:顶部导航栏
-->

<template>
  <div class='headerNav' id="headerNav">
    <div class="left">
      <img src="../assets/mobileIcon/menuIcon.png"
           alt=""
           @click="showMenu"
           class="menuIcon _mobile">
      <img @click="goPath('/homes',1)" class="logo" src="../assets/icon/logo@2x.png" alt="">
      <SelectLanguage class="_windows" :list="languageList" :value="currentLanguage" @changeLanguage="changeLanguage"/>
      <div class="task _windows" @click="goTask">
        <img src="../assets/home/TaskCenter@2x.png" alt="">
      </div>
    </div>
    <div class="right">
      <div class="navList _windows">
        <div  v-for="(item,index) in navList" :key="index">
          <div class="item" @click="item.fn? item.fn() : goPath(item.path)" v-if="item.flag">
            <img :src="item.img" alt="">
            <span>{{ item.name }}</span>
          </div>
        </div>
       
      </div>
      <div class="user">
        <div v-if="Object.keys(userInfo).length" class="userInfo" id="headerUserInfo">
          <div class="info">
            <p class="userName _windows">{{ userInfo.nickName }}</p>
            <div class="userMoney _tabBtn">
              <MoneyCoin :money="userInfo.money"/>
              <div class="add _mobile" @click="goPath('/recharge',1)">
                <span class="_mobile">{{ $t(`nav.refill`) }}</span>
                <!--                <SvgIcon icon-class="battle-add"/>-->
              </div>
            </div>
          </div>
          <img class="avatar _windows" :src="userInfo.photo" alt="" @click="goPath('/information',1)">
          <div class="menuBtn _windows"
               @click.stop="()=>{windowmenu = !windowmenu}"
               :class="{'open':windowmenu,'close':!windowmenu}">
            <div class="line"></div>
            <div class="line"></div>
            <div class="line"></div>
          </div>
          <transition>
            <div v-show="windowmenu" class="_windows menuList animate__animated"
                 :class="windowmenu ? 'animate__bounceInRight':'animate__bounceOutRight'">
              <div class="menuTop">
                <img :src="userInfo.photo" alt="">
                <div>
                  <p>{{ userInfo.nickName }}</p>
                  <div class="vipProgress"></div>
                </div>
              </div>
              <div class="menuContent">
                <div class="menuItem"
                     v-for="(item,index) in menuList"
                     :class="{'active':$route.path === item.path}"
                     @click.stop="goPath(item.path,1)"
                     :key="index">
                  <img :src="$route.path === item.path ? item.img2 : item.img" alt="">
                  <span>{{ $t(`nav.${item.name}`) }}</span>
                </div>
              </div>
              <!--   设置全局音乐-->
              <div class="setAudio"
                   :class="userInfo.soundEffects === '0' ? 'openAudio' : 'closeAudio'"
                   @click="updateUserInfo">
                <span>{{
                    userInfo.soundEffects === '0' ? $t(`nav.glblMusicEnabled`) : $t(`nav.soundEffectsDisabled`)
                  }}</span>
                <img class="audioImg"
                     :src="userInfo.soundEffects === '0' ?
                 require('../assets/icon/audioOpen.png') :
                  require('../assets/icon/audioClose.png')" alt="">
              </div>
              <div class="menuBottom">
                <div class="logout" @click="logout">
                  <SvgIcon icon-class="guanji"/>
                  <span>{{ $t(`nav.logout`) }}</span>
                </div>
              </div>
            </div>
          </transition>
        </div>
        <div v-else class="login" @click="login">
          <img src="../assets/icon/steam@2x.png" alt="">
          <span>
            {{ $t(`nav.login`) }}
          </span>
        </div>
      </div>
    </div>
  </div>
  <LoginPopup/>
  <CDKPopup
      :cdkShow="cdkShow"
      @close="ckdClose"
  />

  <MenuList
      :show="menuShow"
      @login="login"
      @setAudio="updateUserInfo"
      @close="menuClose"/>
  <AppDownLoad
      :show="downloadShow"
      @close="closeDownload"/>
</template>

<script>
import {mapMutations} from "vuex";
import SelectLanguage from "@/components/ToolsComponents/SelectLanguage.vue";
import {language} from "@/common/config";
import {mapState} from "vuex";
import LoginPopup from "@/components/LoginPopup.vue";
import MenuList from "@/layout/MenuList.vue";
import CDKPopup from "@/components/CDKPopup.vue";

import MoneyCoin from "@/components/IconComponents/MoneyCoin.vue";
import SvgIcon from "@/components/IconComponents/SvgIcon.vue";
import {goPath} from "@/router/goPath";
import {updateUserInfo} from "@/axios/information";
import AppDownLoad from "@/components/AppDownLoad.vue";

export default {
  name: "Nav",
  provide() {
    return {
      cdkShowFun: this.cdkShowFun,
      showDownload:this.showDownload,
    };
  },
  computed: {
    ...mapState(['userInfo','getByUrl','timeBoxData', 'minValue']),
  },
  watch: {
    'timeBoxData': {
      handler(newVal) {
        if(newVal.length > 0){
          this.navList[1].flag = true
        }else{
          this.navList[1].flag = false
        }
      },
      immediate: true
    },
    'minValue': {
      handler(newVal) {
        if(newVal == 0){
          this.navList[2].flag = false
        }else{
          this.navList[2].flag = true
        }
      },
      immediate: true
    },
    'getByUrl': {
      handler(newVal) {
        if(newVal.appDownload == 1){
          this.navList[0].flag = true
        }else{
          this.navList[0].flag = false
        }
      },
      deep: true,
      immediate: true
    },
  },
  components: {AppDownLoad, SvgIcon, MoneyCoin, MenuList, LoginPopup, SelectLanguage, CDKPopup},
  data() {
    return {
      windowmenu: false,
      menuShow: false,
      cdkShow: false,

      downloadShow: false,
      languageList: language,
      showAPP:false,//是否展示APP下载
      currentLanguage: localStorage.getItem('language'),
      //type 1、站内跳转 2、cdk 3、活动大厅 4、app下载弹窗
      navList: [
        {
          name: "APP",
          path: "",
          img: require('../assets/icon/app@2x.png'),
          fn: () => {
            this.showDownload()
          },
          flag: false
        },
        {
          name: "时间盲盒",
          path: "/timeBox",
          img: require('../assets/icon/shijian.png'),
          flag: false
        },
        {
          name: "汰换",
          path: "/replacement",
          img: require('../assets/icon/taihuan.png'),
          flag: false
        },
        {
          name: "福利",
          path: "/information/welfare",
          img: require('../assets/icon/vip@2x.png'),
          flag: true
        },
        {
          name: "免费",
          path: "/",
          img: require('../assets/icon/free@2x.png'),
          flag: true,
          fn: () => {
            this.goTask()
          }
        },
        {
          name: "充值",
          path: "/recharge",
          img: require('../assets/icon/a8@2x.png'),
          flag: true
        },
        {
          name: "CDK",
          path: "cdkShowFun",
          img: require('../assets/icon/cdk@2x.png'),
          flag: true,
          fn: () => {
            this.cdkShowFun()
          }
        },
        /* {
           name: "QQ群",
           path: "",
           img: require('../assets/icon/qq@2x.png'),
         },*/
        {
          name: "客服",
          path: "/",
          img: require('../assets/icon/csp@2x.png'),
          flag: true,
          fn: () => {
            window.open(this.getByUrl.serviceUrl,'_blank')
          }
        },
      ],
      menuList: [
        {
          name: "mySkins",
          img: require('../assets/information/wupin.png'),
          img2: require('../assets/information/wupin2.png'),
          path: '/information',
        },
        {
          name: "myWelfare",
          img: require('../assets/information/fuli.png'),
          img2: require('../assets/information/fuli2.png'),
          path: '/information/welfare',
        },
        {
          name: "myReward",
          img: require('../assets/information/yongjin.png'),
          img2: require('../assets/information/yongjin2.png'),
          path: '/information/share',
        },
        {
          name: "historicalBilling",
          img: require('../assets/information/recharge.png'),
          img2: require('../assets/information/recharge2.png'),
          path: '/information/income',
        },
      ],
    }
  },
  methods: {
    ...mapMutations(['setLoginShow', 'setCDK', 'setLoginShow', 'setActiveShow']),
    goTask() {
      if (!localStorage.getItem('token')) {
        return this.setLoginShow(true)
      }
      this.setActiveShow(true)
    },
    async updateUserInfo() {
      try {
        const params = {
          soundEffects: this.userInfo.soundEffects === '0' ? '1' : '0'
        }
        let data = await updateUserInfo(params)
        this.$store.dispatch('actionUserInfo')
        console.log("updateUserInfo", data)
      } catch (e) {
        console.log(e)
      }
    },
    login() {
      this.menuShow = false
      this.setLoginShow(true)
    },
    cdkShowFun() {
      this.cdkShow = true
    },
    ckdClose() {
      this.cdkShow = false
    },
    menuClose() {
      this.menuShow = false
    },
    showMenu() {
      this.menuShow = true
    },
    showDownload() {
      this.downloadShow = true
    },
    closeDownload() {
      this.downloadShow = false
    },
    goPath(path) {
      goPath(path)
    },
    changeLanguage(val) {
      console.log(val)
      this.currentLanguage = val
      localStorage.setItem('language', val)
      location.reload()
    },
    logout() {
      localStorage.removeItem('token')
      window.location.reload()
    },
    closeMenu(e) {
      let userInfo = document.getElementById('headerUserInfo')
      if (!userInfo) return
      if (!userInfo.contains(e.target)) {
        this.windowmenu = false;
      }
    },
  },
  mounted() {
    window.addEventListener('click', this.closeMenu)
  },
  beforeUnmount() {

    window.removeEventListener('click', this.closeMenu)
  }
}
</script>

<style scoped lang="scss">
.headerNav {
  height: 3.77rem;
  margin-bottom: 0.17rem;
  padding-left: 0.79rem;
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  background-color: #1A1C24;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  width: 100vw;
  box-sizing: border-box;

  .left {
    display: flex;
    align-items: center;

    .menuIcon {

    }

    .logo {
      width: 8.21rem;
      height: 1.67rem;
      margin-right: 0.5rem;
      cursor: pointer;
    }


    .task {
      img {
        height: 3.79rem;
        width: auto;
        cursor: pointer;
      }
    }
  }

  .right {
    display: flex;
    justify-content: space-between;


    .navList {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .item {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 3.24rem;
        cursor: pointer;

        img {
          width: 1.54rem;
          height: 1.42rem;
          margin-bottom: 0.28rem;
        }

        span {
          font-size: 0.67rem;
        }
      }
    }

    .user {
      display: flex;
      align-items: center;

      .userInfo {
        display: flex;
        align-items: center;
        padding-right: 0.83rem;
        position: relative;

        .info {
          margin-right: 0.35rem;

          .userName {
            text-align: right;
            font-size: 0.58rem;

          }

          .userMoney {
            display: flex;
            align-items: center;
            height: 1.25rem;
            border-radius: 0.63rem;
            font-size: 0.58rem;
            padding: 0 0.33rem;
            margin-top: 0.2rem;
            cursor: pointer;

            .add {
              width: 1rem;
              height: 1rem;
              background: #FFC600;
              border-radius: 50%;
              @include center;
              color: #3C3006;
              margin-left: 0.33rem;
            }

          }
        }


        .avatar {
          width: 2rem;
          height: 2rem;
          border-radius: 50%;
          cursor: pointer;
        }

        .menuBtn {
          margin-left: 0.88rem;
          position: relative;
          cursor: pointer;
          width: 0.67rem;
          height: 1rem;

          .line {
            width: 100%;
            height: 2px;
            background-color: #666;
            position: absolute;
            top: calc(50% - 2px);
            transition: all 0.3s linear;

            &:nth-child(1) {
              transform: translateY(-0.3rem) rotate(0);
            }

            &:nth-child(2) {
              width: 0.5rem;
              opacity: 1;
            }

            &:nth-child(3) {
              transform: translateY(0.3rem) rotate(0);
            }

          }

          &:hover {
            .line {
              background-color: #a9a9a9;
            }
          }
        }

        .open {
          .line {
            &:nth-child(1) {
              transform: translateY(0) rotate(45deg);
            }

            &:nth-child(2) {
              width: 100%;
              opacity: 0;
            }

            &:nth-child(3) {
              transform: translateY(0) rotate(-45deg);
            }
          }
        }

        .menuList {
          background-color: #1A1C24;
          border-radius: 0.33rem;
          position: absolute;
          right: 0;
          top: calc(var(--window-headerNav-height) - 0.8rem);
          padding: 0.33rem 0.33rem 0 0.33rem;
          width: 9.8rem;
          font-size: 0.58rem;

          .menuTop {
            padding: 0.33rem;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            margin-bottom: 0.5rem;

            img {
              width: 1.92rem;
              height: 1.92rem;
              border-radius: 50%;
              margin-right: 0.54rem;
            }

            .vipProgress {
              width: 5rem;
              border-radius: 0.5rem;
              height: 0.2rem;
              background-color: #C49C61;
              margin-top: 0.2rem;
            }
          }

          .menuContent {
            background-color: #26282F;
            border-radius: 0.25rem;

            .menuItem {
              height: 2.14rem;
              display: flex;
              align-items: center;
              border-radius: 0.25rem;
              padding: 0 0.63rem;
              font-size: 0.58rem;
              cursor: pointer;

              img {
                margin-right: 0.38rem;
                width: 0.83rem;
              }

              &:hover {
                background-color: rgba(255, 255, 255, 0.05);
              }

            }

            .active {
              background-color: rgba(255, 255, 255, 0.05);
              color: $theme-color-f600;
            }
          }

          .setAudio {
            height: 1.84rem;
            background: rgba(129, 27, 255, 0.3);
            border: 2px solid #811BFF;
            border-radius: 0.33rem;
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 0 0.67rem;
            margin-top: 0.4rem;
            cursor: pointer;
            box-sizing: border-box;

            .audioImg {
              width: 1.06rem;
            }
          }

          .openAudio {

          }

          .closeAudio {
            background: rgba(255, 255, 255, 0.1);
            opacity: 0.5;
            border: 0;
          }

          .menuBottom {
            width: 9.73rem;
            height: 2.95rem;
            background: url('../assets/information/bj@2x.png') no-repeat center /cover;
            color: #9BA0B1;
            display: flex;
            flex-direction: row-reverse;
            align-items: center;

            .logout {
              padding: 0.5rem 1rem;
              cursor: pointer;

              span {
                margin-left: 0.2rem;
              }

              &:hover {
                color: #FFFFFF;
              }
            }
          }
        }

      }

      .login {
        width: 4.46rem;
        height: 3.68rem;
        background: $theme-color-f600;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        cursor: pointer;

        img {
          width: 1.42rem;
          height: 1.42rem;
          margin-bottom: 0.36rem;
        }

        span {
          font-size: 0.67rem;
          font-weight: bold;
          color: #000000;
        }
      }
    }
  }
}

@media screen and (max-width: $windowSize-md) {
  .headerNav {
    padding: 0 0.8rem;

    .left {
      .menuIcon {

        width: 1.29rem;
        margin-right: 0.67rem;

      }
    }

    .right {


      .user {

        .userInfo {
          display: flex;
          align-items: center;
          cursor: pointer;
          padding-right: 0;

          .coin {
            width: 1.67rem;
          }

          .money {
            font-size: 1.08rem;
            color: $theme-color-f600;
            font-weight: bold;
            padding-right: 0.5rem
          }

          .info {
            margin-right: 0;

            .userMoney {
              background: transparent;

              .add {
                width: 5.75rem;
                height: 1.8rem;
                background: #FFC600;
                border-radius: 0.33rem;
                font-size: 1.08rem;
                font-weight: bold;
              }

              .MoneyCoin {
                font-size: 1.08rem;
                font-weight: bold;

                :deep(img) {
                  width: 1.67rem;
                  height: 1.71rem;
                }
              }

            }
          }
        }

        .login {
          flex-direction: row;
          width: 7.58rem;
          height: 2.67rem;
          background: $theme-color-f600;
          border-radius: 0.25rem;

          img {
            margin: 0 0.46rem 0 0;
          }

          span {
            font-size: 1.08rem;
          }
        }
      }
    }
  }
}
</style>
