<!--
时间:2023/12/20/020
用法:电脑活动大厅弹窗入口
-->

<template>
  <transition name="fade">
    <div class="ActiveIndex mask animate__animated" v-if="activeShow" :class="{'show':activeShow,'hidde':!activeShow}">
      <transition name="fadeScale">
        <div class="popupContent" v-show="activeShow">
          <img src="../../assets/icon/close.png" alt="" class="close" @click="setActiveShow(false)">
          <div class="navBar">
            <div class="btn navBtn"
                 v-for="(item,index) in navList"
                 :class="{'btnActive':index === btnIndex}"
                 @click="btnIndex = index"
                 :key="index">
              <img :src="item.img" alt="">
              <span>{{ item.name }}</span>
            </div>
          </div>
          <div class="views">
<!--            <div class="topSwiper">-->
<!--&lt;!&ndash;              <img src="../../assets/active/notice.png" alt="">&ndash;&gt;-->
<!--              <div class="swiperBox">-->
<!--                <el-carousel height="1rem" direction="vertical" :autoplay="true" indicator-position="none">-->
<!--                  <el-carousel-item v-for="(item,index) in swiperList" :key="index">-->
<!--                    <p v-html="item.text"></p>-->
<!--                  </el-carousel-item>-->
<!--                </el-carousel>-->
<!--              </div>-->
<!--            </div>-->
            <div class="viewContent" v-if="userInfo.id">
              <!-- 签到-->
              <SignIn v-if="btnIndex === 0"/>
              <!-- 热门活动-->
              <HotEvents v-else-if="btnIndex === 1"/>
              <!-- 任务中心-->
              <MissionCenter v-else-if="btnIndex === 2"/>
            </div>
          </div>
        </div>
      </transition>
    </div>
  </transition>
</template>

<script>
import {getUserTaskReward} from "@/axios/active";
import SignIn from "@/components/Active/SignIn.vue";
import HotEvents from "@/components/Active/HotEvents.vue";
import {mapState,mapMutations} from "vuex";
import MissionCenter from "@/components/Active/MissionCenter.vue";

export default {
  name: "ActiveIndex",
  components: {MissionCenter, HotEvents, SignIn},
  computed:{
    ...mapState(['activeShow','userInfo'])
  },
  data() {
    return {
      navList: [
        {
          name: '每日签到',
          img: require("../../assets/active/qiandao.png"),

        },
        {
          name: '热门活动',
          img: require("../../assets/active/liwu.png"),
        },
        {
          name: '任务中心',
          img: require("../../assets/active/px@2x.png"),
        },
      ],
      btnIndex: 0,
      swiperList: [],
    }
  },
  methods: {
    ...mapMutations(['setActiveShow']),
    async getUserTaskReward() {
      try {
        const params = {
          pageNum: 1,
          pageSize: 10
        }
        let data = await getUserTaskReward(params)
        this.swiperList = data.map(item => {
          item.text = `${item.timeTips}<span>${item.name}</span> Get <span>${item.award} A coins </span>  for completing the <span>${item.task}</span>`
          return item
        })
        console.log("getUserTaskReward", data)
      } catch (e) {
        console.log(e)
      }
    },
  },
  created() {
    if(!localStorage.getItem('token')) return
    this.getUserTaskReward()
  }
}
</script>

<style scoped lang="scss">
.mask {
  width: 100vw;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 900;
  background-color: rgba(0, 0, 0, 0.6);
  @include center;
}

.show {
  background-color: rgba(0, 0, 0, 0.6);
  animation: fadeIn 0.5s;
}

.hidde {
  animation: fadeOut 0.5s;
}

//电脑
 @media screen and (min-width:$windowSize-md){
   .popupContent {
     width: 47rem;
     height: 29.7rem;
     background: url('../../assets/active/activeBg.png') no-repeat center/cover;
     position: relative;
     padding: 6.3rem 1rem 1rem 1.4rem;
     box-sizing: border-box;
     display: flex;

     .close {
       position: absolute;
       top: 1rem;
       right: 1.8em;
       width: 1rem;
       cursor: pointer;
       transition: transform 0.3s linear;

       &:hover {
         transform: rotate(90deg);
       }
     }

     .navBar {
       width: 5rem;
       background-color: rgba(255, 255, 255, 0.05);
       border-radius: 0.42rem;
       margin-right: 1rem;
       flex-shrink: 0;
       .navBtn {
         display: flex;
         flex-direction: column;
         align-items: center;
         justify-content: center;
         height: 5rem;
         font-size: 0.67rem;

         img {
           width: 1.34rem;
           margin-bottom: 0.33rem;
         }
       }

       .btnActive {
         background-color: rgba(255, 255, 255, 0.05);
         border-radius: 0.42rem;
         color: #FFC600;
       }
     }

     .views {
       flex-grow: 1;

       .topSwiper {
         width: 100%;
         height: 1.46rem;
         background: rgba(255, 255, 255, 0.05);
         border-radius: 0.73rem;
         display: flex;
         align-items: center;
         font-size: 0.58rem;
         padding: 0 1rem;
         box-sizing: border-box;

         img {
           width: 0.67rem;
           margin-right: 0.33rem;
         }

         .swiperBox {
           flex-grow: 1;
         }

         :deep(p) {
           height: 100%;
           display: flex;
           align-items: center;

           span {
             color: #FBC600;
             margin: 0 0.2rem;
           }
         }
       }

       .viewContent {
         margin-top: 1rem;
         height: 20rem;
         overflow: auto;
         padding-right: 1rem;
       }
     }
   }
 }

//手机
 @media screen and (max-width:$windowSize-md){
   .mask {
     display: none;
   }

 }

</style>
