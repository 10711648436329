<!--
时间:2023/9/6/006
用法:
-->

<template>
  <div class="cardItem">
    <div class="swiperContent">
      <div class="skins">
        <slot name="top" :slotData="childData"></slot>
      </div>
      <div class="user">
        <slot name="bottom" :slotData="childData"></slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "CardItem",
  props: {
    childData: {
      type: Object,
      default:() => {}
    },
    margin:{
      type:String,
      default:() => '0 0.1rem'
    }
  },
  data() {
    return {}
  },
  methods: {
    cardClick(data) {
      this.$emit('cardClick', data)
    }
  }
}
</script>

<style scoped lang="scss">
.cardItem {
  position: relative;
  overflow: hidden;
  cursor: pointer;
  width: 100%;
  height: 100%;
  &:hover {
    .swiperContent {
      transform: translateY(-50%);
    }
  }
  /*&:active{
    .swiperContent {
      transform: translateY(-50%);
    }
  }*/

  .swiperContent {
    transition: transform 0.5s ease;
    overflow: hidden;

    .skins,
    .user {
      position: relative;
      text-align: center;
    }

    .skins {
      box-sizing: border-box;

      .gun {
        width: 5.5rem;
        height: 2.79rem;
        //height: 1.75rem;
        //width: 60%;
        margin-top: 0.5rem;
        vertical-align: middle;
      }

      .text {
        position: absolute;
        bottom: 0.46rem;
        left: 0.63rem;
        width: 100%;

        .skinEllipsis {
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          -webkit-text-overflow: ellipsis;
          width: 90%;
          text-align: left;
        }

        .skinRoll {
          display: none;

          span {
            margin: 0 2rem;
          }
        }


        .boxName {
          font-size: 0.5rem;
          color: #A7A7A7;
        }

        .skinName,
        .boxName {
          text-align: left;
        }
      }

    }

    .user {
      box-sizing: border-box;

      .avatarsImg {
        height: 2.03rem;
        width: 2.03rem;
        border-radius: 50%;
        background: url("../../assets/icon/avatarCircle.png") no-repeat center/cover;
        padding: 0.3rem;

      }


      .userName {
        margin-top: 0.4rem;
        font-size: 0.75rem;
        color: #FFC600;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        -webkit-text-overflow: ellipsis;
        width: 100%;
      }
    }
  }
}

 @media screen and (max-width:$windowSize-md){
   .cardItem {
   /*  &:hover {
       .swiperContent {
         transform: none;
       }
     }
     .swiperContent{
       .user{
         display: none;
       }
     }*/
   }

  }
</style>