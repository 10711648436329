<!--
时间:2023/9/8/008
用法:cdk兑换提示的弹窗
-->

<template>
  <PopupTemplate :show="cdkShow" @close="close">
    <template v-slot:default>
        <div class="box">
          <img class="logo" src="../assets/icon/logo@2x.png" alt="">
          <input :type="cdkValue" v-model="cdkValue" :placeholder="$t('nav.inptCdk')" class="custom-input"
                 onkeyup="value=value.replace(/[^a-zA-Z0-9]/g, ''); if(value.length > 16) value = value.slice(0, 16);">
          <div class="btn" @click="cdkExchange">
            <span>{{$t(`nav.exchange`)}}</span>
          </div>
        </div>
    </template>
  </PopupTemplate>

</template>

<script>
import {openCDK} from "@/axios/header";
import PopupTemplate from "@/components/popup/PopupTemplate.vue";

export default {
  name: "CDKPopup",
  components: {PopupTemplate},
  props: {
    cdkShow: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      adult: false,//是否满18岁
      pact: false,//
      cdkValue: ''
    }
  },
  methods: {
    async cdkExchange() {
      if (!this.cdkValue) {
        this.$Msg.error(this.$t('nav.inptCdk'));
        return;
      }
      try {
        let data = await openCDK(this.cdkValue);
        this.$store.dispatch('actionUserInfo')
        this.$Msg.success(this.$t('nav.redemptionSuccessful') + data.money);
        this.cdkValue = ''
        this.close()
      } catch (e) {
        console.log(e)
      }
    },
    close() {
      this.$emit('close')
    }
  }
}
</script>

<style scoped lang="scss">
@import "@/style/input";
.box {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 20rem;
  padding: 2rem 3rem;
  box-sizing: border-box;
  .logo {
    width: 13.75rem;
    margin-bottom: 1.5rem;
  }


  .btn {
    width: 100%;
    height: 2.11rem;
    background: #FBC300;
    border-radius: 0.17rem;
    @include center;
    font-weight: bold;
    font-size: 0.67rem;
    margin-top: 1.7rem;
    color: #191B29;
  }
}
@media screen and (max-width: $windowSize-md) {
  .box {
    .logo {
      width: 13.75rem;
      margin-bottom: 1.5rem;
    }

    .btn {
      width: 100%;
      height: 2.5rem;
      background: #FBC300;
      border-radius: 0.17rem;
      @include center;
      font-weight: bold;
      font-size: 1.08rem;
      margin-top: 1.3rem;
      color: #191B29;
    }
  }
}

</style>