import Decimal from "decimal.js";
import store from "@/store";
import {createDialog} from "@/components/Message";

/**
 * 将px转换位rem单位
 * @param {Number} num  大小
 * @return {Number}  转换结果
 */
export const reSize = (num) => {
    const html = document.querySelector("html");
    let fontSize = parseFloat(html.style.fontSize)

    return num / fontSize
}

/**
 * 将时间转换为多少分钟，小时前
 * @param {Number} timestamp  时间戳
 * @return {String} result 转换结果
 */
export const timeAgo = (timestamp) => {
    const currentTime = new Date();
    const inputTime = new Date(timestamp);
    const timeDifference = currentTime - inputTime;

    const minutes = Math.floor(timeDifference / (1000 * 60));
    const hours = Math.floor(timeDifference / (1000 * 60 * 60));
    const days = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
    const weeks = Math.floor(timeDifference / (1000 * 60 * 60 * 24 * 7));
    const months = Math.floor(timeDifference / (1000 * 60 * 60 * 24 * 30));

    if (months > 0) {
        return `${months} 月之前`;
    } else if (weeks > 0) {
        return `${weeks} 周之前`;
    } else if (days > 0) {
        return `${days} 天之前`;
    } else if (hours > 0) {
        return `${hours} 小时之前`;
    } else {
        return `${minutes} 分钟之前`;
    }
}

/**
 * 金额格式化，最后一位向下取整
 * @param {Number|String} amount  金额
 * @param {Number} decimalPlaces  保留小数点后几位
 * @return {String} result 转换结果
 */
export const formatCurrency = (amount, decimalPlaces = 2) => {
    const factor = Math.pow(10, decimalPlaces);
    console.log(factor)
    const truncatedAmount = Math.floor(amount * factor) / factor;
    console.log(amount * factor)
    const formattedTruncatedAmount = truncatedAmount.toFixed(decimalPlaces);
    const parts = formattedTruncatedAmount.split('.');
    // 千分位处理
    // parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');

    return `${parts.join('.')}`;
}

/**
 * 深拷贝
 * @param {Object|Array} target  拷贝对象
 * @returns {Object|Array} result 拷贝结果
 * 不适用Map，Set
 */
export const deepCopy = (target) => {
    if (Array.isArray(target)) { // 处理数组
        return target.map(item => deepCopy(item));
    }

    if (Object.prototype.toString.call(target) === '[object Object]') { // 处理对象
        // 先将对象转为二维数组，再将二维数组转回对象（这个过程还是浅拷贝）
        // 所以使用map方法将二维数组里的元素进行深拷贝完了再转回对象
        return Object.fromEntries(Object.entries(target).map(([k, v]) => [k, deepCopy(v)]));
    }
    return target; // 深拷贝要处理的就是引用类型内部属性会变化的情况，像正则、Error、函数、Date这种一般不会发生变化的直接返回原数据就可以
}

/**
 * 截取浏览器地址参数
 * @param {String} str  网站链接地址
 * @returns {Object} obj 返回所有参数对象形式
 **/
export const splitUrl = (str) => {
    if (str.indexOf('?') < 0) return false
    let arr = str.split("?")[1].split('&')
    let obj = {}
    for (let i = 0; i < arr.length; i++) {
        let newArr = arr[i].split('=')
        obj[newArr[0]] = newArr[1]
    }
    return obj
}

//节流函数，会执行最后一次操作
export const throttle = (fn, delay = 500) => {
    let previous = 0;
    let timer = null
    // 使用闭包返回一个函数并且用到闭包函数外面的变量previous
    return function () {
        let args = arguments;
        let now = Date.now()
        let restTime = previous + delay - now
        if (restTime <= 0) {
            fn.apply(this, args);
            previous = now;
        } else {
            if (timer) {
                clearTimeout(timer)
                timer = null
            }
            timer = setTimeout(() => {
                fn.apply(this, args);
                previous = Date.now();
            }, delay)
        }
    }
}

//取随机整数
export const getRandomInt = (min, max) => {
    min = Math.ceil(min); // 确保最小值为整数
    max = Math.floor(max); // 确保最大值为整数
    return Math.floor(Math.random() * (max - min + 1)) + min;
}


//数字增加减少,滚动变化数字特效
export const animateNumber = (startNumber, targetNumber, animationDuration, callback) => {
    // 记录开始时间
    const startTime = performance.now();
    let requestId = null
    startNumber = new Decimal(startNumber)
    targetNumber = new Decimal(targetNumber)
    const animate = (timestamp) => {
        //超过1及为动画结束
        const progress = Math.min(1, (timestamp - startTime) / animationDuration);
        let currentNumber;
        currentNumber = startNumber.plus(progress * (targetNumber.minus(startNumber))).toFixed(2);
        if (callback) {
            callback(currentNumber);
        }
        if (progress < 1) {
            requestId = requestAnimationFrame(animate);
        } else {
            cancelAnimationFrame(requestId);
        }
    };
    requestAnimationFrame(animate);
}


export const getImg = (url, index = '', format = 'png') => {
    if (!index && index !== 0) return null
    index = String(index)
    return require(`@/assets${url}${index}.${format}`)
}

export const mapArray = (array, num, newObject = {}) => {
    const newArray = [];
    for (let i = 0; i < num; i++) {
        if (array[i]) {
            newArray.push(array[i])
        } else {
            newArray.push(newObject)
        }
    }

    return newArray;
}

export const randSort = (arr, newArr,length = 30) => {
    if (arr.length === 0) return []
    if (newArr.length >= length) return newArr
    for (let i = 0; i < arr.length; i++) {
        if (Math.random() > .5) {
            newArr.unshift(arr[i])
        } else {
            newArr.push(arr[i])
        }
    }
    return randSort(arr, newArr,length)
}

// 传入时间转时分秒 默认是毫秒
export const durationFormatter = (time,isisMiniSecond = true) => {
    if(isisMiniSecond) time = Math.round(+time / 1000)
    if (!time) return {ss: 0};
    let t = time;
    const ss = t % 60;
    t = (t - ss) / 60;
    if (t < 1) return {ss};
    const mm = t % 60;
    t = (t - mm) / 60;
    if (t < 1) return {mm, ss};
    const hh = t % 24;
    t = (t - hh) / 24;
    if (t < 1) return {hh, mm, ss};
    const dd = t;
    return {dd, hh, mm, ss};
}

// 倒计时方法
export const countDown = (duration, callback,isisMiniSecond = true) => {
    let timer;
    let curTime = Date.now();
    if(isisMiniSecond) duration = Math.round(+duration / 1000)
    const updateTime = (duration) => {
        clearTimeout(timer); // 清除旧的计时器

        if (duration < 0) {
            return;
        }

        const { dd, hh, mm, ss } = durationFormatter(duration,false);
        let days = dd || 0;
        let hours = hh || 0;
        let mins = mm || 0;
        let seconds = ss || 0;
        curTime = Date.now();
        callback && callback(days, hours, mins, seconds);

        timer = setTimeout(() => {
            const now = Date.now();
            const diffTime = Math.floor((now - curTime) / 1000);
            curTime = now;
            updateTime(duration - diffTime);
        }, 1000);
    };

    updateTime(duration); // 启动计时器
    return () => {
        clearTimeout(timer);
        timer = null
    };
}

export const randomName = (nameArr1 = '',nameArr2 = '',nameArr3 = '',nameArr4 = '') => {
    const getRandom = (number) => {
        return Math.floor(Math.random() * number)
    }
    let name1 = nameArr1[getRandom(nameArr1.length)] || ''
    let name2 = nameArr2[getRandom(nameArr2.length)] || ''
    let name3 = nameArr3[getRandom(nameArr3.length)] || ''
    let name4 = nameArr4[getRandom(nameArr4.length)] || ''
   return name1 + name2 + name4 + name3
}

//html转译
export const escapeHtml = (text) => {
    return text
        .replace(/&/g, "&amp;")
        .replace(/</g, "&lt;")
        .replace(/>/g, "&gt;")
        .replace(/"/g, "&quot;")
        .replace(/'/g, "&#039;");
};


export const logout = () => {
    localStorage.removeItem('token')
    store.commit('setUserInfo', {})
    if (window.location.href !== '/') {
        window.location.href = '/'
    }
}
/**
 * 用户未充值提示
 **/
export const firstRecharged = ()=>{
    if(store.state.userInfo.firstRecharged === 0){
        createDialog.warn('为防止机器人恶意注册,维护广大用户的合法权益,请充值任意金额即可开箱提取,谢谢理解！')
        return false
    }else {
        return true
    }
}
/**
* 校验身份证号码是否合规
* */
export const  validateIDCard =(idNumber) =>{
    // 定义身份证号码的正则表达式
    const regExp =/^(([1][1-5])|([2][1-3])|([3][1-7])|([4][1-6])|([5][0-4])|([6][1-5])|([7][1])|([8][1-2]))\d{4}(([1][9]\d{2})|([2]\d{3}))(([0][1-9])|([1][0-2]))(([0][1-9])|([1-2][0-9])|([3][0-1]))\d{3}[0-9xX]$/;

    if (regExp.test(idNumber)) {
        return true; // 符合身份证号码格式要求
    } else {
        return false; // 不符合身份证号码格式要求
    }
}
/**
* 通过身份证号码获取当前年龄
* */
export const  getAgeFromId = (idNumber) => {
    // 假设idNumber是有效的中国大陆身份证号码
    var currentYear = new Date().getFullYear();
    var birthYear = parseInt(idNumber.substring(6, 10), 10);
    return currentYear - birthYear;
}
/**
 * 身份证号码加敏
 * */
export const EncryptCert = (cert) =>{
    return cert.replace(/^(.{3})(?:\d+)(.{3})$/, "$1*********$2")
}
