import {createRouter, createWebHistory} from 'vue-router'
import Layout from '../layout/layout.vue'
import store from '../store'

const routes = [
    {
        path: '/',
        name: '',
        component: Layout,
        meta: {
            title: "Home"
        },
        children: [
            {
                path: '',
                name: '',
                component: () => import('../views/homes.vue')
            },
            { //首页
                path: '/homes',
                name: 'homes',
                component: () => import('../views/homes.vue')
            },
            {
                path: 'openBlind/:id',
                name: 'openBlind',
                component: () => import('../views/openBlind.vue')
            },
           /* {
                path: 'roll',
                name: 'roll',
                component: () => import('../views/roll.vue'),
                meta: {
                    title: "Roll"
                }
            },*/
            {
                path: 'timeBox',
                name: 'timeBox',
                component: () => import('../views/active/timeBox.vue')
            },

            {
                path: '/deliver',//笔笔送页面
                name: 'deliver',
                component: () => import('../views/active/deliver.vue'),
                meta: {
                    title: "deliver",
                    login:true
                },
            },
            {
                path: '/welfare',//周一大福利
                name: 'welfare',
                component: () => import('../views/active/welfare.vue'),
                meta: {
                    title: "welfare",
                    login:true
                },
            },
            {
                path: '/fullmoon',//满月活动
                name: 'fullmoon',
                component: () => import('../views/active/fullmoon.vue'),
                meta: {
                    title: "fullmoon",
                    login:true
                },
            },
            /*{
                path: 'rollRoom/:id',
                name: 'rollRoom',
                component: () => import('../views/rollRoom.vue'),
                meta: {
                    title: "Roll",
                    login:true
                }
            },*/
            //追梦页面
            {
                path: 'dreams',
                name: 'dreams',
                component: () => import('../views/dreams.vue'),
                meta: {
                    title: "Dreams",
                    login:true
                }
            },
            {
                path: 'provably',
                name: 'provably',
                component: () => import('../views/provably.vue'),
                meta: {
                    title: "provably",
                    login:true
                }
            },
           /* {
                path: 'test',
                name: 'test',
                component: () => import('../views/test.vue'),
                meta: {
                    title: "Shop"
                }
            },*/
            {
                path: 'information/share',//佣金页面
                name: 'share',
                component: () => import('../views/information/share.vue'),
                meta:{
                    login:true
                }
            },
            {
                path: 'recharge',//佣金页面
                name: 'recharge',
                component: () => import('../views/recharge.vue'),
                meta:{
                    login:true
                }
            },
            {
                path: 'rule/:name',//隐私政策等页面，name为i18n翻译名
                name: 'agreement',
                component: () => import('../views/Help/agreement.vue'),
            },
            {
                path: 'comeback',
                name: 'comeback',
                component: () => import('../views/active/comeback.vue'),
            },
            //对战模块
           /* {
                path: 'battle',
                name: 'battle',
                component: () => import('../views/battle/battle.vue'),
                meta: {
                    title: "Battle"
                },
                children: [
                    {
                        path: '',
                        name: 'battleList',
                        component: () => import('../views/battle/battleList.vue'),
                    },
                    {
                        path: 'chiefBattleList',
                        name: 'chiefList',
                        component: () => import('../views/battle/chiefBattleList.vue'),
                    },
                    {
                        path: 'battleHistory/:type',
                        name: 'battleHistoryMy',
                        component: () => import('../views/battle/battleHistory.vue'),
                    },
                    {
                        path: 'battleTop',
                        name: 'battleTop',
                        component: () => import('../views/battle/battleTop.vue'),
                    },
                ]
            },*/
            //创建对战
           /* {
                path: 'battleEstablish',
                name: 'battleEstablish',
                component: () => import('../views/battle/battleEstablish.vue'),
                meta: {
                    title: "CreatBattle",
                    login:true
                }
            },
            //对战详情
            {
                path: 'battleFight/:id',
                name: 'battleFight',
                component: () => import('../views/battle/battleFight.vue'),
                meta: {
                    login:true
                }
            },*/
            {
                path: 'information',
                name: 'information',
                component: () => import('../views/information/information.vue'),
                meta: {
                    title: "Information",
                    login:true
                },
                children: [
                    {
                        path: '',
                        name: 'informationskins',
                        component: () => import('../views/information/skins.vue'),
                    },
                    {
                        path: 'info',
                        name: 'informationinfo',
                        component: () => import('../views/information/info.vue'),
                    },
                    {
                        path: 'welfare',
                        name: 'informationwelfare',
                        component: () => import('../views/information/welfare.vue'),
                    },
                    {
                        path: 'income',
                        name: 'informationincome',
                        component: () => import('../views/information/income.vue'),
                    },
                ]
            },
            {
                path: 'replacement',//汰换功能页面
                name: 'replacement',
                component: () => import('../views/replace/replacement.vue'),
                meta:{
                    login:true
                }
            },


            //手机版活动大厅
            {
                path: "active",
                name: 'active',
                component: () => import('../views/active/activeIndex.vue'),
                meta: {
                    login:true
                },
                children: [
                    {
                        path: '',
                        name: 'SignIn',
                        component: () => import('../components/Active/SignIn.vue'),
                    },
                    {
                        path: 'hotEvents',
                        name: 'hotEvents',
                        component: () => import('../components/Active/HotEvents.vue'),
                    },
                    {
                        path: 'missionCenter',
                        name: 'missionCenter',
                        component: () => import('../components/Active/MissionCenter.vue'),
                    },
                ]
            },
        ]
    },
    {//手机版个人中心
        path: "/profile",
        name: 'profile',
        component: () => import('../layout/profileLayout.vue'),
        meta: {
            login:true
        },
        children: [
            {
                path: '',
                name: 'informationMobile',
                component: () => import('../views/information/informationMobile.vue'),
            },
            {
                path: 'skins',
                name: 'profileskins',//使用相同页面，不同路由的情况下name要唯一
                component: () => import('../views/information/skins.vue'),
            },
            {
                path: 'welfare',
                name: 'profilewelfare',
                component: () => import('../views/information/welfare.vue'),
            },
            {
                path: 'withdraw',
                name: 'profilewithdraw',
                component: () => import('../views/information/withdraw.vue'),
            },
            {
                path: 'income',
                name: 'profileincome',
                component: () => import('../views/information/income.vue'),
            },
            {
                path: 'share',//佣金页面
                name: 'profileshare',
                component: () => import('../views/information/share.vue'),
            },
        ]
    },
    // steam登陆回调页面
    {
        path: '/steamBack',
        name: 'steamBack',
        component: () => import('../views/steamBack.vue'),
    },
    // 扫码app下载页面
    {
        path: '/downloadApp',
        name: 'downloadApp',
        component: () => import('../views/downloadApp.vue'),
    },
]

const router = createRouter({
    history:createWebHistory(),
    routes,
    scrollBehavior: () => {
        window.scrollTo(0, 0);
    }
})

router.beforeResolve(async (to, from, next) => {

    const isLoginRequired = to.meta.login;
    const isLoggedIn = !!localStorage.getItem('token'); // 使用异步操作获取登录状态
    if (isLoginRequired && !isLoggedIn) {
        store.commit('setLoginShow', true);
        localStorage.setItem('fromPath', to.fullPath);
        next(from.fullPath); // 阻止路由跳转
    } else {
        if(to.fullPath.indexOf('/steamBack') < 0){
            localStorage.removeItem('fromPath');
        }
        next();
    }
});
export default router
