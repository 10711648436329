import request from './index'

//获取活动配置信息接口
export const getActivityConfig = (data) => {
    return request({
        url: '/api/activity/getActivityConfigListApi',
        method: 'POST',
        data
    })
}
//大转盘活动状态信息接口
export const getTurntable = (data) => {
    return request({
        url: '/api/activity/turntable/status',
        method: 'GET',
        data
    })
}
//大转盘活动抽奖接口
export const doTurntablePrize = (data) => {
    return request({
        url: '/api/activity/turntable/prize',
        method: 'GET',
        data
    })
}
//周一福利大放送
export const getMonHistory = (data) => {
    return request({
        url: '/api/activity/getMonHistoryListApi',
        method: 'POST',
        data
    })
}
//修改周一福利大放送（立即领取）
export const updateMondayWelfareEntity = (params) => {
    return request({
        url: '/api/activity/updateMondayWelfareEntity',
        method: 'GET',
        params
    })
}
//获取笔笔送数据
export const getDeliver = (data) => {
    return request({
        url: '/api/activity/getHistoryListApi',
        method: 'POST',
        data
    })
}
//修改笔笔送数据（立即领取）
export const updateStrokeDeliverEntity = (params) => {
    return request({
        url: '/api/activity/updateStrokeDeliverEntity',
        method: 'GET',
        params
    })
}

//满月活动表格数据
export const getByType = (data) => {
    return request({
        url: '/api/activity/getByType',
        method: 'GET',
        data
    })
}
//满月活动 当前用户数据
export const getActiveMonthRecord = (data) => {
    return request({
        url: '/api/activity/getActiveMonthRecord',
        method: 'GET',
        data
    })
}
//满月活动 领取奖励
export const receiveActiveMonthRecord = (params) => {
    return request({
        url: '/api/activity/receiveActiveMonthRecord',
        method: 'GET',
        params
    })
}
//获取最新用户任务完成领取记录 （滚动广播）
export const getUserTaskReward = (params) => {
    return request({
        url: '/api/active/getUserTaskReward',
        method: 'GET',
        params
    })
}

//获取当前签到信息
export const getSignIn = (params) => {
    return request({
        url: '/api/signin/getSign',
        method: 'GET',
        params
    })
}

//当天签到
export const sign = (data) => {
    return request({
        url: '/api/signin/sign',
        method: 'POST',
        data
    })
}

//领取签到奖励
export const signReward = (data) => {
    return request({
        url: '/api/signin/signReward',
        method: 'POST',
        data
    })
}


/**
 * 获取每日任务及消耗
 *
 * @param {Object} params - 请求参数对象
 * @param {Number} params.type - 任务类型 1盲盒任务 2对战任务 3追梦任务
 * @returns {Promise} 返回一个 Promise 对象
 */
export const getConsume = (params) => {
    return request({
        url: '/api/active/getConsume',
        method: 'GET',
        params
    });
};


/**
 * 领取任务奖励
 *
 * @param {Object} data - 请求参数对象
 * @param {Number} data.id - 领取 id
 * @returns {Promise} 返回一个 Promise 对象
 */
export const receiveAward = (data) => {
    return request({
        url: '/api/active/receiveAward',
        method: 'POST',
        data
    })
}

/**
 * 东山再起 用户是否能领取
 *
 * @param {Object} data - 请求参数对象
 * @returns {Promise} 返回一个 Promise 对象
 */
export const hasGetDong = (data) => {
    return request({
        url: '/api/active/activeTomorrowUpHasGet',
        method: 'POST',
        data
    })
}


/**
 * 东山再起 领取
 *
 * @param {Object} data - 请求参数对象
 * @returns {Promise} 返回一个 Promise 对象
 */
export const upGetDong = (data) => {
    return request({
        url: '/api/active/activeTomorrowUpGet',
        method: 'POST',
        data
    })
}

/**
 * 获取时间盲盒数据
 *
 * @returns {Promise} 返回一个 Promise 对象
 */
export const getTimeBox = () => {
    return request({
        url: '/api/activity/getAllTimeBoxActivitys',
        method: 'GET',
    });
};
/**
 * 获取时间盲盒开奖路径
 *
 * @returns {Promise} 返回一个 Promise 对象
 */
export const getBoxHistory = (data) => {
    return request({
        url: '/api/activity/getTimeBoxRecords',
        method: 'POST',
        data
    });
};
