export default {
    nav: {
        // 导航栏
        welfare: "福利",
        myInfo: '个人资料',
        mySkins: '我的物品',
        myWelfare: '我的福利',
        myReward: '我的佣金',
        information: "个人中心",
        historicalBilling: '历史账单',
        login: "登录",
        logout: "退出登录",
        blindBox: '盲盒',
        rollRoom: "Roll房",
        dream: "追梦",
        battle: "竞技场",
        bomb: "拆弹",
        taihuan: "汰换",
        shijian: "时间盲盒",
        refill: "充值",
        // 登陆弹窗
        loginTitle: '我们需要确保您可以使用csgo8,请确认：',
        my18: '我已满18岁',
        agree: "我已阅读并同意",
        usrAgreement: '用户协议',
        privacyAgreement: '隐私协议',
        antiMoneyLaundering: '反洗钱协议',
        // 电脑版footer内容
        openCases: '已开箱总数',
        users: '已注册用户',
        online: '在线用户',
        footerText1: "盲盒玩法多种多样，饰品提取安全便捷！",
        // 手机版侧边栏
        games: "游戏",
        preferential: "特惠",
        free: "免费",
        recharge: "充值",
        cdk: "CDK",
        other: "其他",
        QQ: "QQ",
        custServ: "客服",
        App: "App",
        glblMusicEnabled: "已启用音效",
        soundEffectsDisabled: "已禁用音效",
        questions:"常见问题",
        aboutUs:"关于我们",
        pleaseEntrANickname:"请输入昵称",
        pleaseEntrARealname:"请输入真实姓名",
        pleaseEntrCardID:"请输入身份号",
        pleaseEntrYourEmailAddr:"请输入手机号",
        pleaseEntrTheVerificationCd:"请输入验证码",
        obtainVerificationCd:"获取验证码",
        pleaseOutputTheInptPsw:"请输入密码",
        pleaseConfThePsw:"请确认密码",
        pleaseEntrTheInvtCd:"请输入邀请码（选填）",
        noSteam:"没有steam？",
        emailLogin:"手机号登陆",
        passwordLogin:"密码登陆",
        verificationCdLogin:"验证码登陆",
        register:"注册",
        steamLogin:"steam登录",
        forgetPsw:"忘记密码？",
        conf:"确认",
        retrievePsw:"找回密码",
        ruleDream:"追梦规则",
        exchange:"兑换",
        inptCdk:"请输入CDK口令",
        redemptionSuccessful: "兑换成功，F币",
        ruleBattle:"竞技场规则",
    },
    blindBox: {
        //开箱页面
        open: "打开",
        buy: "购买",
        bestDrp: "最近掉落",
        boxContains: "箱子包含",
        buyTips1: "您没有足够的资金来开箱!"
    },
    roll: {
        ofcl: "官方",
        anchor: "主播",
        iParticipatedIn: "我参与的",
        haveInHand: "进行中",
        itsOvr: "已结束",
        participated: "已参与",
        viewingRooms: "查看房间",
        joinNow: "立即加入",
        deadline: "截止时间",
        totVal: "总价值",
        items: "物品",
        players: "玩家",
        //roll详情
        countdownToLottery: "开奖倒计时",
        prtpConditions: "参与条件",
        includingPrizes: "包含奖品",
        participatingUsers: "参与用户",
        awrdLst: "获奖名单",
        joinRm: "加入房间"
    },
    dream: {
        chooseAccessories: "选择饰品",
        probability: "概率",
        upg: "追梦",
        upgTips: "*追梦概率范围为{num1}%~{num2}%",
        //追梦选择饰品弹窗
        inputPlaceholder1: "输入装备关键字",

    },
    battle: {
        //对战列表
        allBattles: "全部对战",
        rankingLst: "排行榜",
        historicalRecords: "历史记录",
        myRecords: "我的记录",
        creABattle: "创建对战",
        //创建对战
        addBox: "添加箱子",
        robot: "机器人",
        combatCost: "战斗成本",
        createBattle: "创建对战",
        chooseAnItem: "选择物品",
        incorrectNumberOfPeople: "人数不正确",
        inputPlaceholder1: "输入箱子关键字",
        thisMorning: "今天上午",
        thisAfternoon: "今天下午",
        selected: "已选:{num}个",
        coVal: "共值",
        //对战详情
        ready: "已准备",
        drawnJewelry: "抽中饰品",
        obtainJewelry: "获得饰品",
        //排行榜
        today: "今天",
        yesterday: "昨天",
        //对战结束弹窗
        battleIsOvr: "对战结束",
        winner: "胜利者",
        oneMoreRound: "再来一局",
        confJoin:"是否确认加入？"
    },
    information: {
        //个人中心首页
        invtCd: "邀请码",
        bal: '余额',
        rchg: "充值",
        orderNumber: '订单号',
        transactionLnk: "STEAM交易链接",
        steamLinkTips1: "1、交易链接一旦绑定则无法更换,请谨慎操作,同一链接更新token不限制.",
        steamLinkTips2: "2、请勿绑定他人的steam交易链接",
        steamInputPlaceholder1: "输入您的steam交易链接",
        obtain: "获取",
        saveAddr: "保存地址",
        save: "保存",
        myInfo: '个人资料',
        mySkins: '我的物品',
        myWelfare: '我的福利',
        rchgDetails: "充值明细",
        incomeDetails: "收支明细",
        myReward: '我的佣金',
        // 我的物品页面
        retrieving: "正在取回",
        retrieveRecords: "取回记录",
        numOrnaments: "共{num}件饰品",
        obtainingTm: "获得时间",
        val: "价值",
        selectedNum: "已选 {num} 件",
        retrieve: "取回",
        chooseABox: "去选箱子获得一些",
        noItems: "你还没有物品",
        noRecIsCur: "没有当前记录",
        //佣金页面
        generalizationCd: "推广码",
        cpy: "复制",
        promLnk: "推广链接",
        partnerRules: "合伙人规则",
        totNumOfInvitations: "邀请总人数",
        effectiveUsers: "有效用户",
        accumRewards: "累计奖励",
        rewardRatio: "奖励比例",
        lvlDsc: "等级说明",
        invtRec: "邀请记录",
        date: "日期",
        invitee: "被邀请人",
        usrRchg: "用户充值",
        myRewards: "我的奖励",
        rewardLvl:"奖励等级",
        invitedUsrRchg:"被邀请用户充值",
        promAndAcq:"推广用户将获得",
        incmStmt:"收益说明"
    },
    active:{
        signIn:"登陆签到",
        rechargeSign:"充值签到",
        signedInDays:`已签到{html1}{num}{html2}天`,
        sign:"签到",
        signedIn:"已签到",
        claim:"领取",
        claimed:"已领取",
        tips:"连续7天{type}，第7天最高可获取{num}红包福利（中断任何一天将重置回第一天）",
        signSuccess:"签到成功",
        claimSuccess:"领取成功"
    },
    confirm: {
        success: "成功",
        warning: "警告",
        error: "错误",
        noDataAvailable: "暂无数据",
        //老虎机抽奖结果弹窗
        decompose: "分解",
        decomposeAll: "全部分解",
        cont: "继续",
        //confirm提示弹窗
        confDecomposition: "确认分解{num}件装备吗？",
        common: "共",
        cancel: "取消",
        confirm: "确认",
        confDec: "确认分解？",
        confOpn: "是否确认打开？",
        conf: "是否确认？" ,
        //msg提示弹窗
        decomposeSuccess: "分解成功!",
        theMinimumProbabilityIs: "概率最小为{num}",
        notLoggedIn: "未登录",
        noJewelrySelected: "未选择饰品",
        agreeToATreaty: "请阅读并同意以下条约",
        copySuccess: "复制成功",
        copyError: "复制失败",
        pleaseFollow: "取回中，请稍后",
        notYetOpn: "暂未开放",
        glblMusic: "请先开启全局音乐",
        amtCannotBeZero: "金额不能为0",
        pswModSuccessful:"密码修改成功",
        loginSuccessful:"登录成功",
        loginWasSuccessful:"注册成功，请登陆！",
        emailFmtErr:"手机号格式错误",
        vSntSuccess:"验证码发送成功",
        pswInconsistency:"密码不一致",
        entrTheVerificationCd:"请输入验证码",
        theMinPswLenIs6:"密码最小长度为6",
        pleaseEntrANickname:"请输入昵称",
    },
    //常用的
    common: {
        //单位
        day: '天',
        hh: "时",
        mm: '分',
        ss: "秒",
        piece: "件",
        // title常用
        back: "返回",
        gameRules: "游戏规则",
        //表格常用
        players: "玩家",
        price: "价格",
        reset: "重置",
        cases: "箱子",
        value: "价值",
        actions: "操作",
        watch: "观战",
        join: "加入",
        ranking: "排行",
        bestDrp: "最佳掉落",
        date: "日期",
        st: "状态",
        purp: "用途",
        amtOfMoney: "金额",
        allElection: "全选",
        typ:"类型",
        numericalVal:"数值",

    },
    incomeType: {
        //个人中心收支type
        whole: "全部",
        incm: "收入",
        expenditure: "支出",
        purCurr: "购币",
        recovery: "回收",
        unpacking: "开箱",
        commission: "佣金",
        redEnvelopes: "红包",
        war: "对战",
        shoppingMallPur: "商城购买",
        chasingDreams: "追梦",
        coinPurGft: "购币赠送",
        cshWdrl: "提现",
        rlRm: "ROLL房",
        regGifts: "注册赠送",
        addRewards: "加送奖励",
        redEnvelopeReward: "红包奖励",
        luckyTurntable: "幸运转盘",
        bkOffcDistribution: "后台发放",
        skinSynthesis: "皮肤合成",
        sgnInReward: "签到奖励",
        newDreamChasing: "新追梦",
        unpackingKing: "拆箱王",
        skydiverHd: "极限跳伞",
        inviteRebate: "邀请返利",
        taskRewards: "任务奖励",
        benefits: "周一福利大放送",
        deliver: "笔笔送",
        turntable: "大转盘",
        turntablegive: "转盘充值赠送",
        breakgive: "分解赠送",
         comeback: "东山再起",
    },
    provably:{
        provably:"公平性验证",
        provablyDsc:"公平性验证说明",
        text1:"1、玩家每次游戏会产生一个roll点，对应的roll点获得对应的皮肤。",
        text2:"2、开箱追梦竞技场 三个玩法各自一套用户种子和公开哈希，模块内通用例如: 开箱块a箱子和b箱子在rl点上没有区别，只是获得皮肤有区别。",
        text3:"3、参与运算的加密值如下:",
        text4:"4、我们提供该roll点算法的开源代码和测试平台，懂程序员的朋友可以单机运算，在网站服务器无关联的情况下，看是否结果一致。",
        clientSeed:"用户种子",
        publicHash:"公开哈希",
        round:"回合数",
        secretHash:"秘密哈希",
        secretSalt:"secret salt",
        createTime:"创建时间",
        enterClientSeed:"请输入用户种子",
        enterPublicHash:"请输入公开哈希",
        enterSecretHash:"请输入秘密哈希",
        enterSecretSalt:"请输入secret salt",
        enterRound:"请输入回合数",
        p1:"也就是说，有两个值是用户可以自己更改的，且这两个值参与了运算，可以改变运算结果/roll点。例如:个人种子默认1.手动更改成2改了之后roll点结果为10086其他参数不变的情况下，再去用工具验证1的结果，会发现种子为1的情况下结果不为10086 ",
        p2:"实例中，在更改种子或公开哈希之后，可看到之前游戏的秘密哈希和secret salt，然后可以去验算之前的结果是否正确。也可以拿之前的值去测，较大回合下(例如10w次)概率分布是否合理。",
        opnSrcAddr:"开源地址",
        download:"下载离线验证器",
        curSeed:"当前种子",
        preSeed:"以往服务器种子",
        chg:"更改",
        reset:"重置",
        oneClkCpy:"一键复制",
        validator:"公平性验证器",
        attachment1:"(可随意更改)",
        attachment2:"(可重置)",
        attachment3:"(随游戏次数增加)",
        resetDisp:"(重置公开哈希后展示)",
        legitimateSeed:"请输入合法种子",
        entrANewSeed:"输入新的种子",
        seedReg:"三个字符（含）以上的英文字母、数字或中文",
        oneClkPaste:"一键粘贴",
        rlPnt:"roll点",
        waitVerification:"等待验证"
    },
    activity:{
        welfare:"活动规则",
        welfare1:"1.每个周一0点开始至周日23点59分59秒时间段内充值的会员，在下周一可以在此活动页面领取上一周累计充值金额的5%;",
        welfare2:"2.累计充值金额要满足100元以上;",
        welfare3:"3.领取赠送福利有效期是7天，没有领取则自动失效;",
        welfare4:"4.本活动最终解释权归本平台所有。",
        tableHeader1:"充值时间",
        tableHeader2:"充值金额",
        tableHeader3:"赠送比例",
        tableHeader4:"领取金额",
        tableHeader5:"状态",
        deliverSpan:"玩家领取后，将自动到账玩家钱包，可进入钱包查看",
        deliverPens:"笔数",
    },
    privacyClause:{
    usrAgreement:"<p>与使用服务相关的条件</p>" +
            "<p>无论您的父母或监护人是否同意使用本服务，十八（18）岁以下的个人均不得使用本服务。</p>" +
            "<p>您需要受支持的Web浏览器才能访问服务。您承认并同意我司可能会停止支持特定的Web浏览器，并且您若要继续使用本服务，将需要下载受支持的Web浏览器。您还承认并同意，服务的性能取决于您的计算机设备和互联网连接的性能。</p>" +
            "<p>您同意通过Valve Corporation提供的Steam帐户登录并注册服务。您全权负责管理您的帐户和密码并对您的密码保密。您还全权负责限制对您帐户的访问。</p>" +
            "<p>您同意对您自己或其他人在您的帐户上或通过使用您的密码进行的所有活动负责。如果您认为第三方能够获取您的密码，请尽快使用本服务的密码重新生成功能来获取新密码。在任何情况下，您同意不允许任何第三方使用或访问本服务。</p>" +
            "<p>本条件文本中的所有术语和定义均由双方根据新加坡法律和互联网上使用的相关术语的解释规则进行解释。</p>" +
            "<p>作为您使用服务的条件，您同意不会：</p>" +
            "<p>冒充或歪曲您与任何个人或实体的关系；</p>" +
            "<p>访问、篡改或使用服务计算机系统的任何非公共区域；</p>" +
            "<p>尝试破译、反编译、反汇编、逆向工程或以其他方式调查用于提供服务的任何软件或组件；</p>" +
            "<p>以任何方式伤害或威胁伤害其他用户，或干扰或试图干扰任何用户、主机或网络的访问，包括但不限于通过发送病毒、过载、洪泛、垃圾邮件或邮件轰炸服务；</p>" +
            "<p>提供属于第三方的支付信息；</p>" +
            "<p>以滥用方式使用服务，违反其预期用途、我司政策和指示以及任何适用法律；</p>" +
            "<p>系统地从服务中检索数据或其他内容，以直接或间接地在单个或多个下载、集合、编译、数据库、目录等中创建或编译，无论是通过手动方法、通过使用机器人、爬虫程序或蜘蛛或其他；</p>" +
            "<p>以违反第三方（例如G2A或Valve）提供服务运营所需设施和技术的条款和条件的方式使用服务；</p>" +
            "<p>在使用或访问本服务时侵犯第三方知识产权，包括但不限于通过使用本服务提供虚拟物品；</p>" +
            "<p>使用、宣传、连接或提供对我司自行判断认为具有冒犯性或对我司声誉造成损害的材料的访问权限，包括但不限于非法内容和色情内容以及被视为冒犯或伤害我司的内容和/或服务（例如Warez网站、IRC机器人和Bittorent网站）。</p>" +
            "<p>终止</p>" +
            "<p>我们可以出于任何原因（包括但不限于您违反本条款）立即终止或暂停对我们服务的访问，恕不另行通知，也不承担任何责任。</p>" +
            "<p>本条款中根据其性质应在终止后继续有效的所有条款应在终止后继续有效，包括但不限于所有权条款、保证免责声明、赔偿和责任限制。</p>" +
            "<p>我们可能出于任何原因（包括但不限于您违反本条款）立即终止或暂停您的帐户，恕不另行通知，也不承担任何责任。</p>" +
            "<p>终止后，您使用服务的权利将立即终止。如果您想终止您的帐户，您只需停止使用该服务即可。</p>" +
            "<p>开案规则</p>" +
            "<p>打开箱子即表示您同意获取页面上显示的游戏物品之一以及箱子所含物品的描述。自动确定接收项目。</p>" +
            "<p>1.接受游戏物品</p>" +
            "<p>1.1.接受游戏物品即表示您同意箱子的价格（包括其中包含的游戏物品）符合您的期望，并确认您无权向网站/服务或公司索取任何额外赔偿。</p>" +
            "<p>1.2.一旦通过使用保护壳收到游戏物品并显示在个人帐户中，用户就可以将游戏物品带到Steam帐户</p>" +
            "<p>1.3. Steam帐户中游戏项目的接受取决于用户是否满足网站和个人帐户中指定的Steam和个人帐户设置。</p>" +
            "<p>禁止赌博禁止以任何违反任何法律或法规（包括禁止非法赌博的法律或法规）的方式。购买的游戏物品只能用于个人目的，不得用于任何类型的商业利润。它必须用于其预期目的，即用于玩CS:GO。您必须根据Steam服务订户协议使用皮肤。您不得在第三方市场上转售在本站上收到的皮肤，或以其他方式将此类皮肤换成任何有价值的东西。</p>" +
            "<p>2.拒绝游戏物品</p>" +
            "<p>2.1.用户可以在开箱时拒绝游戏物品，并将其退回以获得奖励积分，从而在将来支付箱子时给予个人帐户中物品描述中指定的金额折扣。</p>" +
            "<p>其他网站的连接</p>" +
            "<p>我们的服务可能包含非我司所有或控制的第三方网站或服务的连接。</p>" +
            "<p>我司无法控制任何第三方网站或服务的内容、隐私政策或做法，也不承担任何责任。您进一步承认并同意，对于因使用或依赖任何此类内容、商品或服务而造成或声称造成的任何损害或损失，我司不承担直接或间接的责任。任何此类网站或服务。</p>" +
            "<p>我们强烈建议您阅读您访问的任何第三方网站或服务的条款和条件以及隐私政策。</p>" +
            "<p>无保证</p>" +
            "<p>本站点「按原样」提供，不提供任何明示或暗示的陈述或保证。本平台不对本站点或本站点提供的信息和材料做出任何陈述或保证。在不影响前述段落一般性的情况下，本平台不保证：本站点将持续可用或完全可用；或本站点上的信息完整、真实、准确或无误导性。本站点上的任何内容均不构成或无意构成任何类型的建议。如果您需要有关任何法律、财务或医疗问题的建议，您应该咨询适当的专业人士。</p>" +
            "<p>账户安全</p>" +
            // "<p>本站点使用Steam OpenID，这意味着您正在使用您的Steam帐户登录。</p>" +
            // "<p>您不得共享您的Steam帐户或登录信息，也不得让任何其他人访问您的Steam帐户或执行任何可能危及您的Steam帐户安全的行为。</p>" +
            // "<p>如果您意识到或有理由怀疑任何违反安全的行为，包括但不限于Steam登录信息的任何丢失、被盗或未经授权的披露，您必须立即联系Steam支持（https://help.steampowered.com/）。</p>" +
            // "<p>您全权负责维护Steam帐户信息的机密性，并且您将对Steam登录信息的所有使用（包括购买）负责，无论是否经过您授权。</p>" +
            // "<p>您对通过您的Steam帐户发生的任何事情负责。</p>" +
            "<p>联系</p>" +
            "<p>我们绝不隶属于Valve公司、《反恐精英：全球攻势》、Steam或Valve公司的任何其他商标，也不受其认可。</p>" +
            "<p>退货和退款政策</p>" +
            "<p>本平台钱包通过充值您的本平台帐户钱包金额，即表示您接受收到的积分（标有IS标志）不值真钱，并且不会获得任何退款。</p>" +
            "<p>一旦订单确认并发送产品，我们不会为数字产品退款。如果您在接收或下载我们的产品时遇到任何问题，我们建议您联系我们寻求帮助。</p>" +
            "<p>不可抗力</p>" +
            "<p>对于因我司合理控制之外的原因而导致的任何延迟或未能履行，我们不承担任何责任。</p>" +
            "<p>这包括但不限于由于不可预见的情况或我们无法控制的原因（例如天灾、战争、恐怖主义、骚乱、禁运、民事或军事当局的行为、火灾、洪水、事故、罢工或短缺）而未能履行本协议运输设施、燃料、能源、劳动力或材料。</p>" +
            "<p>协议的修改</p>" +
            "<p>我们有权单方面更改本协议的条款，这些更改在新版本的协议在互联网上发布时生效</p>" +
            "<p>继续使用本站点的功能将意味着您同意新版本协议的条款。如果您不同意新版本协议的条款，您必须停止使用本站点。</p>" +
            "<p>版权和其他无形权利</p>" +
            "<p>网站由Steam（Valve Corporation的注册商标）提供支持。</p>" +
            "<p>Valve、Valve徽标、Steam徽标、Counter-Strike和Counter-Strike徽标是Valve Corporation的商标和/或注册商标。</p>" +
            "<p>所有其他商标均为其各自所有者的财产。网站上的任何无形权利或其他材料均不会转让给用户。</p>" +
            "<p>在此网站/APP中的任何活动Apple不是赞助者，也没有以任何形式参与活动。</p>" +
            "<p>运输/交货政策</p>" +
            "<p>1、您在我司网站获得的皮肤会显示在您的皮肤物品库存中我司库存状态分为4种，分别为（库存、提货、退回入库、提货）</p>" +
            "<p>物品库存：显示您在开箱中获得的未分解的皮肤，您可以分解它们或请求拾取它们；</p>" +
            "<p>取货：代表您已提交，网站管理员尚未处理发货或因缺货等原因尚未协调到货源，这种状态需要您耐心等待。</p>" +
            "<p>退回存储：可能由以下原因引起：您的steam状态异常导致交易限制；或连接失败，或因市场缺货，所以无法处理您的取货请求；或因您的背包物品7天未领取，且价格波动过大，导致无法完成您的领取需求（所以请您及时领取饰品，以免给您的领取带来麻烦）。</p>" +
            "<p>已领取：代表您的提交已经通过审核，并且本站已经发货，这种状态需要您及时去steam平台领取您的装备。</p>" +
            "<p>2、提货前的准备工作</p>" +
            "<p>在领取装备之前，请前往您的个人资料页面设置您的Steam交易报价连接。但由于steam由于政策原因，中国玩家可能无法打开，还请使用UU网站加速器测试。</p>" +
            "<p>3、发货时间</p>" +
            "<p>正常情况下，您会在1分钟内收到取货通知，由于皮肤价格波动、库存等特殊情况，最长取货时间为18小时，请耐心等待。如有其他问题，请随时联系官方客服（10:00-22:00）</p>" +
            "<p>信息</p>" +
            "<p>本站作为网站的管理员，采用这些使用条款，规定了用户的权利和义务，并构成对双方具有法律约束力的协议。这些使用条款影响用户在使用本站点时的权利并规定了某些义务，因此用户必须仔细阅读。</p>"
            // "<p>网站信息：我司.com</p>"
            /*"<p>邮箱：--------------</p>" +
            "<p>地址：---------------</p>*/,
    privacyAgreement:"<p>隐私政策条款</p>" +
           "<p>我司可能会不时更改本隐私政策，一旦修订后的隐私政策在本应用程序上可用，更改就会生效。「最后更新」日期位于本政策底部。因此，请您在每次提交个人信息时参考本隐私政策。</p>" +
           "<p>数据控制者和所有者</p>" +
           // "<p>我司.com</p>" +
           "<p>联系方式：客服</p>" +
           "<p>收集的数据类型</p>" +
           "<p>本应用程序自行或通过第三方收集的个人数据类型包括：Cookie和使用数据。</p>" +
           "<p>收集的其他个人数据可能会在本隐私政策的其他部分中进行描述，或者通过与数据收集相关的专用说明文本进行描述。</p>" +
           "<p>个人数据可由用户自由提供，或在使用本应用程序时自动收集。</p>" +
           "<p>除非另有说明，本应用程序或本应用程序使用的第三方服务的所有者对Cookie或其他跟踪工具的任何使用，均用于识别用户并记住他们的偏好，其唯一目的是提供用户。</p>" +
           "<p>未能提供某些个人数据可能会导致本应用程序无法提供服务。</p>" +
           "<p>用户对通过本应用程序获取、发布或共享的任何第三方个人数据负责，并确认他们已获得第三方同意向所有者提供数据。</p>" +
           "<p>处理数据的方式和地点</p>" +
           "<p>加工方法</p>" +
           "<p>数据控制者以适当的方式处理用户的数据，并应采取适当的安全措施来防止未经授权的访问、披露、修改或未经授权的销毁数据。</p>" +
           "<p>数据处理是使用计算机和/或IT支持的工具进行的，遵循与指定目的严格相关的组织程序和模式。除了数据控制者之外，在某些情况下，参与网站运营的某些类型的负责人（管理、销售、营销、法律、系统管理）或外部各方（例如第三方）也可以访问数据。如有必要，由所有者指定为数据处理者。数据控制者可以随时向数据控制者请求这些方的更新列表。</p>" +
           "<p>地方</p>" +
           "<p>数据在数据控制者的运营办公室以及参与处理的各方所在的任何其他地方进行处理。如需更多信息，请联系数据控制者。</p>" +
           "<p>保留时间</p>" +
           "<p>数据将在提供用户请求的服务或本文档中概述的目的所规定的服务所需的时间内保留，并且用户始终可以请求数据控制者暂停或删除数据。在任何情况下，数据的保存时间均不会超过用户在网站上进行最新活动后的5年。</p>" +
           "<p>我们如何保护您的信息？</p>" +
           "<p>我们致力于保护您的隐私，并在个人数据的处理和传输方面实施各种安全措施。然而，互联网的性质使得数据在某些情况下可能在没有完全安全措施的情况下通过网络流动，并且可能被未经授权的人员访问。</p>" +
           "<p>我们如何使用您的信息？</p>" +
           "<p>我们将出于我们的合法利益使用您的个人信息，以响应您的询问、向您发送信息、履行对您的任何合同义务、出于研究目的、向您发送有关我们的商品、服务和相关机会的营销信息，以及经您明确同意的其他合理用途。</p>" +
           "<p>如果您从我们这里购买商品或服务，我们可能会通过邮寄、推送通知或电子邮件向您发送与您的购买相关的更多信息。</p>" +
           "<p>我们希望通过推送通知和电子邮件向您发送营销信息。</p>" +
           "<p>如果您不希望收到上述任何信息，也不希望我们按照上述方式与您联系，您可以向我们的客服询问。</p>" +
           "<p>如果您通过我们的联系表向我们提交您的意见，我们可能会在内部分发它们以用于培训和管理目的。</p>" +
           "<p>我们定期审查所收集的个人信息，以确定其准确性以及是否可以删除。</p>" +
           "<p>我们可能会将您发送给我们的信息与其他数据进行汇总（以便无法从该数据中识别您的身份），并将该汇总数据用于管理目的。</p>" +
           "<p>推送通知</p>" +
           "<p>该应用程序可以向用户发送推送通知。</p>" +
           "<p>分析</p>" +
           "<p>本节中包含的服务使所有者能够监控和分析网络流量，并可用于跟踪用户行为。</p>" +
           "<p>谷歌分析（谷歌公司）</p>" +
           "<p>Google Analytics是Google Inc.（「Google」）提供的一项网络分析服务。谷歌利用收集的数据来跟踪和检查该应用程序的使用情况，准备有关其活动的报告并与其他谷歌服务共享。</p>" +
           "<p>谷歌可以使用收集的数据来将其自己的广告网络的广告情境化和个性化。</p>" +
           "<p>收集的个人数据：Cookie和使用数据。</p>" +
           "<p>Google AdWords转化跟踪（Google Inc.）</p>" +
           "<p>Google AdWords转化跟踪是Google Inc.提供的一项分析服务，它将来自Google AdWords广告网络的数据与在此应用程序上执行的操作连接起来。</p>" +
           "<p>收集的个人数据：Cookie和使用数据。</p>" +
           "<p>Twitter广告转化跟踪（Twitter，Inc.）</p>" +
           "<p>Twitter广告转化跟踪是Twitter，Inc.提供的一项分析服务，它将来自Twitter广告网络的数据与在此应用程序上执行的操作连接起来。</p>" +
           "<p>收集的个人数据：Cookie和使用数据。</p>" +
           "<p>用户的权利</p>" +
           "<p>用户可以对所有者处理的数据行使某些权利。</p>" +
           "<p>特别是，用户有权执行以下操作：</p>" +
           "<p>随时撤回他们的同意。用户有权撤回之前同意处理其个人数据的同意。反对处理其数据。如果处理是在法律依据而非同意的基础上进行的，用户有权反对对其数据的处理。下面的专门部分提供了更多详细信息。访问他们的数据。用户有权了解数据所有者是否正在处理数据、获取有关处理某些方面的披露并获取正在处理的数据的副本。核实并寻求整改。用户有权验证其数据的准确性并要求更新或更正。限制对其数据的处理。在某些情况下，用户有权限制对其数据的处理。在这种情况下，所有者不会出于存储以外的任何目的处理其数据。删除或以其他方式删除他们的个人数据。在某些情况下，用户有权要求所有者删除其数据。接收他们的数据并将其传输到另一个控制器。用户有权以结构化、常用和机器可读的格式接收其数据，并且在技术上可行的情况下，有权将其无障碍地传输到另一个控制器。如果数据是通过自动方式处理的，并且该处理是基于用户的同意、用户所签订的合同或合同前的义务，则本规定适用。提出投诉。用户有权向其主管的数据保护机构提出索赔。</p>" +
           "<p>定义和法律参考</p>" +
           "<p>个人数据（或数据）</p>" +
           "<p>直接、间接或与其他信息相关的任何信息可以识别或识别自然人。此类信息可能包括姓名、地址、护照或身份证数据、个人身份号码以及任何其他可以共同识别个人身份的数据。</p>" +
           "<p>使用数据</p>" +
           "<p>通过本站点自动收集的信息，其中可能包括：使用本站点的用户所使用的计算机的IP地址或域名、URI地址（统一资源标识符）、请求时间、提交请求所使用的方法对服务器的请求、响应中收到的文件的大小、指示服务器应答状态的数字代码（成功结果、错误等）、原产国、浏览器的功能以及所使用的操作系统用户、每次访问的各种时间详细信息（例如，在网站内每个页面上花费的时间）以及有关网站内所遵循路径的详细信息，特别是访问页面的顺序，以及有关设备操作系统的其他参数和/或用户的IT环境。</p>" +
           "<p>用户</p>" +
           "<p>除非另有说明，使用本站点的个人与数据主体一致。</p>" +
           "<p>数据主体</p>" +
           "<p>个人数据所指的自然人。</p>" +
           "<p>数据处理器（或数据管理器）</p>" +
           "<p>如本隐私政策所述，代表控制者处理个人数据的自然人或法人、公共机构、代理机构或其他机构。</p>" +
           "<p>数据控制者（或所有者）</p>" +
           "<p>单独或与他人共同确定个人数据处理的目的和方式（包括与本站点的运营和使用有关的安全措施）的自然人或法人、公共机构、代理机构或其他团体。除非另有说明，数据控制者是本站点的所有者。</p>" +
           "<p>这个应用程序</p>" +
           "<p>收集和处理用户个人数据的方式。</p>" +
           "<p>服务</p>" +
           "<p>本站点提供的服务如相关条款（如果有）和本站点上所述。</p>" +
           "<p>欧盟（或欧盟）</p>" +
           "<p>除非另有说明，本文件中所有提及欧盟的内容均包括欧盟和欧洲经济区的所有现有成员国。</p>" +
           "<p>Cookie</p>" +
           "<p>存储在用户设备中的小数据。</p>" +
           "<p>联系方式：在线客服</p>" +
           // "<p>邮箱：-------------------</p>" +
           "<p>最后更新时间：2023年10月27日</p>",
    aboutUs:"<p>关于我们</p>" +
            "<p>我司是一个专业公正的CS:GO皮肤开箱网站，在我司网站上，您可以立即获得更多的csgo皮肤，开箱以后皮肤可以随时放入Steam库存中。我司致力于为玩家提供更有趣的获取皮肤的方式，采用独特的获取模式，深受许多玩家喜爱。</p>" +
            "<p>箱子介绍</p>" +
            "<p>我司开箱肯定会掉落CS:GO皮肤，皮肤完全随机掉落，我公司坚持专业、公平的原则，为用户提供Steam游戏虚拟皮肤提取服务。特别提醒：消费需理性，娱乐为主。</p>" +
            "<p>箱子规则</p>" +
            "<p>用户可以花费相应数量的$根据不同类型的箱子进行开箱（$是玩家充值获取，禁止提现）；箱子内的皮肤为用户随机获得，皮肤掉落的概率在皮肤图表中公示，本平台的箱子能获得的皮肤范围以网站具体图表为准。我们希望玩家遵守相关法律法规，保持理性消费，文明开箱。</p>"
           // "<p>官方站点地址：www.我司.com</p>"
    /*  "<p>联系信息：在线客服</p>" +
     "<p>公司名称：------------</p>" +
     "<p>公司地址：--------------</p>" +
     "<p>电子邮件：------------</p>"*/,
    questions:
        "<h2>可以使用Steam钱包资金购买我司箱子吗?</h2>" +
        "<p>Steam余额不适用于本网站。您需要直接在我司网站上充值余额。</p>" +
        "<h2>我已充值余额，但资金并未转入我的个人资料。 我应该怎么办？</h2>" +
        "<p>你的付款可能会延迟 5-10 分钟。如果一小时内资金未转入，请联系支持人员，将付款详细信息写入我司支持。</p>" +
        "<h2>你的取回已等待超过30天</h2>" +
        "<p>在30天内没有取回的饰品将自动等值兑换为余额返至您的账户。</p>" +
        "<h2>因为弹窗死机我无法出售饰品！</h2>" +
        "<p>关闭窗口并刷新该页面。如果问题依然存在，清理浏览器缓存后再尝试。</p>" +
        "<h2>买箱之前</h2>" +
        "<p>检查自己的Steam账号市场交易权限是否受限，否则我们的机器人将无法发送皮肤至您的账号。登陆Steam账号打开市场，确认市场交易权限正常并且密码更新超过7天</p>",
    ruleDream:"<p>1.选择您想要的目标饰品，选择成功率5%-75%。追梦所需F币会随饰品价值和所选百分比区间进行变化。</p>" +
        "<p>2.每次追梦皆为独立计算，通过随机函数得到某个数值，与当前饰品价值乘以玩家控制百分比范围得到的所占用范围区间进行比对，若随机得到的数值与所在道具区间一致，则获得该饰品，若失败则获得随机安慰饰品。</p>" +
        "<p>3.追梦成功，您将获得目标饰品。追梦失败，您将获得随机安慰奖。</p>" +
        "<p>4.由于每次追梦为独立计算，当您连续三次的30%概率开启追梦饰品时不意味著有90%几率获得该饰品。当您连续3次30%都未掉落饰品时，第四次修改为10%概率开启不意味着100%获得饰品，相比于之前的30%获得几率可能会面临更大的失败概率。</p>" +
        "<p>5.请仔细查阅《用户协议》及相应条款，保持理性消费!</p>"
    ,
// 2、房间一但创建，不可取消，如在15分钟内未有用户加入，则房间自动解散，归还房间对应币值
    ruleBattle:"<p>1、创建活动时，可以选择拼箱人数（2/4人），一个场次最多可以选择 6 个盲盒。</p>" +
                "<p>2、房间一但创建，不可取消。</p>" +
                "<p>3、活动参与费用即本场活动开启的盲盒价格总和，支付费用后，即视为加入房间，中途退出房间，并不会退还盲盒或参与费。</p>" +
                "<p>4、多个玩家拼箱，并列胜出者各拿各的饰品，落败方饰品分解成福袋均分给胜出者</p>" +
                "<p>5、每场活动的结果受到（创建时间，用户ID，用户位置和开始时间等）多方面因素影响，为完全随机产生的结果。游戏完全公平可证</p>"
    ,
    }

}
