import staticData from "@/axios/staticData";
import {oss} from "@/common/config";

function getEnText(text, inKey, outKey) {
    let filter = staticData.exteriorList.filter(i => i[inKey] === text);
    if (filter.length > 0) {
        return filter[0][outKey];
    }
    return '';
}

function superReplaceEn(key) {
    return getEnText(key, 'cn', 'en') || key;
}

function superReplaceCn(key) {
    return getEnText(key, 'en', 'cn') || key;
}

/**
 * 递归处理数据
 * @param obj
 * @param language
 */
function replaceSkinNameWithMarketHashName(obj, language) {
    let reg = /^(?!http).*\.(jpg|jpeg|png|gif|bmp)$/
    for (let key in obj) {
        if (typeof obj[key] === 'object' && obj[key] !== null) {
            // 递归处理嵌套的对象
            replaceSkinNameWithMarketHashName(obj[key], language);
        }

        //不是中文  切换到英文显示名称
        if (language !== 'zh_CN' && key === 'skinName') {
            // 如果找到 "skinName"，则替换为 "marketHashName"
            obj[key] = obj['marketHashName'] || obj[key];
        }
        //不是中文  切换到英文显示名称
        if (language !== 'zh_CN' && key === 'skinDetailName') {
            // 如果找到 "skinName"，则替换为 "marketHashName"
            obj[key] = obj['marketHashName'] || obj[key];
        }
        //描述
        if (key === 'skinDegree') {
            // 如果找到 "skinDegree"，改成EN
            obj[key] = language === 'zh_CN' ? superReplaceCn(obj[key]) : superReplaceEn(obj[key]);
        } else if (key === 'degree') {
            // 如果找到 "skinDegree"，改成EN
            obj[key] = language === 'zh_CN' ? superReplaceCn(obj[key]) : superReplaceEn(obj[key]);
        }
        //把没有域名的图片，拼接oss域名
        if(reg.test(obj[key])){
            obj[key] = oss + obj[key]
        }
    }
}


export {replaceSkinNameWithMarketHashName}
