<!--
时间:2023/12/21/030
用法:右侧活动
-->
<template>
    <div class='rightNav '>
        <div class="list">
            <div class="list-button _mobile" v-if="rightShow" @click="rightShow = !rightShow">
                <img class="button-img" src="../assets/newactivity/activity/icon/right_open.png">
            </div>
            <div class="list-button-height _mobile" v-if="!rightShow" @click="rightShow = !rightShow">
                <img class="button-img" src="../assets/newactivity/activity/icon/right_close.png">
            </div>
            <div v-show="rightShow"
                 class="listItem"
                 v-for="(item,index) in navList"
                 :class="{
             'active':$route.path.indexOf(item.path) > -1 && item.path
           }"
                 @click="(item.path === 'turntable'|| item.name === 'lineServer') ? goServerOrTurn(item): goPath(item.path)"
                 :key="index">
                <img :src="item.img" alt="">
                <!--        <span>{{$t(`nav.${item.name}`)}}</span>-->
            </div>

        </div>
    </div>

    <TurnTable v-if="turntableShow" :data="turntableShow" @closeTurn="closeTurn"/>

</template>

<script>

    import {goPath} from "@/router/goPath";
    import {doTurntablePrize, getActivityConfig, getDeliver, getTurntable} from "@/axios/active";
    import TurnTable from "@/layout/TurnTable";
    import {mapState} from "vuex";
    export default {
        name: "rightNav",
        computed: {
            ...mapState(['getByUrl']),
        },
        components: {TurnTable,},
        data() {
            return {
                rotate: 0,
                resetRotate: 0,
                hitId: 0, //1-10
                drawStatus: false,
                // count:3 , //可抽奖次数
                turntableShow: false,//大转盘弹框 显示
                hasTodayReceive: false,//今日是否已经抽过奖
                resultShow: false,//中奖结果 显示
                resultText: '',
                rightShow: true,//右侧菜单是否展示
                isShow: true,//是否有活动
                resultData: null,
                navList: [],
                minAmount:0,//大转盘最小充值额度可以抽奖
                // prizeResult:null,//大转盘抽奖返回的结果
            }
        },

        async mounted() {
            await this.$nextTick();
            await this.getActivityConfig()
        },

        methods: {
            goPath,
            async getActivityConfig() {
                try {
                    const params = {}
                    /*获取活动配置*/
                    let data = await getActivityConfig(params)
                    /*获取到开启的活动数据*/
                    let activityData = data.filter(item => item.enabled === 1)
                    // type 1是笔笔送  2是大转盘 3是周一福利
                    // eslint-disable-next-line no-empty
                    let itemTurntable, itemDeliver, itemWelfare;
                    for (let i = 0; i < activityData.length; i++) {
                        switch (activityData[i].type) {
                            case 1:
                                itemDeliver = {
                                    name: 'deliver',
                                    path: '/deliver',
                                    img: require('../assets/newactivity/activity/icon/deliver.png')
                                }
                                break;
                            case 2:
                                itemTurntable = {
                                    name: 'turntable',
                                    path: 'turntable',
                                    img: require('../assets/newactivity/activity/icon/turntable.png')
                                }
                                break;
                            case 3:
                                itemWelfare = {
                                    name: 'welfare',
                                    path: '/welfare',
                                    img: require('../assets/newactivity/activity/icon/welfare.png')
                                }
                                break;
                        }
                    }
                    /*三个活动都没有开启隐藏活动列表*/
                   /* if (!itemTurntable && !itemDeliver && !itemWelfare) {
                        this.isShow = false;
                    } else {*/
                        if (itemTurntable) {
                            this.navList.push(itemTurntable)
                        }
                        if (itemDeliver) {
                            this.navList.push(itemDeliver)
                        }
                        if (itemWelfare) {
                            this.navList.push(itemWelfare)
                        }
                        /*添加在线客服*/
                  let  lineServer = {
                        name: 'lineServer',
                        path: 'http://txyl-web.huiyunsec.xyz/csgo/csgo.html',
                        img: require('../assets/newactivity/activity/icon/zxkf.png')
                    }
                    this.navList.push(lineServer)
                        // this.navList.push(itemTurntable,itemDeliver,itemWelfare)
                    // }
                    // this.total = data.total
                    // this.tableData = data.records
                    console.log("getActivityConfig", data)
                } catch (e) {
                    console.log(e)
                }
            },
            /*判断是大转盘还是在线客服*/
            goServerOrTurn(item){
                 if(item.name == "lineServer") {
                     window.open(this.getByUrl.serviceUrl,'_blank')
                     // window.open(item.path, '_blank')
                 }else{
                     this.getTurntable();
                 }
            },
            /*获取大转盘状态*/
            async getTurntable() {
                try {
                    const params = {}
                    /*获取活动配置*/
                    let data = await getTurntable(params)
                    this.minAmount = data.minRechargeAmount
                    /*显示大转盘*/
                    this.turntableShow = true
                    /*获取今日是否已经抽过奖*/
                    this.hasTodayReceive = data.hasTodayReceive
                    /*if(!this.turntableShow) {
                      return this.$Msg.warn("今日已抽奖")
                    }*/
                } catch (e) {

                }
            },
            closeTurn() {
                this.turntableShow = false;
            },
            resultClose() {
                this.resultShow = false;
            },
            show() {
                this.turntableShow = true;
            },

        },
        watch:{
            turntableShow:(turntableShow)=>{
        document.body.style.overflow=turntableShow?'hidden':'auto'

    }
        }
    }
</script>

<style scoped lang="scss">
    .rightNav {
        width: var(--window-rightNav-width);
        height: calc(100vh - var(--window-headerNav-height));
        overflow-y: auto;
        /*background-color: #1A1C24;*/
        display: inline-block;
        position: fixed;
        right: 0;
        /*top:var(--window-headerNav-height);*/
        top: 10rem;
        z-index: 20;

        .list {
            // background: white;
            .listItem {
                width: 4.92rem;
                height: 5.08rem;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                cursor: pointer;
                padding: 0.5rem;
                box-sizing: border-box;
                text-align: center;

                img {
                    width: 5.12rem;
                }

                span {

                }
            }

            .active {
                background: url("../assets/home/navigation-bj@2x.png") no-repeat center/cover;
            }
        }
    }


    @media screen and (max-width: $windowSize-md) {
        .rightNav {
            width: var(--window-rightNav-width);
            height: calc(100vh - var(--window-headerNav-height));
            overflow-y: auto;
            /*background-color: #1A1C24;*/
            display: inline-block;
            position: fixed;
            /*margin-top: 40%;*/
            right: 0;
            /*top:var(--window-headerNav-height);*/
            top: 12rem;
            z-index: 20;

            .list {
                background: #00000069;
                border: 1px solid #8A8988;
                border-radius: 5px;
                padding-bottom: 0.5rem;

                .button-img {
                    width: 1rem;
                }

                .list-button {
                    width: var(--window-rightNav-width);
                    padding-top: 0.5rem;
                    padding-bottom: 0.5rem;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    border-bottom: 1px solid #8A8988;
                }

                .list-button-height {
                    width: 2rem;
                    height: 4rem;
                    padding-top: 0.5rem;
                    padding-bottom: 0.5rem;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    border-bottom: 1px solid #8A8988;
                }

                .listItem {
                    margin-top: 0.5rem;
                    width: 4.92rem;
                    height: 5.08rem;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    cursor: pointer;
                    padding: 0.5rem;
                    box-sizing: border-box;
                    text-align: center;

                    img {
                        width: 5.12rem;
                    }

                    span {

                    }
                }

                .active {
                    background: url("../assets/home/navigation-bj@2x.png") no-repeat center/cover;
                }
            }
        }
    }
</style>
