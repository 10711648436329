<!--
时间:2024/2/1/001
用法:
-->

<template>
  <PopupTemplate :show="show" @close="close">
    <template v-slot:default>
      <div class="container">
        <div class="downLoadTitle">
          扫码下载APP或点击链接下载
        </div>
        <Qrcode :value="qrcodeLink" :size="size"/>
        <div class="btnBox">
          <div v-for="(item,index) in list" :key="index"
               class=" btn"
               :class="{
              'notOpen':!item.url,
              'android':item.type === 1,
              'ios':item.type === 2,}"
               @click="download(item.url)">
            {{ item.name }}
          </div>
        </div>
      </div>
    </template>
  </PopupTemplate>
</template>

<script>
import PopupTemplate from "@/components/popup/PopupTemplate.vue";
import Qrcode from "@/components/ToolsComponents/Qrcode.vue";
import {mapState} from "vuex";

export default {
  name: "AppDownLoad",
  components: {Qrcode, PopupTemplate},
  computed: {
    ...mapState(['getByUrl']),
    list() {
      return [
        {
          name: '安卓下载',
          type: 1,
          url: this.getByUrl?.androidUrl
        },
        {
          name: 'ios下载',
          type: 2,
          url: this.getByUrl?.iosUrl
        }
      ]
    }
  },
  props: {
    show: {
      type: Boolean,
    },
  },
  data() {
    return {
      qrcodeLink: window.location.origin + '/downloadApp',
      size: document.documentElement.clientWidth >= 992 ? 10 : 15,
    }
  },
  methods: {
    close() {
      this.$emit('close')
    },
    download(url) {// 1、安卓 2、ios
      if (!url) return  this.$Msg.warn(this.$t('confirm.notYetOpn'))
      window.open(url, '_blank')
    }
  }
}
</script>

<style scoped lang="scss">
@media screen and (min-width: $windowSize-md) {
  .downLoadTitle {
    margin-bottom: 0.5rem;
  }
  .container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 1rem 2rem;
  }
  .btnBox {
    display: flex;
    margin-top: 0.5rem;
    justify-content: center;
    width: 100%;

    .btn {
      width: 5rem;
      height: 2rem;
      border-radius: 0.42rem;
      @include center;
      background-color: #FBC600;
      color: black;
      font-size: 0.8rem;
      margin: 0 0.33rem;
      font-weight: bold;
    }

    .android {
      background-color: #27D17E;
    }

    .notOpen {
      background-color: #979CA7;
      cursor: not-allowed;
    }
  }
}

@media screen and (max-width: $windowSize-md) {
  .downLoadTitle {
    margin-bottom: 0.5rem;
  }
  .container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 1rem;
  }
  .downLoadTitle {
    margin-bottom: 1rem;
  }
  .btnBox {
    display: flex;
    margin-top: 0.5rem;
    justify-content: center;
    width: 100%;
    flex-wrap: wrap;

    .btn {
      width: 100%;
      height: 3.5rem;
      border-radius: 0.42rem;
      @include center;
      background-color: #FBC600;
      color: black;
      font-size: 1.2rem;
      margin: 0.33rem 0;
      font-weight: bold;
    }

    .android {
      background-color: #27D17E;
    }
  }
}
</style>