<!--
时间:2023/9/1/001
用法:
-->

<template>
  <div class='selectLanguage' id="languageList" v-click-outside="closeList" @click="selectChange">
    <!--    <input/>-->
    <div class="selectCurrent">
      <img :src="current.flag" class="currentFlag" alt="">
      <span class="nation">{{ current.nation }}</span>
      <img class="downArrow"
           src="../../assets/icon/down-arrow.png"
           :class="show ? 'downArrowActive' :''"
           alt="">
    </div>
    <div class="listBox" ref="languageList"
         :class="{
          'active':show
        }">
      <ul class="list">
        <li v-for="(item,index) in list"
            :key="index"
            :class="item.index === current.index ? 'activeLang':''"
            @click="changeLanguage(item.language)">
          <img :src="item.flag" class="itemFlag" alt="">
          <span class="languageName">{{ item.languageName }}({{ item.nation }})</span>
        </li>
      </ul>
    </div>

  </div>
</template>

<script>
export default {
  name: "SelectLanguage",
  props: {
    list: {
      type: Array,
      default: () => {
        return []
      }
    },
    value: {
      type: String, // list里面的index
    }
  },
  computed: {
    current() {
      if (this.list.length === 0) return null
      let current = this.list.find((item) => {
        return item.language === this.value
      })
      return current
    }
  },
  data() {
    return {
      show: false
    }
  },
  methods: {
    selectChange() {
      this.show = !this.show
    },
    changeLanguage(val) {
      this.show = false
      this.$emit('changeLanguage', val)
    },
    closeList(e) {
      this.show = false
    }
  },
}
</script>

<style scoped lang="scss">
.selectLanguage {
  width: 5rem;
  position: relative;

  &:focus {
    outline: none;
  }

  .selectCurrent {
    display: flex;
    align-items: center;
    padding: 0 0.2rem;
    width: 3.5rem;
    height: 1.2rem;
    background: rgba(255, 255, 255, 0);
    border: 0.05px solid #77668A;
    border-radius: 0.8rem;
    box-sizing: border-box;
    font-size: 0.58rem;
    position: relative;
    cursor: pointer;

    .currentFlag {
      width: 0.92rem;
      height: 0.92rem;
      border-radius: 50%;
      margin-right: 0.3rem;
    }

    .downArrow {
      width: 0.33rem;
      position: absolute;
      top: 0.48rem;
      right: 0.3rem;
      transition: transform 0.2s linear;
    }

    .downArrowActive {
      transform: rotate(180deg);
    }
  }

  .listBox {
    background-color: #181A21;
    max-height: 0;
    position: absolute;
    z-index: 2;
    overflow: hidden;
    box-sizing: border-box;
    transition: all 0.15s linear;
    font-size: 0.58rem;
    @media screen and (min-width: $windowSize-md) {
      top: calc(100% + 0.2rem);
      left: 0;
    }

    .list {
      border-radius: 0.33rem;
      border: 0.02rem solid #414141;
      overflow-y: auto;
      //height: 6rem;

      li {
        display: flex;
        align-items: center;
        padding: 0.3rem 0.5rem;
        cursor: pointer;
        color: #ACAFBB;
        background-color: #18181B;

        &:hover {
          color: #fff;
          background-color: #292A2F;
        }

        img {
          margin-right: 0.3rem;
          width: 0.88rem;
          border-radius: 50%;
          overflow: hidden;
        }

      }

      .activeLang {
        background-color: #292A2F;
        color: #fff;
      }
    }

  }

  .active {
    max-height: 8rem;
  }
}

@media screen and (max-width: $windowSize-md) {
  .selectLanguage {
    width: 8rem;
    height: 3rem;
    background: #1E2029;
    border-radius: 0.25rem;
    @include center;
    position: relative;

    &:focus {
      outline: none;
    }

    .selectCurrent {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      padding: 0.7rem 1.2rem;
      background: rgba(255, 255, 255, 0);
      border: 0;
      border-radius: 0.8rem;
      box-sizing: border-box;
      font-size: 0.58rem;
      position: relative;
      cursor: pointer;

      .currentFlag {
        width: 1.8rem;
        height: 1.8rem;
        border-radius: 50%;
        margin-right: 0.8rem;
      }

      .nation {
        font-size: 1rem;
      }

      .downArrow {
        width: 0.71rem;
        position: absolute;
        top: 1.3rem;
        right: 0.83rem;
        transition: transform 0.2s linear;
      }

      .downArrowActive {
        transform: rotate(180deg);
      }
    }

    .listBox {
      background-color: #181A21;
      max-height: 0;
      position: absolute;
      bottom: calc(100% + 0.5rem);
      right: 0;
      z-index: 2;
      overflow: hidden;
      box-sizing: border-box;
      transition: all 0.15s linear;
      font-size: 1rem;

      .list {
        border-radius: 0.33rem;
        border: 0.02rem solid #414141;
        height: 10rem;

        li {
          display: flex;
          align-items: center;
          padding: 0.5rem 0.9rem;
          cursor: pointer;
          color: #ACAFBB;
          background-color: #18181B;

          &:hover {
            color: #fff;
            background-color: #292A2F;
          }

          img {
            margin-right: 0.3rem;
            width: 1.58rem;
          }

        }

        .activeLang {
          background-color: #292A2F;
          color: #fff;
        }
      }

    }

    .active {
      max-height: 10rem;
    }
  }

}
</style>