//rem自适应配置
export const remConfig = {
    configWidth: [ //configWidth 每一个对象代表一个窗口配置，请按照windowWidth大小排序
        {
            designWidth: 1920,
            windowWidth: 992,
            fontSize: 24,
        },
        {
            designWidth: 750,
            windowWidth: 0,
            fontSize: 24,
        }
    ]
}

// 语言切换
export const language = [
    {
        index: 1,
        languageName: "中文",
        language: 'zh_CN',
        nation: 'CN',
        flag: require('../assets/flag/lang-cn.svg')
    },
    // {
    //     index: 2,
    //     languageName: "English",
    //     language: "en_US",
    //     nation: 'EN',
    //     flag: require('../assets/flag/lang-en.svg')
    // },
    // {
    //     index: 3,
    //     languageName: "Türkçe",
    //     language: "tr_TR",
    //     nation: 'TR',
    //     flag: require('../assets/flag/tuerqi.png')
    // },
  /*  {
        index: 4,
        languageName: "Japan",
        language: "en_JP",
        nation: 'JP',
        flag: require('../assets/flag/lang-en.svg')
    },*/
]

export const languageObj = language.reduce((obj, item) => {
    obj[item.language] = item;
    return obj;
}, {});

//默认初始语言
export const defaultLanguage = "zh_CN"

export const webSocketType = [
    {
        type:'',
        describe:''
    }
]

//装备等级对应的颜色
export const skinBgColor = {
    0:'#FFA22A',
    1:"#CA002B",
    2:"#8800F4",
    3:"#2F6EFD",
    4:"#CFCFCF",
}

//steam社区
export const link = {
    steamcommunity:'https://steamcommunity.com/login/home/?goto=%2Fid%2Fme%2Ftradeoffers%2Fprivacy#trade_offer_access_url'

}

//用户注册随机名字
export const userNickname = [
    "怀念。", "宅一起", "追逐你", "掩耳〃", "__衰", "ㄗs鋒",
    "悲伤￠", "桑心。", "步行者", "断￠点", "迩回眸", "夏▄雨",
    "繁体字", "-装爱", "渡沧海", "逆光影", "浅色枫", "令狐臭",
    "玩寂寞", "喵骚年", "影子的泪", "夏丶陌汐", "歌淺婼離",
    "空城孤王", "发条兔子", "灵魂之神", "念你南笙", "临波望月",
    "乱世情缘", "海浪精灵", "泪芯伤痕", "孤独寒冰", "狱血魔神",
    "緘緘恋雨", "南柯无痕", "夜雨☆梦", "雨的印记", "相逢不易",
    "缱绻光纪", "遇强则强", "夏倦", "星沉", "清川", "梵声",
    "黑牌", "船梢", "闲鸥", "春蝉", "秦月", "风急", "钩吻",
    "江鬼", "叹软", "夜鹭", "岛风", "野趣", "鬼鸟", "甜俊",
    "学痞", "和风", "醉沙疆", "云中朵", "垂耳兔", "海上花",
    "蝴蝶结", "諵.笙", "弥桑花", "梦銮＊", "幻竹寰", "风萧萧",
    "洛婉儿", "云浅月", "温盏年", "顾璃曦", "苏绾落", "大宝宝",
    "紫鲽恋", "兔小琦", "萌琳达", "兔大萌","蝶恋花"
]

export const az = 'abcdefghijklmnopqrstuvwxyz'
export const AZ = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'
export const To9 = '0123456789'

//远程图片域名
export const oss = 'https://boxblind.oss-accelerate.aliyuncs.com'
