<!--
时间:2023/12/20/020
用法:任务中心
-->

<template>
  <div class='MissionCenter'>
    <div class="tab">
      <div class="tabItem btn"
           v-for="(item,index) in tab"
           :class="{'active':tabIndex === index}"
           @click="changeTab(index)"
           :key="index">
        <img class="icon" :src="item.img" alt="">
        <span>{{ item.name }}</span>
      </div>
    </div>
    <div class="list">
      <div class="listItem"
           v-for="(item,index) in list"
           :key="index">
        <div class="left">
          <div class="name">{{ item.name }}
            (<span style="color: #FBC600">{{ item.hasBean }}</span> / {{ item.threshold }})
          </div>
          <div class="describe">
            任务奖励:
            <span class="minReward">保底<!--<MoneyCoin :money="item.minReward"/>-->{{ item.minReward }} F</span> ,
            最高{{ item.maxReward }} F
          </div>
        </div>
        <div class="right">
          <div class="taskBtn btn"
               :class="{'received':item.hasGet === 1}"
               @click="taskBtn(item)">
            {{ item.btnText }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import * as api from "@/axios/active";
import {ref, defineComponent, onMounted, getCurrentInstance} from "vue";
import router from "@/router";
import store from "@/store";
let maxReward = ref([18,88,188,288,588])

let {proxy} = getCurrentInstance()
defineComponent({
  name: "MissionCenter",
})
const tab = ref([
  {
    name: "盲盒任务",
    img: require('../../assets/icon/box@2x.png'),
    path:"/homes",
  },
  {
    name: "对战任务",
    img: require('../../assets/icon/Battles@2x.png'),
    path:"/battle",
  },
  {
    name: "追梦任务",
    img: require('../../assets/icon/Dreams@2x.png'),
    path:"/dreams",
  },
])
let tabIndex = ref(0)
let list = ref({})

const btnStatus = {
  '0': 'GO',
  '1': '领取',
  '2': '已领'
}
const getConsume = async () => {
  try {
    const params = {
      type: tabIndex.value + 1
    }
    let data = await api.getConsume(params)
    data = data.map(item => {
      item.status = getStatus(item.hasBean, item.threshold, item.hasGet)
      item.btnText = btnStatus[item.status]
      return item
    })
    list.value = data
    console.log('getConsume', data)
  } catch (e) {
    console.log(e)
  }
}

const receiveAward = async (id) => {
  try {
    const params = {id}
    let data = await api.receiveAward(params)
    await getConsume()
    await store.dispatch('actionUserInfo')
    proxy.$Msg.success('领取成功' + data)
    console.log('getConsume', data)
  } catch (e) {
    console.log(e)
  }
}
const taskBtn = (item) => {
  switch (item.status) {
    case '0':
      router.push(tab.value[tabIndex.value].path)
      store.commit('setActiveShow', false)
      break;
    case '1':
      receiveAward(item.id)
      break;
    case '2':
      proxy.$Msg.warn('已领取')
      break;
    default:
      return
  }
}

const getStatus = (hasBean, threshold, hasGet) => {
  if (hasBean < threshold) return '0'
  if (hasGet === 0) return '1'
  if (hasGet === 1) return '2'
}

const changeTab = (index) => {
    tabIndex.value = index
    getConsume()
}

onMounted(() => {
  getConsume()
})


</script>

<style scoped lang="scss">
.tab {
  display: flex;
  align-items: center;
  margin-bottom: 0.29rem;

  .tabItem {
    min-width: 5.85rem;
    height: 2.08rem;
    background: rgba(255, 255, 255, 0.05);
    border-radius: 0.33rem;
    box-sizing: border-box;
    padding: 0 0.33rem;
    margin-right: 0.21rem;
    @include center;

    .icon {
      width: 0.98rem;
      margin-right: 0.33rem;
      opacity: 0.3;
    }
  }

  .active {
    background-color: rgba(251, 198, 0, 0.3);
    color: #FBC600;

    .icon {
      opacity: 1;
    }
  }
}

.list {
  .listItem {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem;
    background: rgba(255, 255, 255, 0.05);
    border-radius: 0.42rem;
    margin-bottom: 0.21rem;
    position: relative;

    &::after {
      content: '';
      display: block;
      height: 50%;
      width: 2px;
      @include p-ab-centerY;
      left: 0;
      background-color: #FBC600;
    }

    .name {
      font-size: 0.75rem;
      font-weight: bold;
      margin-bottom: 0.33rem;
    }

    .describe {
      display: flex;
      align-items: center;
      font-size: 0.58rem;

      .minReward {
        color: #FBC600;
        @include center;

        :deep(.MoneyCoin) {
          margin: 0 0.33rem;
        }
      }
    }

    .taskBtn {
      background: url("../../assets/active/GO1_a@2x.png") no-repeat center/cover;
      width: 3.25rem;
      height: 1.83rem;
      @include center;
      font-size: 0.75rem;
      font-weight: bold;
      color: #1C2037;
    }

      .received {
        background: url("../../assets/active/GO1_b@2x.png") no-repeat center/cover;
        color: #5C5C5C;
      }
    }
  }

@media screen and (max-width: $windowSize-md) {
  .tab {
    display: flex;
    align-items: center;
    margin-bottom: 1.4rem;
    justify-content: space-around;

    .tabItem {
      min-width: 5.85rem;
      height: 4.08rem;
      border-radius: 0.33rem;
      box-sizing: border-box;
      padding: 0 0.33rem;
      margin-right: 0.21rem;
      @include center;
      flex-direction: column;

      .icon {
        width: 1.92rem;
        margin-right: 0.33rem;
      }
    }

    .active {
      color: #FBC600;
    }
  }

  .list {
    .listItem {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 1.33rem;
      background: rgba(255, 255, 255, 0.05);
      border-radius: 0.42rem;
      margin-bottom: 0.88rem;
      position: relative;

      &::after {
        content: '';
        display: block;
        height: 50%;
        width: 4px;
        @include p-ab-centerY;
        left: 0;
        background-color: #FBC600;
      }

      .name {
        font-size: 1.08rem;
        font-weight: bold;
        margin-bottom: 0.7rem;
      }

      .describe {
        display: flex;
        align-items: center;
        font-size: 1rem;
        color: #868898;

        .minReward {
          @include center;

          :deep(.MoneyCoin) {
            margin: 0 0.33rem;
          }
        }
      }

      .taskBtn {
        background: url("../../assets/active/GO1_a@2x.png") no-repeat center/cover;
        width: 5.39rem;
        height: 3.05rem;
        @include center;
        font-size: 1.08rem;
        font-weight: bold;
        color: #1C2037;
      }

      .received {
        background: url("../../assets/active/GO1_b@2x.png") no-repeat center/cover;
        color: #5C5C5C;
      }
    }
  }
}
</style>
