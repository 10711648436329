export default {
    exteriorList:[
        {en:'Factory New',cn:'崭新出厂'},
        {en:'Factory-New',cn:'崭新出厂'},
        {en:'Minimal Wear',cn:'略有磨损'},
        {en:'Minimal-Wear',cn:'略有磨损'},
        {en:'Field-Tested',cn:'久经沙场'},
        {en:'Well-Worn',cn:'破损不堪'},
        {en:'Battle-Scarred',cn:'战痕累累'},
        {en:'Not-Painted',cn:'无涂装'},
        {en:'Not Painted',cn:'无涂装'}
    ]
}