export default {
    nav: {
        // ナビゲーションバー
        welfare: "福祉",
        mySkins: 'マイアイテム',
        myWelfare: 'マイウェルフェア',
        myReward: '私の報酬',
        historicalBilling: '過去の請求履歴',
        login: "ログイン",
        logout: "ログアウト",
        blindBox: 'ブラインドボックス',
        rollRoom: "ロールルーム",
        dream: "夢を追う",
        battle: "バトル",
        bomb: "爆破",

        // ログインウィンドウ
        loginTitle: 'csgo8を使用できることを確認する必要があります：',
        my18: '18歳以上です',
        agree: "次に同意します",
        usrAgreement: 'ユーザーアグリーメント',
        privacyAgreement: 'プライバシーアグリーメント',
        antiMoneyLaundering: 'マネーロンダリング防止法',
        // PC版フッターの内容
        openCases: '開封済みケース総数',
        users: '登録ユーザー数',
        online: 'オンラインユーザー数',
        footerText1: "ブラインドボックスにはさまざまな遊び方があり、アイテムの取り出しは安全で便利です！",
        // モバイル版サイドバー
        games: "ゲーム",
        preferential: "特典",
        free: "無料",
        recharge: "リチャージ",
        cdk: "CDK",
        other: "その他",
        QQ: "QQ",
        custServ: "カスタマーサービス",
        App: "アプリ",
    },
    blindBox: {
        //開封ページ
        open: "オープン",
        buy: "購入",
        bestDrp: "ベストドロップ",
        boxContains: "ボックスに含まれるもの",
        buyTips1: "ボックスを開くのに十分な資金がありません！"
    },
    roll: {
        ofcl: "公式",
        anchor: "アンカー",
        iParticipatedIn: "私の参加",
        haveInHand: "進行中",
        itsOvr: "終了しました",
        viewingRooms: "部屋を表示",
        joinNow: "今すぐ参加",
        deadline: "締め切り",
        totVal: "合計価値",
        items: "アイテム",
        players: "プレイヤー",
        //ロールの詳細
        countdownToLottery: "抽選までのカウントダウン",
        prtpConditions: "参加条件",
        includingPrizes: "賞品を含む",
        participatingUsers: "参加ユーザー",
        awrdLst: "受賞者リスト",
        joinRm: "部屋に参加"
    },
    dream: {
        chooseAccessories: "アクセサリーを選択",
        probability: "確率",
        upg: "アップグレード",
        upgTips: "* アップグレードの確率は{num1}%から{num2}%までです",
        //夢のアクセサリー選択ポップアップ
        inputPlaceholder1: "アイテムのキーワードを入力",

    },
    battle: {
        //バトルリスト
        allBattles: "すべてのバトル",
        rankingLst: "ランキングリスト",
        historicalRecords: "履歴",
        myRecords: "マイレコード",
        creABattle: "バトルを作成",
        //バトルを作成
        addBox: "ボックスを追加",
        robot: "ロボット",
        combatCost: "戦闘コスト",
        createBattle: "バトルを作成",
        chooseAnItem: "アイテムを選択",
        incorrectNumberOfPeople: "人数が正しくありません",
        inputPlaceholder1: "アイテムのキーワードを入力",
        thisMorning: "今朝",
        thisAfternoon: "今日の午後",
        selected: "選択されたアイテム：{num}個",
        coVal: "合計金額",
        //バトルの詳細
        ready: "準備完了",
        drawnJewelry: "抽選されたジュエリー",
        obtainJewelry: "ジュエリーを取得",
        //ランキング
        today: "今日",
        yesterday: "昨日",
        //バトル終了ポップアップ
        battleIsOvr: "バトルが終了しました",
        winner: "勝者",
        oneMoreRound: "もう1ラウンド",

    },
    information: {
        //個人センターホームページ
        invtCd: "招待コード",
        bal: '残高',
        rchg: "リチャージ",
        steamLinkTips1: "1. 一度トレードリンクをバインドすると、変更できなくなります。注意して操作してください。同じリンクのトークンの更新に制限はありません。",
        steamLinkTips2: "2. 他の人のSteamトレードリンクをバインドしないでください",
        steamInputPlaceholder1: "Steamトレードリンクを入力してください",
        mySkins: 'マイアイテム',
        myWelfare: 'マイウェルフェア',
        rchgDetails: "リチャージの詳細",
        incomeDetails: "収支の詳細",
        // マイアイテムページ
        retrieving: "取得中",
        retrieveRecords: "取得履歴",
        numOrnaments: "合計{num}個のアクセサリー",
        obtainingTm: "取得時間",
        val: "価値",
        selectedNum: "選択済み{num}個",
        retrieve: "取り戻す",
        chooseABox: "ボックスを選んでアイテムを入手してください",
        noItems: "まだアイテムがありません",
    },
    confirm: {
        success: "成功",
        warning: "警告",
        error: "エラー",
        // スロットマシンの抽選結果ポップアップ
        decompose: "分解",
        decomposeAll: "すべて分解",
        cont: "開封を続ける",
        // 確認ポップアップ
        confDecomposition: "{num}個のアイテムを分解しますか？",
        common: "共通",
        cancel: "キャンセル",
        confirm: "確認",
        // メッセージポップアップ
        decomposeSuccess: "分解が成功しました",
        theMinimumProbabilityIs: "最小確率は{num}です",
        notLoggedIn: "ログインしていません",
        noJewelrySelected: "アクセサリーが選択されていません",
        agreeToATreaty: "以下の契約に同意してください",
        copySuccess: "コピーが成功しました",
        copyError: "コピーに失敗しました",
    },
    // 一般
    common: {
        // 単位
        day: '日',
        hh: "時間",
        mm: '分',
        ss: "秒",
        piece: "個",
        // 一般的なタイトル
        back: "戻る",
        gameRules: "ゲームルール",
        // 一般的なテーブル
        players: "プレイヤー",
        price: "価格",
        reset: "リセット",
        cases: "ケース",
        value: "価値",
        actions: "アクション",

        watch: "観戦",
        join: "参加",
        ranking: "ランキング",
        bestDrp: "ベストドロップ",
        date: "日付",

        st: "ステータス",
        purp: "用途",
        amtOfMoney: "金額",

        allElection: "すべて選択"

    }
}
