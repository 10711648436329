import request from './index'

// 账户记录
export const getNotes = (params) => {
    return request({
        url: '/api/user/account/notes',
        method: 'GET',
        params
    })
}
// 我的背包
export const getMybackpack = (params) => {
    return request({
        url: '/api/user/mybackpack',
        method: 'GET',
        params
    })
}
// 取回装备
export const extractApply = (data) => {
    return request({
        url: '/api/storehouse/extractApply',
        method: 'POST',
        data
    })
}

// 更新用户信息
export const updateUserInfo = (data) => {
    return request({
        url: '/api/user/update/userInfo',
        method: 'POST',
        data
    })
}

//获取用户分享详情
export const getUserShare = (params) => {
    return request({
        url: '/api/user/share/info',
        method: 'GET',
        params
    })
}

// 用户奖励列表
export const getRewardList = (params) => {
    return request({
        url: '/api/user/reward/list',
        method: 'GET',
        params
    })
}

// 分享等级说明
export const promotionLevel = (data) => {
    return request({
        url: '/api/user/promotion/level',
        method: 'POST',
        data
    })
}


// 实名认证接口
/**
 * String  name; // '真实姓名(经过验证之后 备份保存',  String   idCard;//'身份证号(经过验证之后 备份保存',
 * */
export const checkIdCard = (data) => {
    return request({
        url: '/api/user/checkIdCard',
        method: 'POST',
        data
    })
}

/**
 * params  背包装备ID集合，
 * */
export const replaceOpen = (data) => {
    return request({
        url: '/api/compose/open',
        method: 'POST',
        data
    })
}

/**
 * params  背包装备ID集合，
 * */
export const getComposeConfigs = (data) => {
    return request({
        url: '/api/compose/getComposeConfigs',
        method: 'GET',
        data
    })
}
