import request from './index'


export const openCDK = (data) => {
    let params = {
        cdkValue: data
    }
    return request({
        url: '/api/cdkRed/open/' + data,
        method: 'POST'
    })
}