import axios from 'axios'
import { createDialog } from '@/components/Message'
import store from '@/store'
import {ElLoading} from "element-plus";
import {defaultLanguage, languageObj} from "@/common/config";
import {encodeAesString,decodeAesString,setDefaultAesKeyInfoIv} from "@/common/encryption";
import {replaceSkinNameWithMarketHashName} from "@/axios/httpUtil";
import { Base64 } from 'js-base64';
import CryptoJS from 'crypto-js';
const instance = axios.create({ timeout: 1000 * 10 })
instance.defaults.headers.post['Content-Type'] = 'application/json'
// instance.defaults.headers.post['Cache-Control'] = 'no-cache'
instance.defaults.baseURL =  '' ;
// instance.defaults.baseURL = process.env.VUE_APP_BASE_API;

// 添加一个请求锁变量
let lockKey = ''
let loading = '';
let loadingLockKey = 'loadingLock';
let language = localStorage.getItem('language') || defaultLanguage
let  isPost = false
instance.interceptors.request.use((config) => {
    //方法锁
    if(config.lock){
        lockKey = config.url;
       let isRequesting = localStorage.getItem(lockKey);
        if(isRequesting&&isRequesting==="true"){
            return Promise.reject(new Error('lock'));
        }else {
            localStorage.setItem(lockKey,"true")
        }
    }

    //loading 状态开启
    if(config.loading!==false){
        loading  = ElLoading.service({
            lock: false,
            background: 'rgba(0, 0, 0, 0.7)',
        });
        localStorage.setItem(loadingLockKey,"true")
    }
    const token = localStorage.getItem("token")
    config.headers['content-language'] = language
    config.headers['Cache-Control'] = "no-cache"
    config.headers['remark'] = dealEncrypted()
    if (token) {
        config.headers.token = token
    }
    if(config.method == 'post'){

        config.data = {
            ...config.data,
            _t:Date.parse(new Date()) / 1000
        }
    }else if (config.method == 'get') {
        config.params = {
            ...config.params,
            _t:Date.parse(new Date()) / 1000
        }
    }

    // if(API.cache && config.data){
    // // if(config.data){
    //     //生产日志打印
    //     if(typeof CSLog !== 'undefined' ){
    //         CSLog(config.url,config.data,API.cache)
    //     }
    //     isPost = true;
    //     let iv = new Date().getTime()+"000";
    //     setDefaultAesKeyInfoIv(iv);
    //      config.data = {data:encodeAesString(JSON.stringify(config.data)),time:iv};
    // }

    return config;
}, error => Promise.reject(error))

/**
 * remark加密计算
 * @param msg
 */
function dealEncrypted(){

    //算出时差,并转换为毫秒：
    let offset8 = new Date().getTimezoneOffset()* 60 * 1000;
    //算出现在的时间：
    let nowDate8 = new Date().getTime();
    //此时东8区的时间
    var currentZoneDate = new Date(nowDate8 + offset8 + 8*60*60*1000).getTime();
    // @ts-ignore
    const hostname = process.env.NODE_ENV  == 'development' ? '192.168.103.4' : location.hostname
    let headerParams =  `${hostname}_${currentZoneDate}`;
    // let headerParams =  `${'192.168.20.26:8090'}_${currentZoneDate}`;
    //加密
    let encryptByDES = (message, key) => {
        const keyHex = CryptoJS.enc.Utf8.parse(key);
        const encrypted = CryptoJS.DES.encrypt(message, keyHex, {
            mode: CryptoJS.mode.ECB,
            padding: CryptoJS.pad.Pkcs7
        });
        return encrypted.toString();
    };
    let base64Data = Base64.encode(decodeURIComponent(headerParams));
    let key = base64Data.slice(0, 8);
    let encrypted = encryptByDES(base64Data, key);
    return `${key}${encrypted}`;
}
/**
 * 延迟关闭
 * 关闭触发
 * @param msg
 */
function closeLock(msg){
    if(loading) {
        //延迟 300ms
        setTimeout(()=>{
            let item = localStorage.getItem(loadingLockKey);
            if( item==null){
                loading.close()
            }
        },100);
        localStorage.removeItem(loadingLockKey)
    }
    if(lockKey && msg!=='lock' ) localStorage.removeItem(lockKey)
}
let isTimeoutErrorShown = false; // 标志变量，表示是否已经显示了超时错误提示

instance.interceptors.response.use(
    res => {
        // 请求完成后，将请求锁设置为false，表示没有请求在进行
        closeLock('close')

        if (res.status === 200) {
            switch (res.data.code) {
                case 200:
                    let data = res.data.data;
                    replaceSkinNameWithMarketHashName(data, language)
                    return Promise.resolve(data)
                case 8000:
                    createDialog.error(res.data.msg)
                    localStorage.removeItem('token')
                    localStorage.removeItem('fromPath')
                    store.commit('setUserInfo', {})
                    store.commit('setLoginShow', true)
                    return Promise.reject(res.data)
                default:
                    createDialog.error(res.data.msg)
                    return Promise.reject(res.data)
            }
        }
    },
    error => {
        // 请求超时错误处理
        if (!isTimeoutErrorShown && error.code === 'ECONNABORTED' && error.message.indexOf('timeout') !== -1) {
            createDialog.error('请求超时，请重试')
            isTimeoutErrorShown = true; // 设置标志变量为 true，表示已经显示了提示
            setTimeout(() => { //设置定时器，避免多次弹窗
                isTimeoutErrorShown = false;
            },5000)
        }
        closeLock(error.message)
        return Promise.reject(error);
    }
);
export default instance
