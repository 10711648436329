<!--
时间:2023/12/20/020
用法:签到
-->

<template>
  <div class='SignIn'>
    <div class="signList">
      <div class="signItem" v-for="(item) in list" :key="item.type">
        <div class="signTitle">
          <div class="titleLeft">
            <div class="titleName">{{ $t(`active.${this.signType[item.type]}`) }}</div>
            <div class="signedDay"
                 v-html="$t(`active.signedInDays`,{num:item.days,html1:'<span>',html2:'</span>'})"></div>
          </div>
          <div class="titleRight">
            <div class="signBtn btn" @click="signBtn(item)">
              <img v-if="item.days === 7 && item.receive === 0" src="../../assets/active/redEnvelope@2X.png" alt="">
              {{ $t(`active.${getBtnText(item.status)}`) }}
            </div>
          </div>
        </div>
        <div class="signDay">
          <div class="days" v-for="day in 7" :key="day"
               :class="{
            'signedIn':day <= item.days,
            'today':item.todaySign === 0 && day === (item.days + 1)
            }">
            <template v-if="day > item.days">
              <span class="num">{{ day }}</span>
              <span class="co">Day</span>
            </template>
            <template v-if="day <= item.days">
              <img src="../../assets/active/register.png" alt="">
            </template>
          </div>
        </div>
        <div class="signTips">
          {{ $t(`active.tips`, {type: $t(`active.${this.signType[item.type]}`), num: item.maxReward}) }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {getSignIn, sign, signReward} from "@/axios/active";
import {mapMutations} from "vuex";

export default {
  name: "SignIn",
  rechargeSign:0,//是否可以充值签到 1是 0否
  data() {
    return {
      list: [],
      signType: {
        1: "signIn",
        2: "rechargeSign",
      }
    }
  },
  methods: {
    ...mapMutations(['setActiveShow']),
    async getSignIn() {
      try {
        this.list = []
        for (let i = 1; i < 3; i++) { // 1:登陆签到 2:充值签到
          let data = await getSignIn({type: i})
          data.type = i
          data.status = this.getStatus(data.todaySign, data.receive, data.days)
          this.rechargeSign = data.rechargeSign;
          this.list.push(data)
        }
        console.log("getSignIn", this.list)
      } catch (e) {
        console.log(e)
      }
    },
    getBtnText(status) {
      switch (status) {
        case 1:
          return "sign"
        case 2:
          return "signedIn"
        case 3:
          return "claim"
        case 4:
          return "claimed"
        default:
          return ""
      }
    },
    getStatus(todaySign, receive, days) {
      if (receive === 1) return 4 // 领取
      if (days === 7) return 3 // 待领取
      if (todaySign === 1) return 2  // 已签到
      if (todaySign === 0) return 1 //未签到
    },
    signBtn(item) {
      console.log("signBtn1",item.status)
      switch (item.status) {
        case 1: //去签到
          this.sign(item.type)
          break;
        case 2: //已签到
          this.$Msg.warn(this.$t(`active.signedIn`))
          break;
        case 3: //待领取
          this.signReward(item.id)
          break;
        case 4: //已领取
          this.$Msg.warn(this.$t(`active.claimed`))
          break;
        default:
          return
      }
    },
    async sign(type) {
      if (type === 1) {
        try {
          const params = {
            "type": type,
          }
          let data = await sign(params)
          await this.getSignIn()
          this.$Msg.success(this.$t(`active.signSuccess`))

          console.log("sign", data)
        } catch (e) {
          console.log(e)
        }
      }
      if (type === 2) {
        if(this.rechargeSign === 0){
          this.$router.push('/recharge')
          this.setActiveShow(false)
        }else{
          try {
            const params = {
              "type": type,
            }
            let data = await sign(params)
            await this.getSignIn()
            this.$Msg.success(this.$t(`active.signSuccess`))

            console.log("sign", data)
          } catch (e) {
            console.log(e)
          }
        }

      }

    },
    async signReward(id) {
      try {
        const params = {id}
        let data = await signReward(params)
        this.$Msg.success(this.$t(`active.claimSuccess`) + data .amount )
        await this.getSignIn()
        console.log("signReward", data)
      } catch (e) {
        console.log(e)
      }
    },
  },
  created() {
    this.getSignIn()
  }
}
</script>

<style scoped lang="scss">
@media screen and (min-width:$windowSize-md){
  .signList {
    .signItem {
      margin-bottom: 2rem;

    .signTitle {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .titleLeft {
        display: flex;
        font-size: 0.75rem;

        .titleName {
          font-weight: bold;
          position: relative;
          margin-right: 1rem;
          color: #FFC600;

          &::after {
            content: '';
            display: block;
            width: 1px;
            height: 0.67rem;
            @include p-ab-centerY;
            right: -0.5rem;
            background-color: #7D7D7D;
          }
        }

        .signedDay {
          :deep(span) {
            color: #FBC600;
            margin: 0 0.33rem;
          }
        }
      }

      .titleRight {
        .signBtn {
          height: 1.49rem;
          background: #FFC600;
          border-radius: 0.75rem;
          color: #403200;
          font-weight: bold;
          padding: 0 0.8rem;
          @include center;

          img {
            width: 0.85rem;
            margin-right: 0.2rem;
          }
        }
      }
    }

    .signDay {
      display: flex;
      justify-content: space-between;

      .days {
        @include center;
        flex-direction: column;
        width: 14.2%;
        height: 2.3rem;
        background: rgba(141, 42, 255, 0.5);
        border: 2px solid rgba(141, 42, 255, 0.88);
        border-radius: 0.42rem 0.42rem 0 0;
        margin: 0.8rem 0.06rem;

        .num {
          font-size: 1.09rem;
          font-weight: bold;
        }

        .co {
          font-size: 0.58rem;
          margin-top: -0.2rem;
        }

        img {
          width: 1.33rem;
        }
      }

      .signedIn {
        background: rgba(30, 206, 119, 0.2);
        border: 2px solid rgba(30, 206, 119, 0.88);
      }

      .today {
        background: rgba(255, 198, 0, 0.2);
        border: 2px solid rgba(255, 198, 0, 0.88);
      }
    }

      .signTips {
        font-size: 0.58rem;
        color: #717382;
      }
    }
  }
}
@media screen and (max-width:$windowSize-md){
  .signList {
    .signItem {
      margin-bottom: 2rem;
      border-radius: 0.83rem;
      background: #12161F;
      padding: 1rem;

      .signTitle {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .titleLeft {
          display: flex;
          font-size: 1.08rem;

          .titleName {
            font-weight: bold;
            position: relative;
            margin-right: 1rem;
            color: #FFC600;

            &::after {
              content: '';
              display: block;
              width: 1px;
              height: 0.67rem;
              @include p-ab-centerY;
              right: -0.5rem;
              background-color: #7D7D7D;
            }
          }

          .signedDay {
            :deep(span) {
              color: #FBC600;
              margin: 0 0.33rem;
            }
          }
        }

        .titleRight {
          .signBtn {
            background: #FFC600;
            color: #403200;
            font-weight: bold;
            padding: 0 0.8rem;
            @include center;
            height: 2.5rem;
            border-radius: 0.25rem;
            min-width: 4.92rem;
            img {
              width: 0.85rem;
              margin-right: 0.2rem;
            }
          }

          .cmplBtn {
            color: #FFFFFF;
            background: rgba(255, 255, 255, 0.1);
          }
        }
      }

      .signDay {
        display: flex;
        justify-content: space-between;

        .days {
          @include center;
          flex-direction: column;
          justify-content: flex-start;
          width: 13%;
          height: 4.5rem;
          background: rgba(141, 42, 255, 0.5);
          border: 2px solid rgba(141, 42, 255, 0.88);
          border-radius: 0.42rem 0.42rem 0 0;
          margin: 0.8rem 0.06rem;

          .num {
            font-size: 1.6rem;
            font-weight: bold;
            flex-grow: 1;
            @include center;
          }

          .co {
            font-size: 1rem;
            margin-top: -0.2rem;
            border-bottom: 2px dashed rgba(255, 255, 255, 0.05);
            background-color: rgba(255, 255, 255, 0.05);
            display: inline-block;
            width: 100%;
            height: 1.8rem;
            @include center;
          }

          img {
            width: 1.56rem;
            margin-top: 0.66rem;
          }
        }

        .signedIn {
          background: rgba(30, 206, 119, 0.2);
          border: 2px solid rgba(30, 206, 119, 0.88);
        }

        .today {
          background: rgba(255, 198, 0, 0.2);
          border: 2px solid rgba(255, 198, 0, 0.88);
        }
      }

      .signTips {
        font-size: 1rem;
        color: #717382;
      }
    }
  }
}
</style>
