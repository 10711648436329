<!--
时间:2023/9/12/012
用法:手机版侧边导航栏
-->

<template>
  <transition>
    <div class="mask animate__animated" v-show="show" :class="{'show':show,'hidde':!show}">
      <transition name="menu">
        <div v-show="show" class="menu _mobile animate__animated animate__duration-0.5s">
          <img src="../assets/mobileIcon/close@2x.png"
               @click="close"
               alt="" class="close">
          <div class="user">
            <div v-if="isLogin" class="userInfo">
              <div class="left" @click="goPath('/profile')">
                <img class="avatar" :src="userInfo.photo" alt="">
                <div class="information">
                  <div class="name">{{ userInfo.nickName }}</div>
                  <div class="money">
                    <img src="../assets/icon/coinorange.png" alt="" class="coin">
                    <span>{{ userInfo.money }}</span>
                  </div>
                </div>
              </div>
              <div class="refill" @click="goPath('/recharge',1)">
                {{ $t(`nav.refill`) }}+
              </div>
            </div>
            <div v-else class="login" @click="login">
              <img src="../assets/icon/steam@2x.png" alt="">
              <span>
            {{ $t(`nav.login`) }}
          </span>
            </div>
          </div>
          <img class="task" src="../assets/mobileIcon/task.png" alt="" @click="goPath('/active')">
          <div class="listInfo" :class="{'onList':!isLogin}">
            <div class="listBox" v-for="(item) in menuList" :key="item.id">
              <p class="titleName">{{ $t(`nav.${item.name}`) }}</p>
              <div class="itemBox">
                <div v-for="listItem in item.list" :key="listItem.name" class="listItem" v-show="listItem.flag">
                  <div  @click="listItem.fn ? listItem.fn() : goPath(listItem.path)" >
                    <div class="iconBox">
                      <img :src="listItem.img" alt="">
                    </div>
                    <p class="listItemName">{{ $t(`nav.${listItem.name}`) }}</p>
                  </div>
                </div>

              </div>

            </div>
          </div>
          <div class="menuFooter">
            <img v-if="!isLogin" src="../assets/mobileIcon/logo2@2x.png" alt="" class="logo">
            <!--消息和开启声音-->
            <div class="newsAndAudio" v-else>
              <!--              <div class="newsBox">
                              &lt;!&ndash; 小红点&ndash;&gt;
                              <div class="spot"></div>
                              <img src="../assets/icon/news.png" alt="">
                            </div>-->
              <div class="setAudio"
                   :class="userInfo.soundEffects === '0' ? 'openAudio' : 'closeAudio'"
                   @click="setAudio">
            <span>{{
                userInfo.soundEffects === '0' ? $t(`nav.glblMusicEnabled`) : $t(`nav.soundEffectsDisabled`)
              }}</span>
                <img class="audioImg"
                     :src="userInfo.soundEffects === '0' ?
                 require('../assets/icon/audioOpen.png') :
                  require('../assets/icon/audioClose.png')" alt="">
              </div>
            </div>
            <SelectLanguage :list="languageList" :value="currentLanguage" @changeLanguage="changeLanguage"/>
          </div>
          <div class="logout" @click="logout" v-if="isLogin">
            <svg-icon icon-class="guanji" class-name="guanji"/>
            {{ $t(`nav.logout`) }}
          </div>
        </div>
      </transition>
    </div>
  </transition>
</template>

<script>
import SelectLanguage from "@/components/ToolsComponents/SelectLanguage.vue";
import {language} from "@/common/config";
import SvgIcon from "@/components/IconComponents/SvgIcon.vue";
import {logout} from "@/utils/math";
import {goPath} from "@/router/goPath";
import {mapMutations, mapState} from "vuex";

export default {
  name: "MenuList",
  inject: ['cdkShowFun','showDownload'],
  components: {SvgIcon, SelectLanguage},
  computed: {
    ...mapState(['userInfo','getByUrl', 'timeBoxData', 'minValue']),
    isLogin() {
      return !!localStorage.getItem('token')
    }
  },
  watch: {
    'timeBoxData': {
      handler(newVal) {
        if(newVal.length > 0){
          this.menuList[0].list[3].flag = true
        }else{
          this.menuList[0].list[3].flag = false
        }
      },
      immediate: true
    },
    'minValue': {
      handler(newVal) {
        if(newVal == 0){
          this.menuList[0].list[2].flag = false
        }else{
          this.menuList[0].list[2].flag = true
        }
      },
      immediate: true
    },
    'getByUrl': {
      handler(newVal) {
        if(newVal.appDownload == 1){
          this.menuList[2].list[1].flag = true
        }else{
          this.menuList[2].list[1].flag = false
        }
      },
      deep: true,
      immediate: true
    },
  },
  props: {
    show: {
      type: Boolean,
      default: false
    },
  },
  data() {
    return {
      languageList: language,
      currentLanguage: localStorage.getItem('language'),
      menuList: [
        {
          name: 'games',
          id: 1,
          list: [
            {
              img: require('../assets/mobileIcon/boxActive.png'),
              name: 'blindBox',
              path: '/homes',
              flag: true,
            },
           //  {
           //    img: require('../assets/mobileIcon/rollActive.png'),
           //    name: 'rollRoom',
           //    path: '/roll',
           //    flag: true,
           //  },
            {
              img: require('../assets/mobileIcon/DreamsActive.png'),
              name: 'dream',
              path: '/dreams',
              flag: true,
            },
            // {
            //   img: require('../assets/mobileIcon/BattlesActive.png'),
            //   name: 'battle',
            //   path: '/battle',
            //   flag: true,
            // },
             {
               img: require('../assets/mobileIcon/taihuan2.png'),
               name: 'taihuan',
               path: '/replacement',
               flag: false,
             },
             {
               img: require('../assets/mobileIcon/shijian2.png'),
               name: 'shijian',
               path: '/timeBox',
               flag: false,
             },
          ]
        },
        {
          name: 'preferential',
          id: 2,
          list: [
            {
              img: require('../assets/mobileIcon/free@2x.png'),
              name: 'free',
              path: '/active',
              flag: true,
            },
            {
              img: require('../assets/mobileIcon/Acoin2.png'),
              name: 'recharge',
              path: '/recharge',
              flag: true,
            },
            {
              img: require('../assets/mobileIcon/CDK@2x.png'),
              name: 'cdk',
              path: '/',
              flag: true,
              fn:() => {
                this.cdkShowFun()
                this.close()
              }
            },
            {
              img: require('../assets/mobileIcon/VIP@2x.png'),
              name: 'welfare',
              path: '/profile/welfare',
              flag: true,
            },
          ]
        },
        {
          name: 'other',
          id: 3,
          list: [
            /*{
              img: require('../assets/mobileIcon/QQ@2x.png'),
              name: 'QQ',
              path: '',
            },*/
            {
              img: require('../assets/mobileIcon/kefu@2x.png'),
              name: 'custServ',
              path: '/',
              flag: true,
              fn: () => {
                window.open(this.getByUrl.serviceUrl,'_blank')
              }
            },
            {
              img: require('../assets/mobileIcon/APP@2x.png'),
              name: 'App',
              path: '',
              flag: false,
              fn: () => {
                this.showDownload()
                this.close()
              }
            },
          ]
        }
      ]
    }
  },
  methods: {
    ...mapMutations(['setActiveShow']),
    logout,
    close() {
      this.$emit('close')
    },
    login() {
      this.$emit("login")
    },
    changeLanguage(val) {
      console.log(val)
      this.currentLanguage = val
      localStorage.setItem('language', val)
      location.reload()
    },
    goPath(path) {
      goPath(path)
      this.close()
    },
    setAudio() {
      this.$emit('setAudio')
    },

  },
}
</script>

<style scoped lang="scss">
.mask {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  background-color: rgba(0, 0, 0, 0.6)
}

.menu {
  width: 84.5%;
  height: 100%;
  position: fixed;
  padding: 3.5rem 1.55rem 1.55rem 1.55rem;
  box-sizing: border-box;
  background: #101117 url("@/assets/information/bj@2x.png") no-repeat 100% 100% / 14.27rem 6.21rem;
  z-index: 1000;

  .close {
    position: absolute;
    top: 1.13rem;
    right: 1.13rem;
    width: 1.42rem;
    transition: transform 0.3s linear;

    &:active {
      transform: rotate(180deg);
    }
  }

  .user {

    .userInfo {
      display: flex;
      align-items: center;
      justify-content: space-between;
      cursor: pointer;
      height: 3.17rem;

      .left {
        display: flex;
      }


      .avatar {
        width: 3.95rem;
        height: 3.95rem;
        border-radius: 50%;
        margin-right: 0.79rem;
        flex-shrink: 0;
      }

      .information {
        .name {
          font-size: 1.08rem;
        }

        .coin {
          width: 1.04rem;
          margin-right: 0.2rem;
        }

        .money {
          @include center;
          margin-top: 0.71rem;
          font-size: 1rem;
          font-weight: bold;
          color: $theme-color-f600;
        }
      }

      .refill {
        width: 8rem;
        height: 3.16rem;
        background: $theme-color-f600;
        color: #000000;
        border-radius: 0.25rem;
        font-size: 1.08rem;
        font-weight: bold;
        @include center;
      }
    }

    .login {
      height: 3.17rem;
      width: 100%;
      background: $theme-color-f600;
      border-radius: 0.25rem;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      cursor: pointer;

      img {
        width: 1.42rem;
        height: 1.42rem;
        margin-right: 0.36rem;
      }

      span {
        font-size: 1.08rem;
        font-weight: bold;
        color: #000000;
      }
    }
  }

  .task {
    width: 100%;
    height: 5.13rem;
    margin: 1.5rem 0;
  }

  .listInfo {
    overflow-y: auto;
    height: calc(100% - 18rem);

    .titleName {
      font-size: 1.08rem;
      color: #5B6171;
      margin-bottom: 1.2rem;
    }

    .itemBox {
      display: flex;
      justify-content: flex-start;
      align-items: stretch;
      flex-wrap: wrap;

      .listItem {
        width: 25%;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        flex-direction: column;
        margin-bottom: 1.75rem;

        .iconBox {
          width: 4.17rem;
          height: 4.17rem;
          background: #171923;
          border-radius: 0.33rem;
          margin-bottom: 0.8rem;
          @include center;

          img {
            width: 1.92rem;
          }
        }

        .listItemName {
          font-size: 1rem;
          text-align: center;
        }
      }
    }

  }

  .onList {
    height: calc(100% - 15rem);
  }

  .menuFooter {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.5rem 0;

    .logo {
      width: 8.63rem;
    }

    .newsAndAudio {
      display: flex;
      height: 2.81rem;
      flex-grow: 1;

      .newsBox {
        width: 4.33rem;
        height: 100%;
        background: #1E2029;
        border-radius: 0.42rem;
        margin-right: 0.54rem;
        @include center;

        img {
          width: 1.17rem;
        }
      }

      .setAudio {
        height: 100%;
        width: 10.12rem;
        background: rgba(129, 27, 255, 0.3);
        border: 2px solid #811BFF;
        border-radius: 0.33rem;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 0.67rem;
        cursor: pointer;
        box-sizing: border-box;
        margin-right: 0.54rem;
        flex-grow: 1;

        .audioImg {
          width: 1.44rem;
        }
      }

      .closeAudio {
        background: rgba(255, 255, 255, 0.1);
        opacity: 0.5;
        border: 0;
      }

    }


  }

  .logout {
    height: 3.3rem;
    @include center;
    color: #A9ABBC;
    font-size: 1rem;
    padding: 0 1rem;
    background: #171923;
    border-radius: 0.42rem;
    width: 100%;
    box-sizing: border-box;

    .guanji {
      margin-right: 0.5rem;
      font-size: 1.25rem;
    }
  }
}

.menu-enter-active {
  animation: slideInLeft 0.5s;

}

.menu-leave-active {
  animation: slideOutLeft 0.5s;
}

.show {
  background-color: rgba(0, 0, 0, 0.6);
  animation: fadeIn 0.5s;
}

.hidde {
  animation: fadeOut 0.5s;
}
</style>
