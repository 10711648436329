<!--
时间:2023/9/19/019
用法:货币图标及价格
-->

<template>
  <div class='MoneyCoin' :class="className">
    <SvgIcon icon-class="F" :style="{'color':svgColor}"/>
    <span class="moneyVal" :style="{color}">{{ symbol }}{{ Number(money).toFixed(2) }}</span>
  </div>
</template>

<script>
import SvgIcon from "@/components/IconComponents/SvgIcon.vue";

export default {
  name: "MoneyCoin",
  components: {SvgIcon},
  props: {
    className: {
      type: String,
      default: ''
    },
    svgColor: {
      type: String,
      default: '#FFC600'
    },
    color: {
      type: String,
      default: '#FFC600'
    },
    symbol: {
      type: String,
      default: ''
    },
    money: {
      default: 0
    }
  },
  data() {
    return {}
  },
  methods: {}
}
</script>

<style scoped lang="scss">
.MoneyCoin {
  font-size: 1em;
  @include center;
  display: inline-flex;
  .svg-icon{
    margin-right: 0.2em;
    font-size: 1.2em;
  }
  @media screen and (max-width: $windowSize-md) {
    .svg-icon {
      min-width: 1rem;
      min-height: 1rem;
      font-size: 1.2em;
      margin-right:0.3em ;
    }
  }
}

</style>
