export default {
    nav: {
        // 导航栏
        welfare: "welfare",
        mySkins: 'Items',
        myWelfare: 'benefits.',
        myReward: 'Commission',
        information: "个人中心",
        historicalBilling: 'Transactions',
        login: "log in",
        logout: "Log out",
        blindBox: 'Cases',
        rollRoom: "rolls",
        dream: "percent case",
        battle: "case Battle",
        bomb: "bomb disposal",
        refill: "Refill",
        // 登陆弹窗
        loginTitle: 'We need to make sure you can use csgo8, please confirm:',
        my18: 'I\'m 18 years old.',
        agree: "I have read and agree to",
        usrAgreement: 'User Agreement',
        privacyAgreement: 'Privacy Agreement',
        antiMoneyLaundering: 'Anti-Money Laundering Agreement',
        // 电脑版footer内容
        openCases: 'open case',
        users: 'users',
        online: 'online',
        footerText1: "Blind boxes are played in a variety of ways, and  extraction is safe and convenient!",
        // 手机版侧边栏
        games: "game",
        preferential: "favorable",
        free: "free",
        recharge: "Refill",
        cdk: "CDK",
        other: "other",
        QQ: "QQ",
        custServ: "customer service",
        App: "App",
        glblMusicEnabled: "Enabled sound effects",
        soundEffectsDisabled: "Disabled sound effects",
        questions: "FAQ ",
        aboutUs: "About",
        pleaseEntrANickname: "Please enter a nickname",
        pleaseEntrYourEmailAddr: "Please enter your cell phone number",
        pleaseEntrTheVerificationCd: "Please enter the verification code",
        obtainVerificationCd: "Get code",
        pleaseOutputTheInptPsw: "Please enter your password",
        pleaseConfThePsw: "Please enter the password again",
        pleaseEntrTheInvtCd: "Please enter invitation code (optional)",
        noSteam: "Don't have STEAM account?",
        emailLogin:"Mobile Login",
        passwordLogin:"password login",
        verificationCdLogin:"captcha Login",
        register: "register",
        steamLogin: "steam login",
        forgetPsw: "Forgot your password?",
        conf: "confirmed",
        retrievePsw: "Retrieve password",
        ruleDream:"Percent case rule",
        exchange: "Exchange",
        inptCdk: "Please enter CDK passphrase",
        redemptionSuccessful: "Redemption successful, A-coin"
    },
    blindBox: {
        //开箱页面
        open: "open",
        buy: "purchase",
        bestDrp: "Recent Drops",
        boxContains: "Case contains",
        buyTips1: "OOPS, YOUR BALANCE IS TOO LOW!"
    },
    roll: {
        ofcl: "OFFICIAL",
        anchor: "anchor ",
        iParticipatedIn: "my rolls",
        haveInHand: "Active rolls",
        itsOvr: "Completed rolls",
        participated: "engaged",
        viewingRooms: "View Roll",
        joinNow: "join",
        deadline: "end time",
        totVal: "total value",
        items: "Items",
        players: "Players",
        //roll详情
        countdownToLottery: "Countdown to results",
        prtpConditions: "Conditions of participation",
        includingPrizes: "Prizes included",
        participatingUsers: "PARTICIPANTS",
        awrdLst: "WINNERS",
        joinRm: "join"
    },
    dream: {
        chooseAccessories: "Select item",
        probability: "probability ",
        upg: "Dreams",
        upgTips: "*Dreams probability ranges from {num1}% to {num2}%",
        //追梦选择饰品弹窗
        inputPlaceholder1: "Search Case...",

    },
    battle: {
        //对战列表
        allBattles: "All battles",
        rankingLst: "ranking list",
        historicalRecords: "historical record",
        myRecords: "My records",
        //创建对战
        addBox: "add case",
        robot: "robot",
        combatCost: "Battle’s cost",
        createBattle: "Create Battle",
        chooseAnItem: "Select item",
        incorrectNumberOfPeople: "Incorrect number of people",
        inputPlaceholder1: "Search Case...",
        thisMorning: "this morning",
        thisAfternoon: "this afternoon",
        selected: "Selected:{num}",
        coVal: "Total cost",
        //对战详情
        ready: "ready",
        drawnJewelry: "Winning the trinket",
        obtainJewelry: "Acquired trinket",
        //排行榜
        today: "today",
        yesterday: "yesterday",
        //对战结束弹窗
        battleIsOvr: "The end of the battle.",
        winner: "winner",
        oneMoreRound: "Create Same Duel",
        confJoin: "Is it confirmed to join?"
    },
    information: {
        //个人中心首页
        invtCd: "invitation code",
        bal: 'balance ',
        rchg: "Refill ",
        orderNumber: 'order number',
        transactionLnk: "STEAM Trading Links",
        steamLinkTips1: "1、Trading link once bound can not be replaced, please be careful operation, the same link update token is not limited.",
        steamLinkTips2: "2、Please do not bind other people's steam trading links",
        steamInputPlaceholder1: "Enter your STEAM transaction link",
        obtain: "gain",
        saveAddr: "Save Address",
        save: "Save",
        mySkins: 'Items',
        myWelfare: 'Benefits',
        rchgDetails: "Payment history",
        incomeDetails: "income and expenditure",
        myReward: 'My Commissions',
        // 我的物品页面
        retrieving: "Being Withdraw ",
        retrieveRecords: "Withdraw history",
        numOrnaments: "Total {num} of ornaments",
        obtainingTm: "Acquisition time",
        val: "value",
        selectedNum: "Selected {num} items",
        retrieve: "Withdraw",
        chooseABox: "Go to the selection box to get some",
        noItems: "Your inventory is empty",
        noRecIsCur: "No current record",
        //佣金页面
        generalizationCd: "Promo code",
        cpy: "copy",
        promLnk: "Promotional Links",
        partnerRules: "terms & conditions and site rules",
        totNumOfInvitations: "Total number of invitees",
        effectiveUsers: "active user",
        accumRewards: "Cumulative rewards",
        rewardRatio: "Incentive ratio",
        lvlDsc: "Grade Description",
        invtRec: "Record of invitations",
        date: "date",
        invitee: "invitee",
        usrRchg: "User Recharge",
        myRewards: "My Rewards",
        rewardLvl: "Reward level",
        invitedUsrRchg: "Invited Users Recharge",
        promAndAcq: "Promoted users will receive",
        incmStmt: "Introduction to earnings"
    },
    confirm: {
        success: "success",
        warning: "warn",
        error: "error",
        noDataAvailable: "No data",
        //老虎机抽奖结果弹窗
        decompose: "sell",
        decomposeAll: "sell all",
        cont: "Keep unboxing.",
        //confirm提示弹窗
        confDecomposition: "Confirmed to sell {num} pieces of equipment?",
        common: "total",
        cancel: "cancel",
        confirm: "confirm",
        confDec: "Confirm decomposition?",
        confOpn: "Is it confirmed open?" ,
        conf: "Confirmation or not?" ,
        //msg提示弹窗
        decomposeSuccess: "Item has been successfully sold!",
        theMinimumProbabilityIs: "The probability is minimized as {num}",
        notLoggedIn: "not logged",
        noJewelrySelected: "unselected item",
        agreeToATreaty: "Please read and agree to the following treaty",
        copySuccess: "Copy Success",
        copyError: "copy failed",
        pleaseFollow: "Retrieving. Please wait.",
        notYetOpn: "not yet open",
        glblMusic: "Please turn on all sounds first",
        amtCannotBeZero: "The amount cannot be 0",
        pswModSuccessful: "Password changed successfully",
        loginSuccessful: "Login Success",
        loginWasSuccessful: "Registration successful, please log in!",
        emailFmtErr: "Wrong format of cell phone number",
        vSntSuccess: "Verification code sent successfully",
        pswInconsistency: "Password inconsistency",
        entrTheVerificationCd: "Please enter the verification code",
        theMinPswLenIs6: "Minimum password length is 6",
        pleaseEntrANickname: "Please enter a nickname",
    },
    //常用的
    common: {
        //单位
        day: 'day',
        hh: "hour",
        mm: 'minute',
        ss: "second",
        piece: "piece",
        // title常用
        back: "back",
        gameRules: "game rules",
        //表格常用
        players: "player",
        price: "price",
        reset: "reset",
        cases: "cases",
        value: "value",
        actions: "actions",
        watch: "watch",
        join: "join",
        ranking: "Ranking",
        bestDrp: "Best Drops",
        date: "date",
        st: "state",
        purp: "uses",
        amtOfMoney: "amounts",
        allElection: "Select All",
        typ: "type",
        numericalVal: "numericValue",
    },
    incomeType: {
        //个人中心收支type
        whole: "all",
        incm: "incomes",
        expenditure: "expenditures",
        purCurr: "money purchase",
        recovery: "recall",
        unpacking: "open",
        commission: "commission",
        redEnvelopes: "rewards",
        war: "battle",
        shoppingMallPur: "Purchase at the mall",
        chasingDreams: "dreams",
        coinPurGft: "Buy Coins Giveaway",
        cshWdrl: "withdraw funds",
        rlRm: "rolls",
        regGifts: "Registration Gift",
        addRewards: "additional bonus",
        redEnvelopeReward: "Red Packet Bonus",
        luckyTurntable: "Lucky turntable",
        bkOffcDistribution: "Back-office issuance",
        skinSynthesis: "skin synthesis",
        sgnInReward: "Sign in reward",
        newDreamChasing: "New Dream Chasing",
        unpackingKing: "Unpacking King",
        skydiverHd: "skydiving",
        inviteRebate: "Invite Rebate",
        taskRewards: "Mission Rewards",
    },
    provably: {
        provably: "Fairness Verification",
        provablyDsc: "Fairness validation description",
        text1: "1. Players generate a roll point each time they play, and the corresponding roll point gets the corresponding skin.",
        text2: "2, open box chase dream arena three play each set of user seed and public hash, module common for example: open box block a box and b box in the rl point there is no difference, only to get the skin there is a difference.",
        text3: "3. The encrypted values involved in the operation are as follows:",
        text4: "4, we provide the open source code and test platform of this roll point algorithm, friends who know programmers can operate stand-alone to see if the results are consistent in the case of unrelated web servers.",
        clientSeed: "userSeed",
        publicHash: "publicHash",
        round: "rounds",
        secretHash: "secretHash",
        secretSalt: "secret salt",
        createTime: "createTime",
        enterClientSeed: "Please enter a user seed",
        enterPublicHash: "Please enter the public hash",
        enterSecretHash: "Please enter the secret hash",
        enterSecretSalt: "Please enter the secret salt",
        enterRound: "Please enter the number of rounds",
        p1: "That is to say, there are two values that the user can change by himself, and these two values are involved in operations that can change the result/roll point. For example: personal seed default 1. manually change to 2 after changing the roll point result for 10086 other parameters remain unchanged, and then go to use the tool to verify the results of 1, you will find that the seed for the case of 1 the result is not 10086 ",
        p2: "In the example, after changing the seed or public hash, you can see the secret hash and SECRET SALT of the previous game, and then you can go to verify whether the previous result is correct. It is also possible to take the previous values and measure whether the probability distribution is reasonable under larger rounds (e.g. 10w times).",
        opnSrcAddr: "Open source address",
        download: "Download Offline Validator",
        curSeed: "Current Seeds",
        preSeed: "previousServerSeed",
        chg: "change",
        reset: "Reset",
        oneClkCpy: "OneClickCopy",
        validator: "Fairness Validator",
        attachment1: "(changeable at will)",
        attachment2: "(Resettable)",
        attachment3: "(Increases with number of games played)",
        resetDisp:"(show after resetting public hash)",
        legitimateSeed: "Please enter a legal seed",
        entrANewSeed: "Input new seed",
        seedReg: "Three characters (or more) of English letters, numbers or Chinese characters",
        oneClkPaste: "OneClickPaste",
        rlPnt: "roll point",
        waitVerification: "Waiting for verification",
    },
    privacyClause: {
        usrAgreement: "<p>Conditions relating to use of the Service</p>" +
            "<p>No individual under the age of eighteen (18) may use the Service, regardless of any consent from your parent or guardian to use the Service.</p>" +
            "<p>You need a supported Web browser to access the Service. You acknowledge and agree that csgo8 may cease to support a given Web browser and that your continuous use of the Service will require you to download a supported Web browser. You also acknowledge and agree that the performance of the Service is incumbent on the performance of your computer equipment and your Internet connection.</p>" +
            "<p>You agree to sign on and register for the Services through your Steam account provided by the Valve Corporation. You are solely responsible for managing your account and password and for keeping your password confidential. You are also solely responsible for restricting access to your account.</p>" +
            "<p>You agree that you are responsible for all activities that occur on your account or through the use of your password by yourself or by other persons. If you believe that a third party has access to your password, use the password regeneration feature of the Service as soon as possible to obtain a new password. In all circumstances, you agree not to permit any third party to use or access the Service.</p>" +
            "<p>All terms and definitions found in the text of the conditions are interpreted by the Parties in accordance with the laws of the Singapore and the rules of interpretation of the relevant terms used in the Internet.</p>" +
            "<p>As a condition to your use of the Service, you agree not to:</p>" +
            "<p>impersonate or misrepresent your affiliation with any person or entity;</p>" +
            "<p>access, tamper with, or use any non-public areas of the Service or csgo8.com's computer systems;</p>" +
            "<p>attempt to decipher, decompile, disassemble, reverse engineer or otherwise investigate any of the software or components used to provide the Service;</p>" +
            "<p>harm or threaten to harm other users in any way or interfere with, or attempt to interfere with, the access of any user, host or network, including without limitation, by sending a virus, overloading, flooding, spamming, or mail-bombing the Service;</p>" +
            "<p>provide payment information belonging to a third party;</p>" +
            "<p>use the Service in an abusive way contrary to its intended use, to csgo8 policies and instructions and to any applicable law;</p>" +
            "<p>systematically retrieve data or other content from the Service to create or compile, directly or indirectly, in single or multiple downloads, a collection, compilation, database, directory or the like, whether by manual methods, through the use of bots, crawlers, or spiders, or otherwise;</p>" +
            "<p>make use of the Service in a manner contrary to the terms and conditions under which third parties provide facilities and technology necessary for the operation of the Service, such as G2A or Valve;</p>" +
            "<p>infringe third party intellectual property rights when using or accessing the Service, including but not limited to in making available virtual items by using the Service;</p>" +
            "<p>make use of, promote, link to or provide access to materials deemed by csgo8 at its sole discretion to be offensive or cause harm to csgo8 reputation, including, but not limited to, illegal content and pornographic content and content deemed offensive or injurious to csgo8 and/or the Service (such as Warez sites, IRC bots and bittorent sites).</p>" +
            "<p>Termination</p>" +
            "<p>We may terminate or suspend access to our Service immediately, without prior notice or liability, for any reason whatsoever, including without limitation if you breach the Terms.</p>" +
            "<p>All provisions of the Terms which by their nature should survive termination shall survive termination, including, without limitation, ownership provisions, warranty disclaimers, indemnity and limitations of liability.</p>" +
            "<p>We may terminate or suspend your account immediately, without prior notice or liability, for any reason whatsoever, including without limitation if you breach the Terms.</p>" +
            "<p>Upon termination, your right to use the Service will immediately cease. If you wish to terminate your account, you may simply discontinue using the Service.</p>" +
            "<p>Opening Case Rules</p>" +
            "<p>By opening case you agree to get the one of the game items presented on the page with the description with the case's containing. The item for receiving is determined automatically.</p>" +
            "<p>1. Accepting a game item</p>" +
            "<p>1.1. By accepting a game item you agree that the price of the case, including the game Item contained within, meets your expectations and confirm that you are not entitled to claim any additional compensation from website/service or company.</p>" +
            "<p>1.2. Once the game Item is received through the use of the Case and is shown in the Personal Account, the User has the possibility to take game Items to the Steam account</p>" +
            "<p>1.3. Acceptance of the game Item in the Steam account is subject to the User's fulfilment of the Steam and Personal Account settings specified on the Site and in the Personal Account.</p>" +
            "<p>Prohibition of gambling is forbidden to use csgo8.com in any way that violates any law or regulation, including those prohibiting illegal gambling. The purchased game Items can only be used for personal purposes and shall not be used for any type of commercial profit. It must be used for their intended purpose, that is, for playing CS:GO. You must use the Skins in accordance with the Steam Service Subscriber Agreement. You will not resell the Skins received on csgo8.com on third-party marketplaces or otherwise trade such Skins for any thing of value.</p>" +
            "<p>2. Refuse a game item</p>" +
            "<p>2.1. User can refuse the game item at the time of opening case and return it for bonus points, giving a discount when paying for cases in the future, in the amount specified in the description of the Item in the Personal Account.</p>" +
            "<p>Links To Other Web Sites</p>" +
            "<p>Our Service may contain links to third-party web sites or services that are not owned or controlled by csgo8.</p>" +
            "<p>csgo8 has no control over, and assumes no responsibility for, the content, privacy policies, or practices of any third party web sites or services. You further acknowledge and agree that csgo8 shall not be responsible or liable, directly or indirectly, for any damage or loss caused or alleged to be caused by or in connection with use of or reliance on any such content, goods or services available on or through any such web sites or services.</p>" +
            "<p>We strongly advise you to read the terms and conditions and privacy policies of any third-party web sites or services that you visit.</p>" +
            "<p>No warranties</p>" +
            "<p>This website is provided “as is” without any representations or warranties, express or implied. www.csgo8.com makes no representations or warranties in relation to this website or the information and materials provided on this website. Without prejudice to the generality of the foregoing paragraph, www.csgo8.com does not warrant that: this website will be constantly available, or available at all; or the information on this website is complete, true, accurate or non-misleading. Nothing on this website constitutes, or is meant to constitute, advice of any kind. If you require advice in relation to any legal, financial or medical matter you should consult an appropriate professional.</p>" +
            "<p>Account security</p>" +
            "<p>This website using Steam OpenID, which means that you are login in with your Steam account.</p>" +
            "<p>You shall not share your Steam Account or the Login Information, nor let anyone else access your Steam Account or do anything else that might jeopardize the security of your Steam Account.</p>" +
            "<p>In the event you become aware of or reasonably suspect any breach of security, including without limitation any loss, theft, or unauthorized disclosure of the Steam Login Information, you must immediately contact Steam Support (https://help.steampowered.com/).</p>" +
            "<p>You are solely responsible for maintaining the confidentiality of the Steam Account Information, and you will be responsible for all uses of the Steam Login Information, including purchases, whether or not authorized by you.</p>" +
            "<p>You are responsible for anything that happens through your Steam Account.</p>" +
            "<p>Affiliation</p>" +
            "<p>We are in NO WAY affiliated with or endorsed by the Valve corporation, Counter Strike: Global Offensive, Steam or any other trademarks of the Valve corporation.</p>" +
            "<p>Returns and Refunds Policy</p>" +
            "<p>csgo8.com Wallet By topping up the amount of your csgo8.com account wallet, you accept that the credits received (labeled by the IS-sign) are not worth real money and not subject to any refunds.</p>" +
            "<p>We do not issue refunds for digital products once the order is confirmed and the product is sent. We recommend contacting us for assistance if you experience any issues receiving or downloading our products.</p>" +
            "<p>Force Majeure</p>" +
            "<p>We shall not be liable for any delay or failure to perform resulting from causes outside the reasonable control of csgo8.</p>" +
            "<p>This is include without limitation any failure to perform hereunder due to unforeseen circumstances or cause beyond our control such as acts of God, war, terrorism, riots, embargoes, acts of civil or military authorities, fire, floods, accidents, strikes, or shortages of transportation facilities, fuel, energy, labor or materials.</p>" +
            "<p>Amendment of the Agreement</p>" +
            "<p>We has the right to unilaterally change the terms of the Agreement, and these changes come into force at the time of publication of the new version of the Agreement on the Internet at https://csgo8.com/terms</p>" +
            "<p>Continued use of the functions of the Site will mean that you agree with the terms of the new version of the Agreement. If the you does not agree with the terms of the new version of the Agreement, you must cease to use the Site.</p>" +
            "<p>Copyright and other incorporeal rights</p>" +
            "<p>Website is Powered by Steam, a registered trademark of Valve Corporation.</p>" +
            "<p>Valve, the Valve logo, the Steam logo, Counter-Strike and the Counter-Strike logo are trademarks and/or registered trademarks of Valve Corporation.</p>" +
            "<p>All other trademarks are property of their respective owners. No incorporeal rights or other material on the website is transferred to the user.</p>" +
            "<p></p>" +
            "<p>Apple is not a sponsor of any activities on this website/APP, nor does it participate in any form in the activities.</p>" +
            "<p>Shipping/delivery policy</p>" +
            "<p>1、The skins you get in the csgo8 website will be shown in your item inventory of skins csgo8 inventory status is divided into 4 types, respectively (inventory, picking up , Return to storage, picked up)</p>" +
            "<p>Item inventory : shows the skins you have obtained in the opening case and not decomposed, you can either decompose them or request to pick them up;</p>" +
            "<p>Picking up : On behalf of you have submitted, the site administrator has not yet processed the shipment or due to shortage of goods and other reasons have not yet coordinated to the source, this state requires you to wait patiently.</p>" +
            "<p>Return to storage: may be caused by the following reasons: your steam status abnormal resulting in transaction restrictions; or link failure, or because the market is out of stock, so can not handle your pickup request; or because your backpack items not picked up for 7 days, and the price fluctuations are too large, resulting in the inability to complete your pickup needs (so please pick up the jewelry in time to avoid causing trouble for your pickup).</p>" +
            "<p>Picked up: on behalf of your submission has passed the audit, and the site has been shipped, this state requires you to go to the steam platform in time to pick up your equipment.</p>" +
            "<p>2、Preparation needed before picking up the equipment</p>" +
            "<p>Before picking up the equipment, please go to your profile page to set your steam trading offer link. But because steam due to policy reasons, Chinese players may not be able to open, but also please use UU Website Accelerator test.</p>" +
            "<p>3、Shipping time</p>" +
            "<p>Normal conditions, you will get the pickup notice within 1 minute, due to skins price fluctuations, inventory and other special circumstances, the maximum pickup time is 18 hours, please wait patiently. Any other questions, please feel free to contact the official customer service (10:00-22:00)</p>" +
            "<p>Information</p>" +
            "<p>csgo8.com as an administrator of the Website adopts these Terms of Use that specifies User’s rights and obligations and constitute a legally binding agreement for both parties. These Terms of Use affect User’s rights and impose certain obligations while using the Website, so the User must read them carefully.</p>" +
            "<p>Registration info: csgo8.com</p>" +
            "<p>Email：-------------------</p>" +
            "<p>Address: -----------------------------</p>",
        privacyAgreement:
            "<p>Privacy Policy Terms</p>" +
            "<p>csgo8 may change this Privacy Policy from time to time and the change will take effect once the revised Privacy Policy is available on this Application. The “last updated” date is at the bottom of this Policy. Therefore, please refer to this Privacy Policy each time you submit your personal information.</p>" +
            "<p>Data Controller and Owner</p>" +
            "<p>csgo8.com</p>" +
            "<p>Owner contact information: customer service</p>" +
            "<p>Types of Data collected</p>" +
            "<p>Among the types of Personal Data that this Application collects, by itself or through third parties, there are: Cookies and Usage Data.</p>" +
            "<p>Other Personal Data collected may be described in other sections of this privacy policy or by dedicated explanation text contextually with the Data collection.</p>" +
            "<p>The Personal Data may be freely provided by the User, or collected automatically when using this Application.</p>" +
            "<p>Any use of Cookies - or of other tracking tools - by this Application or by the owners of third party services used by this Application, unless stated otherwise, serves to identify Users and remember their preferences, for the sole purpose of providing the service required by the User.</p>" +
            "<p>Failure to provide certain Personal Data may make it impossible for this Application to provide its services.</p>" +
            "<p>Users are responsible for any Personal Data of third parties obtained, published or shared through this Application and confirm that they have the third party's consent to provide the Data to the Owner.</p>" +
            "<p>Mode and place of processing the Data</p>" +
            "<p>Methods of processing</p>" +
            "<p>The Data Controller processes the Data of Users in a proper manner and shall take appropriate security measures to prevent unauthorized access, disclosure, modification, or unauthorized destruction of the Data.</p>" +
            "<p>The Data processing is carried out using computers and/or IT enabled tools, following organizational procedures and modes strictly related to the purposes indicated. In addition to the Data Controller, in some cases, the Data may be accessible to certain types of persons in charge, involved with the operation of the site (administration, sales, marketing, legal, system administration) or external parties (such as third party technical service providers, mail carriers, hosting providers, IT companies, communications agencies) appointed, if necessary, as Data Processors by the Owner. The updated list of these parties may be requested from the Data Controller at any time.</p>" +
            "<p>Place</p>" +
            "<p>The Data is processed at the Data Controller's operating offices and in any other places where the parties involved with the processing are located. For further information, please contact the Data Controller.</p>" +
            "<p>Retention time</p>" +
            "<p>The Data is kept for the time necessary to provide the service requested by the User, or stated by the purposes outlined in this document, and the User can always request that the Data Controller suspend or remove the data. In no event, the Data will be kept longer than 5 years after the latest activity of the User on the site.</p>" +
            "<p>How Do We Protect Your Information?</p>" +
            "<p>We are committed to protecting your privacy and we implement various security measures in relation to our processing and transfer of personal data. However, the nature of the Internet is such that the data may in some circumstances flow over networks without full security measures and could be accessible to unauthorized persons.</p>" +
            "<p>How Do We Use Your Information?</p>" +
            "<p>We will use your personal information for our legitimate interests to respond to your enquiries, to send information to you, to fulfil any contractual obligation to you, for research purposes, to send marketing information to you regarding our goods, services and related opportunities, and other reasonable uses by virtue of your affirmative consent.</p>" +
            "<p>If you purchase goods or services from us we may send you further information that is related to your purchase, by post, push notification or e-mail.</p>" +
            "<p>We would like to send you marketing information, by push notifications and e-mail.</p>" +
            "<p>If you do not wish to receive any of the above information or be contacted by us in accordance with the above, you can ask to our customer service.</p>" +
            "<p>If you submit your views to us via our contact form we may circulate them internally for training and management purposes.</p>" +
            "<p>We review the Personal Information we collect periodically to determine its accuracy and whether it can be deleted.</p>" +
            "<p>We may aggregate the information you send to us with other data (so that you cannot be identified from that data) and use that aggregated data for administrative purposes.</p>" +
            "<p>Push notifications</p>" +
            "<p>This Application may send push notifications to the User.</p>" +
            "<p>Analytics</p>" +
            "<p>The services contained in this section enable the Owner to monitor and analyze web traffic and can be used to keep track of User behavior.</p>" +
            "<p>Google Analytics (Google Inc.)</p>" +
            "<p>Google Analytics is a web analysis service provided by Google Inc. (“Google”). Google utilizes the Data collected to track and examine the use of this Application, to prepare reports on its activities and share them with other Google services.</p>" +
            "<p>Google may use the Data collected to contextualize and personalize the ads of its own advertising network.</p>" +
            "<p>Personal Data collected: Cookies and Usage Data.</p>" +
            "<p>Google AdWords conversion tracking (Google Inc.)</p>" +
            "<p>Google AdWords conversion tracking is an analytics service provided by Google Inc. that connects data from the Google AdWords advertising network with actions performed on this Application.</p>" +
            "<p>Personal Data collected: Cookies and Usage Data.</p>" +
            "<p>Twitter Ads conversion tracking (Twitter, Inc.)</p>" +
            "<p>Twitter Ads conversion tracking is an analytics service provided by Twitter, Inc. that connects data from the Twitter advertising network with actions performed on this Application.</p>" +
            "<p>Personal Data collected: Cookies and Usage Data.</p>" +
            "<p>The rights of Users</p>" +
            "<p>Users may exercise certain rights regarding their Data processed by the Owner.</p>" +
            "<p>In particular, Users have the right to do the following:</p>" +
            "<p>Withdraw their consent at any time. Users have the right to withdraw consent where they have previously given their consent to the processing of their Personal Data. Object to processing of their Data. Users have the right to object to the processing of their Data if the processing is carried out on a legal basis other than consent. Further details are provided in the dedicated section below. Access their Data. Users have the right to learn if Data is being processed by the Owner, obtain disclosure regarding certain aspects of the processing and obtain a copy of the Data undergoing processing. Verify and seek rectification. Users have the right to verify the accuracy of their Data and ask for it to be updated or corrected. Restrict the processing of their Data. Users have the right, under certain circumstances, to restrict the processing of their Data. In this case, the Owner will not process their Data for any purpose other than storing it. Have their Personal Data deleted or otherwise removed. Users have the right, under certain circumstances, to obtain the erasure of their Data from the Owner. Receive their Data and have it transferred to another controller. Users have the right to receive their Data in a structured, commonly used and machine readable format and, if technically feasible, to have it transmitted to another controller without any hindrance. This provision is applicable provided that the Data is processed by automated means and that the processing is based on the User's consent, on a contract which the User is part of or on pre-contractual obligations thereof. Lodge a complaint. Users have the right to bring a claim before their competent data protection authority.</p>" +
            "<p>Definitions and legal references</p>" +
            "<p>Personal Data (or Data)</p>" +
            "<p>Any information that directly, indirectly, or in connection with other information allows for the identification or identifiability of a natural person. Such information may include name, address, passport or ID data, personal identification number, and any other data which collectively may allow to identify a person.</p>" +
            "<p>Usage Data</p>" +
            "<p>Information collected automatically through this Site, which can include: the IP addresses or domain names of the computers utilized by the Users who use this Site, the URI addresses (Uniform Resource Identifier), the time of the request, the method utilized to submit the request to the server, the size of the file received in response, the numerical code indicating the status of the server's answer (successful outcome, error, etc.), the country of origin, the features of the browser and the operating system utilized by the User, the various time details per visit (e.g., the time spent on each page within the Site) and the details about the path followed within the Site with special reference to the sequence of pages visited, and other parameters about the device operating system and/or the User's IT environment.</p>" +
            "<p>User</p>" +
            "<p>The individual using this Site who, unless otherwise specified, coincides with the Data Subject.</p>" +
            "<p>Data Subject</p>" +
            "<p>The natural person to whom the Personal Data refers.</p>" +
            "<p>Data Processor (or Data Supervisor)</p>" +
            "<p>The natural or legal person, public authority, agency or other body which processes Personal Data on behalf of the Controller, as described in this privacy policy.</p>" +
            "<p>Data Controller (or Owner)</p>" +
            "<p>The natural or legal person, public authority, agency or other body which, alone or jointly with others, determines the purposes and means of the processing of Personal Data, including the security measures concerning the operation and use of this Site. The Data Controller, unless otherwise specified, is the Owner of this Site.</p>" +
            "<p>This Application</p>" +
            "<p>The means by which the Personal Data of the User is collected and processed.</p>" +
            "<p>Service</p>" +
            "<p>The service provided by this Site as described in the relative terms (if available) and on this Site.</p>" +
            "<p>European Union (or EU)</p>" +
            "<p>Unless otherwise specified, all references made within this document to the European Union include all current member states to the European Union and the European Economic Area.</p>" +
            "<p>Cookies</p>" +
            "<p>Small piece of data stored in the User's device.</p>" +
            "<p>Contact information: customer service</p>" +
            "<p>Email：-----------------------</p>",
        aboutUs:
            "<p>About Us</p>" +
            "<p>csgo8.com is a professional and impartial CS:GO skins case website, in csgo8 website, you can instantly get more csgo skins,Cases has all kinds of skins can be obtained at any time after picking up to the steam inventory. csgo8 is committed to providing players with more interesting way to get skins, using a unique mode of acquisition, is loved by many players .</p>" +
            "<p>Case introduction:</p>" +
            "<p>csgo8 case open will definitely drop CS:GO skins, and skins drop completely random, our company adheres to the principle of professional, fair to provide users with steam game virtual skins extraction services</p>" +
            "<p>Special reminder: consumption needs to be rational, recreation for fun.</p>" +
            "<p>Case rules: users can spend the corresponding amount of $ to open the case according to the different types of cases ($ for players to recharge to obtain, prohibit exchange); cases skins for users to obtain randomly, as well as the probability of skins drops are publicized on the skins chart, the case of this platform can get the range of skins to the specific chart in the website shall prevail. We hope that players will observe the relevant laws and regulations, keep rational consumption and open cases in a civilized manner.</p>" +
            "<p>Official website address: www.csgo8.com</p>" +
            "<p>Contact information: -----------------</p>" +
            "<p>Company Name：--------------------------</p>" +
            "<p>Address：-----------------------------</p>" +
            "<p>Email: ---------------</p>",
        questions:"<h2>Can I use Steam Wallet funds to buy csgo8 cases? </h2>" +
            "<p>Steam balances do not apply to this site. You will need to top up your balance directly on the csgo8 website. </p>" +
            "<h2>I have topped up my balance but the funds have not been transferred to my profile. What should I do? </h2>" +
            "<p>Your payment may be delayed by 5-10 minutes. If the funds are not transferred within an hour, please contact support to write the payment details to csgo8 Support. </p>" +
            "<h2>Your pickup has been pending for more than 30 days</h2>" +
            "<p>Trinkets not retrieved within 30 days will be automatically converted to a balance equivalent to be returned to your account. </p>" +
            "<h2>I was unable to sell the trinket due to a dead pop-up window! </h2>" +
            "<p>Close the window and refresh the page. If the problem persists, clear your browser cache and try again. </p>" +
            "<h2>Before buying a box</h2>" +
            "<p>Check that your Steam account marketplace trading privileges are not restricted, otherwise our bots will not be able to send skins to your account. Log in to your Steam account and open the marketplace, make sure the marketplace trading privileges are working and that your password has been updated for more than 7 days</p>",
        ruleDream:"<p>1. Each dream is calculated independently, by random function to get a certain value, and the current value of the jewelry multiplied by the percentage of the range of the player's control to get the occupied area for comparison, if the random value and the props in the area of the same, then you get the jewelry, if not, then you get the random consolation jewelry. </p>" +
            "<p>2. Since each dreamcatcher is calculated independently, when you open a dreamcatcher ornament with 30% chance for 3 times in a row, it does not mean you have 90% chance to get the ornament. When you have not dropped the jewelry for 3 consecutive 30%, the fourth time you open it with 10% probability does not mean you have 100% chance to get the jewelry, and you may face a higher chance of failure compared to the previous 30% chance of getting it. </p>",
    }
}
