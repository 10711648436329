<!--
时间:2023/12/20/020
用法:提示登录注册和首冲的弹窗
-->

<template>
  <transition name="fade">
    <div class="NewcomerPopup mask animate__animated" v-show="show" :class="{'show':show,'hidde':!show}">
      <transition name="fadeScale">
        <div class="popupContent" v-show="show" @click="claim">
          <img v-if="$i18n.locale === 'zh_CN'" class="bgImg" src="../assets/active/popUpBg-cn.png" alt="">
          <img v-else   class="bgImg" src="../assets/active/popUpBg-en.png" alt="">
          <div class="jewelryBox">
            <img class="jewelry" src="../assets/active/jewelry@2X.png" alt="">
            <ul>
              <li>注册最高赠送价值188F币随机游戏皮肤</li>
              <li>赠送随机4-28平台币</li>
<!--              <li>立即到账4 - 15币</li>-->
            </ul>
          </div>
          <div class="bagBox">
            <img class="bag" src="../assets/active/bag@2X.png" alt="">
            <ul>
              <li>首冲最高赠送百分之30%</li>
              <li>多重roll房 幸运转盘天天薅羊毛</li>
             <!-- <li>额外赠送3-220币</li>
              <li>多重真实ROLL房</li>-->
            </ul>
          </div>
          <img src="../assets/active/close-circle@2X.png" alt="" class="close" @click.stop="close">
        </div>
      </transition>
    </div>
  </transition>
</template>

<script>
import {mapMutations, mapState} from "vuex";

export default {
  name: "NewcomerPopup",
  computed: {
    ...mapState(['userInfo']),
  },
  data() {
    return {
      show: !localStorage.getItem('token'),
    }
  },
  methods: {
    close() {
      this.show = false
    },
    claim() {
      if (!localStorage.getItem('token')) {
        this.close()
        this.$store.commit('setLoginShow', true)
      } else if (this.userInfo.firstRecharged !== 1) {
        this.close()
        this.$router.push('/recharge')
      }
    }
  }
}
</script>

<style scoped lang="scss">
.mask {
  width: 100vw;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  background-color: rgba(0, 0, 0, 0.6);
  @include center;
}

.popupContent {
  position: relative;
  @include center;

  .bgImg {
    width: 30.17rem;
    cursor: pointer;
  }

  .bagBox, .jewelryBox {
    cursor: pointer;
    position: absolute;
    top: 50%;
    z-index: 1;
    width: 7rem;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
    li{
      list-style-type: disc;
      text-indent: -0.2rem;
      font-weight: bold;
      margin-bottom: 0.2rem;

    }
    ul{
      margin-top: 0.8rem;
      padding-left: 0.5rem;
    }
  }
  .jewelryBox {
    left: 7.2rem;
    .jewelry{
      width: 6.5rem;
    }
  }
  .bagBox {
    right: 7rem;
    .bag{
      width: 4.13rem;
      margin-left: 1rem;
    }
  }

  .close {
    width: 1.5rem;
    @include p-ab-centerX;
    bottom: -1rem;
    cursor: pointer;
    transition: opacity 0.3s linear;
    opacity: 0.8;

    &:hover {
      opacity: 1;
    }
  }

}

@media screen and (max-width: $windowSize-md) {
  .popupContent {
    position: relative;
    @include center;

    .bgImg {
      width: 42rem;
      cursor: pointer;
    }

    .bagBox, .jewelryBox {
      cursor: pointer;
      position: absolute;
      top: 51%;
      z-index: 1;
      width: 8rem;
      display: flex;
      align-items: flex-start;
      justify-content: center;
      flex-direction: column;
      li{
        list-style-type: disc;
        text-indent: -0.4rem;
      }
      ul{
        margin-top: 0.8rem;
        padding-left: 0.5rem;

      }
    }
    .jewelryBox {
      left: 10.5rem;
      .jewelry{
        width: 7rem;
      }
    }
    .bagBox {
      width: 9rem;
      right: 10rem;
      .bag{
        width: 5rem;
        margin-left: 1rem;
      }
    }

    .close {
      width: 2rem;
      @include p-ab-centerX;
      bottom: -1rem;
      cursor: pointer;
      transition: opacity 0.3s linear;
      opacity: 0.8;

      &:hover {
        opacity: 1;
      }
    }

  }
}


.show {
  background-color: rgba(0, 0, 0, 0.6);
  animation: fadeIn 0.5s;
}

.popup1Show {
  animation: zoomIn 0.5s;
}

.hidde {
  animation: fadeOut 0.5s;
}

.popup1Hidde {
  animation: zoomOut 0.5s;
}
</style>
