<!--
时间:2023/9/21/021
用法:弹窗右上角馆按钮
-->

<template>
  <div class='CloseIcon'  :class="closeClass">
    <SvgIcon class-name="closeSvg" icon-class="close1"/>
  </div>
</template>

<script>
import SvgIcon from "@/components/IconComponents/SvgIcon.vue";

export default {
  name: "CloseIcon",
  components: {SvgIcon},
  props:{
    closeClass:{
      type:String,
      default:''
    },
    height:{
      type:String,
      default:'0.55rem'
    },
    top:{
      type:String,
      default:'0.63rem'
    },
    left:{
      type:String,
      default:''
    },
    right:{
      type:String,
      default:'0.63rem'
    },
    bottom:{
      type:String,
      default:''
    },
  },
  data() {
    return {}
  },
  methods: {}
}
</script>

<style scoped lang="scss">
.CloseIcon{
  position: absolute;
  width: 0.69rem;
  height: 0.55rem;
  top:0.63rem;
  right: 0.63rem;
  .closeSvg{
    width: 100%;
    height: 100%;
    cursor: pointer;
    transition: all 0.3s linear;
    &:hover{
      transform: rotate(90deg);
    }
  }
}

</style>