<!--
时间:2023/12/20/020
用法:热门活动
-->

<template>
  <div class='HotEvents'>
    <div class="tab">
      <div class="tabItem btn"
           :class="{'active':tabIndex === index}"
           v-for="(item,index) in tab"
           @click="tabIndex = index"
           :key="index">
        {{ item.name }}
      </div>
    </div>
    <div  v-for="(item,index) in tab">
      <template   v-if="tabIndex === index">
        <!--    <img class="banner" src="" alt="">-->
        <div class="banner _windows">
          <img :src="item.img" @click="goToPath(item)">
        </div>
        <div class="banner _mobile" :class="{'height-aout': item.type == 1}">
          <img :src="item.img_h5" @click="goToPath(item)">
        </div>
      </template>

    </div>
  </div>
  <TurnTable v-show="turntableShow" :data="turntableShow" @closeTurn="closeTurn"/>
</template>

<script>
  import router from "@/router";
  import {mapMutations} from "vuex";
  import TurnTable from "@/layout/TurnTable";
  import {getActivityConfig} from "@/axios/active";
  export default {
    name: "HotEvents",
    components: {
      TurnTable,
    },
    async mounted() {
      await this.$nextTick();
      /*大转盘活动是否开启*/
      await this.getActivityConfig()
    },

    data() {
      return {
        tab: [
        ],
        tabIndex: 0,
        turntableShow: false,//大转盘弹框 显示
      }
    },
    methods: {
      ...mapMutations(['setActiveShow']),
      goToPath(item) {
        console.log("turntableShow",item.type)
        if(item.type == 4){
          this.$store.commit('setActiveShow',false)
          this.$router.push(item.path)
        }else  if(item.type == 2){
          this.turntableShow =true
          console.log("turntableShow",this.turntableShow)
        }else{
          router.push(item.path)
          this.setActiveShow(false)
        }

      },
      closeTurn() {
        this.turntableShow = false;
      },
      async getActivityConfig() {
        try {
          const params = {}
          /*获取活动配置*/
          let data = await getActivityConfig(params)
          /*获取到开启的活动数据*/
          let activityData = data.filter(item => item.enabled === 1)
          // type 1是笔笔送  2是大转盘 3是周一福利
          // eslint-disable-next-line no-empty
          let itemTurntable, itemDeliver, itemWelfare,fullmoon;
          for (let i = 0; i < activityData.length; i++) {
            switch (activityData[i].type) {
              case 1:
                itemDeliver = {
                  name: "笔笔送",
                  type: 1,
                  img: require('../../assets/active/deviler.jpg'),
                  img_h5: require('../../assets/active/deviler_h5.jpg'),
                  path:'/deliver',
                }
                break;
              case 2:
                itemTurntable = {
                  name: "大转盘",
                  type: 2,
                  img: require('../../assets/active/turntable.jpg'),
                  img_h5: require('../../assets/active/turntable_h5.jpg'),
                  path:'',//大转盘不穿path
                }
                break;
              case 3:
                itemWelfare = {
                  name: "周一福利",
                  type: 3,
                  img: require('../../assets/active/welfare.jpg'),
                  img_h5: require('../../assets/active/welfare_h5.jpg'),
                  path:'/welfare',
                }
                break;
                case 4:
                  fullmoon ={
                    name: "满月活动",
                    type: 4,
                    img: require('../../assets/active/follmoon_pc.jpg'),
                    img_h5: require('../../assets/active/follmoon_h5.jpg'),
                    path:'/fullmoon',
                  }
                  break;
            }
          }
          /*三个活动都没有开启隐藏活动列表*/
          if (!itemTurntable && !itemDeliver && !itemWelfare&& !fullmoon) {
            this.isShow = false;
          } else {
            if (itemTurntable) {
              this.tab.push(itemTurntable)
            }
            if (itemDeliver) {
              this.tab.push(itemDeliver)
            }
            if (itemWelfare) {
              this.tab.push(itemWelfare)
            }
            if (fullmoon) {
              this.tab.push(fullmoon)
            }
          }
        let  itemDs = {
            name: "东山再起",
            type: 4,
            img: require('@/assets/active/ds_pc.jpg'),
            img_h5: require('@/assets/active/ds_h5.jpg'),
          path: '/comeback'
          }
          this.tab.push(itemDs)
          console.log("getActivityConfig", data)
        } catch (e) {
          console.log(e)
        }
      },
    }
  }
</script>

<style scoped lang="scss">
  @media screen and (min-width: $windowSize-md) {
    .tab {
      display: flex;
      align-items: center;
      margin-bottom: 1.5rem;

      .tabItem {
        font-size: 0.75rem;
        font-weight: bold;
        margin-right: 2rem;
        position: relative;
      }

      .active {
        color: #FBC600;

        &::after {
          content: '';
          display: block;
          @include p-ab-centerX;
          bottom: -0.5rem;
          width: 2rem;
          height: 2px;
          background-color: #FBC600;
        }
      }
    }

    .banner {
      width: 100%;
      border-radius: 0.42rem;
      margin-bottom: 0.9rem;
      background: #30323E;
      height: 11.33rem;

      img {
        width: 100%;
      }
    }

    .tips {
      margin-bottom: 0.8rem;
    }

    .hotBtn {
      width: 5.05rem;
      height: 1.59rem;
      background: #FFC600;
      border-radius: 0.17rem;
      color: #191B29;
      @include center;
      font-weight: bold;
    }
  }

  @media screen and (max-width: $windowSize-md) {
    .tab {
      display: flex;
      align-items: center;
      margin-bottom: 1.5rem;

      .tabItem {
        font-size: 1rem;
        margin-right: 2rem;
        width: 9.53rem;
        height: 2.84rem;
        border-radius: 0.42rem;
        position: relative;
        background-color: rgba(255, 255, 255, 0.05);
        @include center;
      }

      .active {
        color: #FBC600;
        background-color: rgba(251, 198, 0, 0.3);
      }
    }

    .banner {
      width: 100%;
      border-radius: 0.42rem;
      margin-bottom: 0.9rem;
      background: #30323E;
      /*height: 18.33rem;*/
      @include center;

      img {
        width: 100%;
        /*height: 100%;*/
      }
    }
    .height-aout {
      height: auto;
    }

    .tips {
      margin-bottom: 0.8rem;
    }

    .hotBtn {
      color: #191B29;
      @include center;
      font-weight: bold;
      width: 8.74rem;
      height: 2.92rem;
      background: #FFC600;
      border-radius: 0.33rem;
      font-size: 1.08rem;
    }
  }


</style>
