<template>
  <div class="qrcode">
    <qrcode-vue :value="value" :size="sizeRem" level="L"/>
  </div>
</template>
<script>
import QrcodeVue from 'qrcode.vue'

export default {
  name: "Qrcode",
  data() {
    return {
      sizeRem: 0
    }
  },
  props: {
    value: {
      type: String,
      require: true
    },
    size: {
      type: Number,
      default: () => {
        return 10
      }
    }
  },
  components: {
    QrcodeVue,
  },
  mounted() {
    let that =this
    let timer = setTimeout(()=>{
      const resize = () => {
        const html = document.querySelector('html')
        let fontSize = html.style.fontSize
        let size = fontSize.replace('px', '')
        that.sizeRem = that.size * size
      }
      resize()
      window.addEventListener('resize',resize)
      clearTimeout(timer)
      timer = null
    },100)

  }
}
</script>
<style lang="scss">
.qrcode{
  padding:0.2rem;
  background-color:#fff;
  display:inline-block;
  //cursor: pointer;
  @media screen and (max-width: 992px){
    padding:0.5rem;
  }

}

</style>
