import { createApp } from 'vue'
import Confirm from '@/components/Confirm/Confirm.vue'

const createDialog = (option = {}) => {
    let MessageDialog = document.querySelector('.Confirm')
    const mountNode = document.createElement('div')
    const Instance = createApp(Confirm, {
        ...option,
        close: () => {
            Instance.unmount(mountNode);
            document.body.removeChild(mountNode);
        }
    })
    document.body.appendChild(mountNode)
    Instance.mount(mountNode)

}

export default {
    install(app) {
        app.config.globalProperties.$confirm = createDialog
    }
};

