<!--
时间:2023/12/21/030
用法:大转盘组件
-->
<template>
    <div class="blind-bg">
        <div class="box">
            <img class="title" src="../assets/newactivity/activity/turntable/title.png">
            <img class="close" src="../assets/newactivity/closure.png" @click="close">
            <div class="round_box">
                <div class="center">
                    <img class="img_rotate" ref="rotImg"
                         src="../assets/newactivity/activity/turntable/turntable-bottom.png" alt="">
                    <div class="btn" v-if="!this.hasTodayReceive" @click="toDraw"></div>
                    <div class="btn disabled" v-if="this.hasTodayReceive"></div>
                </div>
            </div>
            <div class="text-bg"> 累计存款满{{this.minAmount}}元后，可享受每日一次免费抽奖 累计存款越多抽到的奖品越好哦</div>
        </div>
        <div class="result-box" v-if="resultShow">
            <span>{{this.resultData[0].prizeDesc}}</span>
            <img class="close-result" src="../assets/newactivity/closure-1.png" @click="resultClose">
            <div class="close-result-div">
                <div class="close-result-item" v-for="(item,index) in resultData" :key="index">
                    <!--            <div class="close-result-item" v-if="resultData" >-->
                    <img v-if="item.prizeType === 1  && item.index ===0" class="center-img" :src="item.prizeImg">
                    <img v-if="item.prizeType === 2 && item.index ===0" class="center-img"
                         src="../assets/newactivity/activity/turntable/prize.png">
                    <img v-if="item.index ===1" class="center-img-box"
                         src="../assets/newactivity/activity/turntable/prize-top.png">
                    <div  class="center-img-hot">
                        {{item.nextRechargeGiftRatio}}%
                    </div>
                    <span v-if="item.index ===0" class="center-sp">{{item.prizeName}}</span>
                    <span v-if="item.index ===1"
                          class="center-sp">下次充值享{{item.nextRechargeGiftRatio}}%赠送<br/>今日有效</span>
                    <!--                <span v-if="item.index ===0" class="center-sp">奖品名称</span>-->
                </div>
            </div>
            <img class="close-result-bottom" src="../assets/newactivity/activity/turntable/button.png"
                 @click="resultClose">
        </div>
    </div>
</template>

<script>

    import {goPath} from "@/router/goPath";
    import {doTurntablePrize, getActivityConfig, getDeliver, getTurntable} from "@/axios/active";
    import {mapState} from "vuex";

    export default {
        name: "turntable",
        computed: {
            ...mapState(['userInfo'])
        },
        data() {
            return {
                rotate: 0,
                resetRotate: 0,
                hitId: 0, //1-10
                drawStatus: false,
                // count:3 , //可抽奖次数
                // turntableShow: false,//大转盘弹框 显示
                hasTodayReceive: false,//今日是否已经抽过奖
                resultShow: false,//中奖结果 显示
                resultText: '',
                rightShow: true,//右侧菜单是否展示
                isShow: true,//是否有活动
                resultData: null,
                navList: [],
                minAmount: 0,//大转盘最小充值额度可以抽奖
                // prizeResult:null,//大转盘抽奖返回的结果
            }
        },

        async mounted() {
            await this.$nextTick();
            await this.getActivityConfig()
            let evenTransition = this.whichTransitionEvent();
            let img = this.$refs.rotImg;
            let that = this;
            // const  hitAre = ['一等奖' ,'二等奖','三等奖','四等奖','五等奖','六等奖','七等奖','八等奖'];
            //监听 动画结束
            if (img) {
                img.addEventListener(evenTransition, tranHand, true);
            }

            function tranHand() {
                //复位
                that.resetRotate = that.rotate > 360 ? that.rotate % 360 : 0;
                img.style.transition = "none 0s ease 0s";
                img.style.transform = `rotate(${that.resetRotate}deg)`;
                // that.resultText = hitAre[that.hitId - 1];
                // that.resultText = this.prizeResult.prizeLevelName;
                // alert(`抽奖结果【 ${hitAre[that.hitId - 1]} 】`);
                that.drawStatus = false;
                that.resultShow = true;
                /*标识今天已经抽过奖*/
                this.hasTodayReceive = true;
            }

        },

        methods: {
            goPath,
            async getActivityConfig() {
                try {
                    const params = {}
                    /*获取活动配置*/
                    let data = await getActivityConfig(params)
                    console.log("userInfotoken",localStorage.getItem('token'))
                    if( localStorage.getItem('token')){
                        await this.getTurntable();
                    }

                    /*获取到开启的活动数据*/
                    let activityData = data.filter(item => item.enabled === 1)
                    // type 1是笔笔送  2是大转盘 3是周一福利
                    // eslint-disable-next-line no-empty
                    let itemTurntable, itemDeliver, itemWelfare;
                    for (let i = 0; i < activityData.length; i++) {
                        switch (activityData[i].type) {
                            case 1:
                                itemDeliver = {
                                    name: 'deliver',
                                    path: '/deliver',
                                    img: require('../assets/newactivity/activity/icon/deliver.png')
                                }
                                break;
                            case 2:
                                itemTurntable = {
                                    name: 'turntable',
                                    path: 'turntable',
                                    img: require('../assets/newactivity/activity/icon/turntable.png')
                                }
                                break;
                            case 3:
                                itemWelfare = {
                                    name: 'welfare',
                                    path: '/welfare',
                                    img: require('../assets/newactivity/activity/icon/welfare.png')
                                }
                                break;
                        }
                    }
                    /*三个活动都没有开启隐藏活动列表*/
                    /* if (!itemTurntable && !itemDeliver && !itemWelfare) {
                         this.isShow = false;
                     } else {*/
                    if (itemTurntable) {
                        this.navList.push(itemTurntable)
                    }
                    if (itemDeliver) {
                        this.navList.push(itemDeliver)
                    }
                    if (itemWelfare) {
                        this.navList.push(itemWelfare)
                    }
                    /*添加在线客服*/
                    let lineServer = {
                        name: 'lineServer',
                        path: 'http://txyl-web.huiyunsec.xyz/csgo/csgo.html',
                        img: require('../assets/newactivity/activity/icon/zxkf.png')
                    }
                    this.navList.push(lineServer)
                    // this.navList.push(itemTurntable,itemDeliver,itemWelfare)
                    // }
                    // this.total = data.total
                    // this.tableData = data.records
                    console.log("getActivityConfig", data)
                } catch (e) {
                    console.log(e)
                }
            },

            /*获取大转盘状态*/
            async getTurntable() {
                try {
                    const params = {}
                    /*获取活动配置*/
                    let data = await getTurntable(params)
                    this.minAmount = data.minRechargeAmount
                    /*显示大转盘*/
                    // this.turntableShow = true
                    /*获取今日是否已经抽过奖*/
                    this.hasTodayReceive = data.hasTodayReceive
                    /*if(!this.turntableShow) {
                      return this.$Msg.warn("今日已抽奖")
                    }*/
                } catch (e) {

                }
            },
            close() {
                if (this.drawStatus) {
                    return this.$Msg.warn("正在抽奖，请稍后")
                }
                this.$emit("closeTurn")
            },
            resultClose() {
                this.resultShow = false;
            },
            start() {
                this.$refs.rotImg.style.transition = "all 3s ease 0s";
                this.$refs.rotImg.style.transform = `rotate(${this.rotate}deg)`;
            },
            async toDraw() {
                if (this.drawStatus) {
                    return this.$Msg.warn('正在抽奖中')
                }
                try {
                    /*调用抽奖接口*/
                    const params = {}
                    await doTurntablePrize(params).then(response => {
                        /*抽奖结果数据重组*/
                        if (response.nextRechargeGiftRatio == 0) {
                            this.resultData = [{
                                index: 0,
                                prizeType: response.prizeType,
                                prizeTypeName: response.prizeTypeName,
                                prizeDesc: response.prizeDesc,
                                prizeLevelName: response.prizeLevelName,
                                prizeName: response.prizeName,
                                prizeImg: response.prizeImg,
                            }]
                        } else {
                            this.resultData = [{
                                index: 0,
                                prizeType: response.prizeType,
                                prizeDesc: response.prizeDesc,
                                prizeLevelName: response.prizeLevelName,
                                prizeTypeName: response.prizeTypeName,
                                prizeName: response.prizeName,
                                prizeImg: response.prizeImg,
                            },
                                {
                                    index: 1,
                                    prizeType: response.prizeType,
                                    prizeDesc: response.prizeDesc,
                                    prizeLevelName: response.prizeLevelName,
                                    prizeTypeName: response.prizeTypeName,
                                    prizeName: response.prizeName,
                                    prizeAmout: response.prizeAmout,
                                    nextRechargeGiftRatio: response.nextRechargeGiftRatio,
                                }
                            ]
                        }
                        /*
                       if (this.count <= 0) {
                         alert.log('抽奖次数已用完');
                         return
                       }
                       this.count--;*/
                        //标记状态
                        this.drawStatus = true
                        /*
                        * 圆盘共 8 份 每份45度 停留位置 id  度数 （id-1）*45
                        * 基数 3圈 360*4
                        * this.rotate 当前角度
                        * */
                        let reset = 360 * 4;
                        // let idx = this.getRandomInt(1, 8);
                        // let idx =5;
                        //设置命中
                        this.hitId = response.prizeLevel;
                        //需要多转角度
                        let addRostate = this.resetRotate > 0 ? 360 - this.resetRotate : 0;
                        //总共角度
                        let allRostate = this.rotate + (360 - ((this.hitId - 1) * 45)) + reset + addRostate;
                        //角度限制
                        this.rotate = this.setRotate(allRostate)
                        this.start()
                    })
                } catch (e) {

                }


            },
            //递归计算角度 不超过 360 *6
            setRotate(deg) {
                let rest = deg - 360;
                return rest > 360 * 8 ? this.setRotate(rest) : deg;
            },
            getRandomInt(min, max) {
                //向上收
                min = Math.ceil(min);
                //向下收
                max = Math.floor(max);
                return Math.floor(Math.random() * (max - min)) + min; //不含最大值，含最小值
            },
            //    动画兼容
            whichTransitionEvent() {
                let el = document.createElement('span'),
                    transitions = {
                        'transition': 'transitionend',
                        'OTransition': 'oTransitionEnd',
                        'MozTransition': 'transitionend',
                        'WebkitTransition': 'webkitTransitionend',
                    };
                for (let t in transitions) {
                    if (el.style[t] != undefined) {
                        return transitions[t]
                    }
                }
                el = null;
            },
        },
        watch: {

            turntableShow: (turntableShow) => {
                document.body.style.overflow = turntableShow ? 'hidden' : 'auto'

            },
           /* minAmount: (minAmount) => {
                this.minAmount = minAmount

            }*/
        }
    }

</script>

<style scoped lang="scss">

    .blind-bg {
        background: rgb(35 32 32 / 50%);
        position: fixed;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: 100;


        .box {
            position: absolute;
            display: flex;
            flex-direction: column;
            top: 50%;
            left: 55%;
            width: 38%;
            transform: translate(-50%, -50%);

            .title {
                /*height: 1rem;*/
                margin: auto;
            }

            .close {
                position: absolute;
                right: 0;
            }

            .img_rotate {
                height: 21rem;
                transform: rotate(0deg);
            }

            .round_box {
                width: 100%;
                height: 26rem;
                margin-top: -3rem;
                margin-left: -6rem;
                /*max-width: 375px;*/
                position: relative;
                overflow: hidden;
                background: url("../assets/newactivity/activity/turntable/figure.png") no-repeat center / cover;;
                /*img{
                  width: 100%;
                }*/
                .center {
                    z-index: 999;
                    position: absolute;
                    top: 50%;
                    left: 60%;
                    transform: translate(-50%, -50%);

                    .btn {
                        position: absolute;
                        left: calc(50% - 70px);
                        top: calc(50% - 100px);
                        width: 142px;
                        height: 165px;
                        border: 0;
                        outline: none;
                        cursor: pointer;
                        z-index: 9;
                        background: url("../assets/newactivity/activity/turntable/turntable-middle.png");

                        &.disabled {
                            height: 165px;
                            top: calc(50% - 90px);
                            background: url("../assets/newactivity/activity/turntable/turntable_middle_h.png");

                            &:hover {
                                animation: none;
                            }
                        }

                        &:hover {
                            animation: shake .5s;
                        }
                    }
                }
            }

            .text-bg {
                display: block;
                position: absolute;
                bottom: 15px;
                padding: 0.8rem;
                color: #fff5dc;
                font-size: 24px;
                border: 1px solid #CEBFA7;
                border-radius: 10px;
                text-align: left;
                z-index: 10;
                width: 76%;
                background: #191b2a;
                /*line-height: 1.8rem;*/
            }
        }

        .result-box {
            position: absolute;
            display: flex;
            flex-direction: column;
            top: 50%;
            left: 51%;
            transform: translate(-50%, -50%);
            height: 17rem;
            padding: 0.5rem;
            align-items: center;
            background: linear-gradient(-30deg, #576170 0%, #49505b 100%);
            border-radius: 20px;
            border: 4px solid #CEBFA7;

            span {
                width: 100%;
                text-align: center;
                font-size: 1rem;
                color: #fbc300;
            }

            .close-result {
                position: absolute;
                right: 0.5rem;
                width: 0.8rem;
            }

            .close-result-div {
                display: flex;
                justify-content: space-between;

                .close-result-item {
                    width: 10rem;
                    height: 11rem;
                    margin-top: 1rem;
                    margin-right: 1rem;
                    margin-left: 1rem;
                    border-radius: 20px;
                    background: #0f0f0f3d;
                    display: grid;
                    justify-content: center;
                    align-items: center;
                    clear: both;

                    .center-img {
                        width: 9rem;
                    }

                    .center-img-box {
                        margin-top: 1rem;
                        margin-left: 1rem;
                        width: 8.41rem;
                    }

                    .center-img-hot {
                        width: 2rem;
                        height: 2rem;
                        position: absolute;
                        top: 3.3rem;
                        right: 8rem;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        background: url("../assets/newactivity/deliver.png") no-repeat center/cover;
                    }

                    .center-sp {
                        color: #fff;
                        font-size: 0.8rem;
                        text-align: center;
                    }
                }
            }

            .close-result-bottom {
                position: absolute;
                bottom: 1rem;
                height: 2rem;
            }
        }
    }

    @media screen and (max-width: $windowSize-md) {
        .blind-bg {
            background: rgb(35 32 32 / 50%);
            position: fixed;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            z-index: 100;

            .box {
                position: absolute;
                display: flex;
                flex-direction: column;
                width: 100%;
                height: 50%;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);

                .title {
                    /*height: 1rem;*/
                    width: 50%;
                    margin: auto;
                }

                .close {
                    position: absolute;
                    z-index: 9999;
                    /*top: 2rem;*/
                    right: 2rem;
                    width: 3rem;
                    height: 3rem;
                }


                .img_rotate {
                    height: 26rem;
                    transform: rotate(0deg);
                }

                .round_box {
                    width: 100%;
                    /*height: 90%;*/
                    margin-left: 0.2rem;
                    /*max-width: 375px;*/
                    position: relative;
                    overflow: hidden;
                    background: url("../assets/newactivity/activity/turntable/figure_h5.png") no-repeat center / cover;;
                    /*img{
                      width: 100%;
                    }*/
                    .center {
                        z-index: 999;
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        transform: translate(-50%, -50%);

                        .btn {
                            position: absolute;
                            left: calc(50% - 2.5rem);
                            top: calc(50% - 3.8rem);
                            width: 5rem;
                            height: 6rem;
                            border: 0;
                            outline: none;
                            cursor: pointer;
                            z-index: 9;
                            background: url("../assets/newactivity/activity/turntable/turntable-middle.png");
                            background-size: cover; /* 背景图片大小适应<div> */
                            background-repeat: no-repeat; /* 不重复背景图片 */
                            background-position: center; /* 居中显示背景图片 */

                            &.disabled {
                                width: 5rem;
                                height: 6rem;
                                left: calc(50% - 2.5rem);
                                top: calc(50% - 3.8rem);
                                background: url("../assets/newactivity/activity/turntable/turntable_middle_h.png");
                                background-size: cover; /* 背景图片大小适应<div> */
                                background-repeat: no-repeat; /* 不重复背景图片 */
                                background-position: center; /* 居中显示背景图片 */

                                &:hover {
                                    animation: none;
                                }
                            }

                            &:hover {
                                animation: shake .5s;
                            }
                        }
                    }
                }

                .text-bg {
                    display: block;
                    position: absolute;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    width: 75%;
                    top: 98%;
                    height: 2.6rem;
                    padding: 0.8rem;
                    color: #fff5dc;
                    font-size: 1rem;
                    border: 1px solid #CEBFA7;
                    border-radius: 10px;
                    text-align: left;
                    z-index: 10;
                    background: #191b2a;
                    /*line-height: 1.8rem;*/
                }
            }

            .result-box {
                position: relative;
                display: flex;
                flex-direction: column;
                top: 39%;
                margin: 0 3.5rem;
                left: 39.5%;
                right: 0;
                transform: translateX(-50%);
                height: 17rem;
                padding: 0.5rem;
                align-items: center;
                background: linear-gradient(-30deg, #576170 0%, #49505b 100%);
                border-radius: 20px;
                border: 4px solid #CEBFA7;

                span {
                    width: 100%;
                    text-align: center;
                    font-size: 1rem;
                    color: #fbc300;
                }

                .close-result {
                    position: absolute;
                    right: 0.5rem;
                    width: 0.8rem;
                }

                .close-result-div {
                    display: flex;
                    justify-content: space-between;

                    .close-result-item {
                        width: 10rem;
                        height: 11rem;
                        margin-top: 1rem;
                        margin-right: 1rem;
                        margin-left: 1rem;
                        border-radius: 20px;
                        background: #0f0f0f3d;
                        display: grid;
                        justify-content: center;
                        align-items: center;
                        clear: both;

                        .center-img {
                            width: 9rem;
                        }

                        .center-img-box {
                            margin-top: 1rem;
                            margin-left: 1rem;
                            width: 8.41rem;
                        }

                        .center-img-hot {
                            width: 2rem;
                            height: 2rem;
                            position: absolute;
                            top: 3.3rem;
                            right: 8rem;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            background: url("../assets/newactivity/deliver.png") no-repeat center/cover;
                        }

                        .center-sp {
                            color: #fff;
                            font-size: 0.8rem;
                            text-align: center;
                        }
                    }
                }

                .close-result-bottom {
                    position: absolute;
                    bottom: 1rem;
                    height: 2rem;
                }
            }
        }
    }
</style>
