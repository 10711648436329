export default {
    nav: {
        // 导航栏
        welfare: "Bonus",
        mySkins: 'Eşyalarım',
        myWelfare: 'Bonuslarım',
        myReward: 'Komisyonum',
        information: "ki̇şi̇sel merkez",
        historicalBilling: 'İşlemler',
        login: "Giriş",
        logout: "Çıkış",
        blindBox: 'Kasa',
        rollRoom: "Roll Odası",
        dream: "Hayallerin peşinde",
        battle: "Kasa savaşı",
        bomb: "Bomba İmha",
        refill: "Yükleme",
        // 登陆弹窗
        loginTitle: 'csgo8\'i kullanabildiğinizden emin olmamız gerekiyor, lütfen onaylayın:',
        my18: '18 yaşından büyük olduğumu onaylıyorum.',
        agree: "Aşağıdakileri okudum ve kabul ediyorum:",
        usrAgreement: 'Kullanıcı Sözleşmesi',
        privacyAgreement: 'Gizlilik Sözleşmesi',
        antiMoneyLaundering: 'Para Aklamayı Önleme Anlaşması',
        // 电脑版footer内容
        openCases: 'Toplam açılan kasa sayısı',
        users: 'Kayıtlı kullanıcılar',
        online: 'Çevrimiçi kullanıcılar',
        footerText1: "Kasa açmanın çeşitli yolları var, ayrıca eşya çıkarmak güvenli ve kolay!",
        // 手机版侧边栏
        games: "Oyun",
        preferential: "Özel teklif",
        free: "Ücretsiz",
        recharge: "Yükleme",
        cdk: "CDK (Ürün Anahtarı)",
        other: "Diğerleri",
        QQ: "QQ",
        custServ: "Müşteri Hizmetleri",
        App: "App",
        glblMusicEnabled: "Ses Efektleri Kapatıldı",
        soundEffectsDisabled: "Ses Efektleri Açıldı",
        questions: "Sıkça Sorulan Sorular",
        aboutUs: "Hakkımızda",
        pleaseEntrANickname: "Bir Takma Ad Girin",
        pleaseEntrYourEmailAddr: "Lütfen cep telefonu numaranızı girin",
        pleaseEntrTheVerificationCd: "Bir Doğrulama Kodu Girin",
        obtainVerificationCd: "Doğrulama Kodu Alın",
        pleaseOutputTheInptPsw: "Lütfen Şifre Girin",
        pleaseConfThePsw: "Lütfen Şifreyi Tekrar Girin",
        pleaseEntrTheInvtCd: "Davet Kodunu Girin (Opsiyonel)",
        noSteam: "Steam Hesabınız Yok mu?",
        emailLogin:"Cep telefonu numarası girişi",
        passwordLogin:"şifre girişi",
        verificationCdLogin:"CAPTCHA Girişi",
        register: "Kayıt Ol",
        steamLogin: "Steam ile Giriş",
        forgetPsw: "Şifreni mi unuttun?",
        conf: "Onayla",
        retrievePsw: "Şifre Kurtar",
        ruleDream: "Hayallerin peşinden koşmak için kurallar",
        exchange:"dönüştürülebilir",
        inptCdk:"Lütfen CDK şifresini girin",
        redemptionSuccessful: "Başarılı kefaret, A-coins"
    },
    blindBox: {
        //开箱页面
        open: "Aç",
        buy: "Satın Al",
        bestDrp: "Son Düşüşler",
        boxContains: "Kasa şunları içerir",
        buyTips1: "Kasa açmak için yeterli bakiyeniz yok!"
    },
    roll: {
        ofcl: "Resmi",
        anchor: "Yayıncı",
        iParticipatedIn: "Katıldığım",
        haveInHand: "Devam Eden",
        itsOvr: "Bitmiş",
        participated: "nişanlı",
        viewingRooms: "Oda Görüntüle",
        joinNow: "Hemen Katıl",
        deadline: "Bitiş Zamanı",
        totVal: "Toplam Değer",
        items: "Eşyalar",
        players: "Oyuncu",
        //roll详情
        countdownToLottery: "Çekiliş Geri Sayımı",
        prtpConditions: "Katılım Koşulları",
        includingPrizes: "Ödüller içerir",
        participatingUsers: "Katılımcılar",
        awrdLst: "Kazananlar",
        joinRm: "Odaya Katıl"
    },
    dream: {
        chooseAccessories: "Eşya Seç",
        probability: "Olasılık",
        upg: "Rüyalar",
        upgTips: "*Rüyalar olasılığı aralığı: {num1}%~{num2}%",
        //追梦选择饰品弹窗
        inputPlaceholder1: "Anahtar kelimeyi ara",

    },
    battle: {
        //对战列表
        allBattles: "Tüm Savaşlar",
        rankingLst: "Sıralama",
        historicalRecords: "Kayıt Geçmişi",
        myRecords: "Kayıtlarım",
        creABattle: "Savaş Oluştur",
        //创建对战
        addBox: "Kasa Ekle",
        robot: "Bot",
        combatCost: "Savaş maliyeti",
        createBattle: "Savaş Oluştur",
        chooseAnItem: "Eşya Seçin",
        incorrectNumberOfPeople: "Kişi sayısı hatalı",
        inputPlaceholder1: "Kasa anahtar kelimesini girin",
        thisMorning: "Bu sabah",
        thisAfternoon: "Bu öğleden sonra",
        selected: "Seçilen: {num} adet",
        coVal: "Toplam maliyet",
        //对战详情
        ready: "Hazır",
        drawnJewelry: "Eşya çekiliyor",
        obtainJewelry: "Eşya kazanıldı",
        //排行榜
        today: "Bugün",
        yesterday: "Dün",
        //对战结束弹窗
        battleIsOvr: "Savaş Sonu",
        winner: "Kazanan",
        oneMoreRound: "Aynı eşleşmeyi oluştur",
        confJoin: "Üyelik onaylandı mı?"
    },
    information: {
        //个人中心首页
        invtCd: "Davet Kodu",
        bal: 'Bakiye',
        rchg: "Yükleme",
        orderNumber: 'Sipariş Numarası',
        transactionLnk: "STEAM Ticaret Bağlantısı",
        steamLinkTips1: "Ticaret bağlantısı bir kez bağlandığında değiştirilemez, lütfen dikkatli olun！Tokenın aynı bağlantıyla güncellenmesinde herhangi bir sınır yoktur.",
        steamLinkTips2: "Başkalarının Steam ticaret bağlantısını bağlamayın.",
        steamInputPlaceholder1: "Steam ticaret bağlantınızı girin",
        obtain: "Al",
        saveAddr: "Adresi Kaydet",
        save: "Kaydet",
        mySkins: 'Eşyalarım',
        myWelfare: 'Bonuslarım',
        rchgDetails: "Yükleme Detayları",
        incomeDetails: "Gelir/Gider Detayları",
        myReward: 'Komisyonum',
        // 我的物品页面
        retrieving: "Geri Alınıyor",
        retrieveRecords: "Geri Alma Geçmişi",
        numOrnaments: "Toplam {num} adet eşya",
        obtainingTm: "Kazanma zamanı",
        val: "Değer",
        selectedNum: "Seçilen: {num} adet",
        retrieve: "Geri Al",
        chooseABox: "... kazanmak için kasa seçmeye git",
        noItems: "Henüz bir eşyan yok",
        noRecIsCur: "Şu anda bir kayıt yok",
        //佣金页面
        generalizationCd: "Promosyon Kodu",
        cpy: "Kopyala",
        promLnk: "Tanıtım Linki",
        partnerRules: "Şartlar ve kurallar",
        totNumOfInvitations: "Toplam davet edilen sayısı",
        effectiveUsers: "Geçerli Kullanıcılar",
        accumRewards: "Toplam Ödül",
        rewardRatio: "Ödül Oranı",
        lvlDsc: "Seviye açıklaması",
        invtRec: "Davet Kayıtları",
        date: "Tarih",
        invitee: "Davet Edilenler",
        usrRchg: "Kullanıcı Yükleme",
        myRewards: "Ödüllerim",
        rewardLvl: "Ödül Seviyesi",
        invitedUsrRchg: "Davet Edilen Kullanıcı Yükleme",
        promAndAcq: "Tanıtım yapan kullanıcılar şunları alacaktır:",
        incmStmt: "Kazanç ile İlgili Açıklama"
    },
    confirm: {
        success: "Başarılı",
        warning: "Uyarı",
        error: "Hata",
        noDataAvailable: "Veri Yok",
        //老虎机抽奖结果弹窗
        decompose: "Ayrıştır",
        decomposeAll: "Tümünü Ayrıştır",
        cont: "ile devam edin",
        //confirm提示弹窗
        confDecomposition: "{num} adet eşyayı ayrıştırmak istediğine emin misin?",
        common: "Toplam",
        cancel: "İptal",
        confirm: "Onayla",
        confDec: "Ayrıştırmayı onaylıyor musun?",
        confOpn: "Açılış onayı?",
        conf: "Onaylama?",
        //msg提示弹窗
        decomposeSuccess: "Ayrıştırma başarılı!",
        theMinimumProbabilityIs: "En düşük olasılık {num}",
        notLoggedIn: "Giriş yapılmadı",
        noJewelrySelected: "Eşya seçilmedi",
        agreeToATreaty: "Lütfen aşağıdaki şartları okuyup kabul edin",
        copySuccess: "Kopyalama Başarılı",
        copyError: "Kopyalama Başarısız",
        pleaseFollow: "Geri Alınıyor, lütfen bekleyin",
        notYetOpn: "Henüz açılmadı",
        glblMusic: "Lütfen önce tüm sesleri açın",
        amtCannotBeZero: "Tutar 0 olamaz",
        pswModSuccessful: "Şifre Değiştirme Başarılı",
        loginSuccessful: "Giriş Başarılı",
        loginWasSuccessful: "Kayıt başarılı, lütfen giriş yapın!",
        emailFmtErr: "Yanlış biçimlendirilmiş cep telefonu numarası",
        vSntSuccess: "Doğrulama kodu başarıyla gönderildi",
        pswInconsistency: "Şifreler aynı değil",
        entrTheVerificationCd: "Lütfen Doğrulama Kodunu Girin",
        theMinPswLenIs6: "Şifrenin uzunluğu en az 6 karakter olmalıdır.",
        pleaseEntrANickname: "Bir Takma Ad Girin",
    },
    //常用的
    common: {
        //单位
        day: 'Gün',
        hh: "Saat",
        mm: 'Dakika',
        ss: "Saniye",
        piece: "Adet",
        // title常用
        back: "Geri Dön",
        gameRules: "Oyun Kuralları",
        //表格常用
        players: "Oyuncu",
        price: "Fiyat",
        reset: "Sıfırla",
        cases: "Kasa",
        value: "Değer",
        actions: "İşlem",
        watch: "İzleme",
        join: "Katıl",
        ranking: "Sırala",
        bestDrp: "En İyi Düşen",
        date: "Tarih",
        st: "Durum",
        purp: "Kullanım",
        amtOfMoney: "Tutar",
        allElection: "Tümünü Seç",
        typ: "TİPOLOJİ",
        numericalVal: "sayısal değer",

    },
    incomeType: {
        //个人中心收支type
        whole: "Tümü",
        incm: "Gelir",
        expenditure: "Gider",
        purCurr: "Coin satın al",
        recovery: "Geri Al",
        unpacking: "Kasa Aç",
        commission: "Komisyon",
        redEnvelopes: "Hediye Paketi",
        war: "Savaş",
        shoppingMallPur: "Mağaza Alışverişi",
        chasingDreams: "Hayallerin peşinde",
        coinPurGft: "Coin Hediye Et",
        cshWdrl: "Para Çekme",
        rlRm: "Roll Odası",
        regGifts: "Kayıt Hediyesi",
        addRewards: "Ek Ödül",
        redEnvelopeReward: "Hediye Paketi Ödülü",
        luckyTurntable: "Çarkıfelek",
        bkOffcDistribution: "Arka Planda Dağıtım",
        skinSynthesis: "Skin Sentezi",
        sgnInReward: "Giriş Ödülü",
        newDreamChasing: "Yeni Hayallerin peşinde",
        unpackingKing: "Kasa Açma Kralı",
        skydiverHd: "Extrem skydiving",
        inviteRebate: "Davetiye iadesi",
        taskRewards: "Görev Ödülleri",
    },
    provably: {
        provably: "Adalet doğrulaması",
        provablyDsc: "Adillik Doğrulama Beyanı",
        text1: "1. Oyuncular her oynadıklarında bir zar puanı üretecek ve ilgili zar puanı ilgili deriyi alacaktır.",
        text2: "2, açık kutu kovalamaca rüyaları arena üç oyun her bir kullanıcı tohumu seti ve genel karma, modül içinde ortak, örneğin: açık kutu blok a kutusu ve b kutusu rl noktasında hiçbir fark yoktur, sadece cildi alın bir fark var.",
        text3: "3. İşlemde yer alan şifrelenmiş değerler aşağıdaki gibidir:",
        text4: "4, bu roll point algoritmasının açık kaynak kodunu ve test platformunu sağlıyoruz, programcı bilen arkadaşlar tek başına aritmetik yaparak ilgisiz web sunucuları durumunda sonuçların tutarlı olup olmadığını görebilirler.",
        clientSeed: "KullanıcıSeed",
        publicHash: "publicHash",
        round: "tur sayısı",
        secretHash: "gizli karma",
        secretSalt: "gizli tuz",
        createTime: "createTime",
        enterClientSeed: "Lütfen kullanıcı tohumunu girin",
        enterPublicHash: "Lütfen genel karmayı girin",
        enterSecretHash: "Lütfen gizli karmayı girin",
        enterSecretSalt: "Lütfen gizli tuzu girin",
        enterRound: "Lütfen tur sayısını girin",
        p1: "Yani, kullanıcının değiştirebileceği iki değer vardır ve bu iki değer, işlemin / yuvarlanma noktasının sonucunu değiştirebilen işlemde yer alır. Örneğin: Kişisel tohum varsayılan 1. Yuvarlanma noktası sonucunu 10086 olarak değiştirdikten sonra manuel olarak 2'ye değiştirin, diğer parametreler değişmeden kalır, ardından 1 sonucunu doğrulamak için aracı kullanmaya gidin, sonucun 10086 olmaması durumunda tohumun 1 olduğunu göreceksiniz ",
        p2: "Örnekte, tohumu veya genel hash'i değiştirdikten sonra, önceki oyunun gizli hash'ini ve gizli tuzunu görebilir ve ardından önceki sonucun doğru olup olmadığını doğrulayabilirsiniz. Önceki değerleri almak ve olasılık dağılımının daha büyük turlar için (örneğin 10w kez) makul olup olmadığını ölçmek de mümkündür.",
        opnSrcAddr: "açık kaynak adresi",
        download: "Çevrimdışı Doğrulayıcıyı İndirin",
        curSeed: "Güncel Tohumlar",
        preSeed: "previousServerSeed",
        chg: "değişim",
        reset: "reprovision",
        oneClkCpy: "Tek tıkla kopyalama",
        validator: "Adalet Doğrulayıcı",
        attachment1: "(İstenildiği zaman değiştirilebilir)",
        attachment2: "(sıfırlanabilir)",
        attachment3: "(Oynanan oyun sayısı ile birlikte artar)",
        resetDisp: "(Genel hash sıfırlandıktan sonra görüntülenir)",
        legitimateSeed: "Lütfen yasal tohumları girin",
        entrANewSeed: "Yeni tohumlar girin",
        seedReg: "Üç karakter (veya daha fazla) İngilizce harf, rakam veya Çince karakter",
        oneClkPaste: "tek tıklamayla yapıştırma",
        rlPnt: "yuvarlanma noktası",
        waitVerification: "Doğrulama için bekliyorum"
    },
    privacyClause: {
        usrAgreement: "<p>Hizmetin kullanımıyla ilgili koşullar</p>" +
            "<p>Ebeveyninizin veya vasinizin Hizmetin kullanımına izin verip vermediğine bakılmaksızın, on sekiz (18) yaşın altındaki hiçbir birey Hizmeti kullanamaz. </p>" +
            "<p>Hizmete erişmek için desteklenen bir web tarayıcısına ihtiyacınız vardır. csgo8'in belirli web tarayıcılarını desteklemeyi bırakabileceğini ve Hizmeti kullanmaya devam etmek için desteklenen bir web tarayıcısı indirmeniz gerekeceğini kabul ve beyan edersiniz. Ayrıca, Hizmetin performansının bilgisayar ekipmanınızın ve İnternet bağlantınızın performansına bağlı olduğunu kabul ve beyan edersiniz. </p>" +
            "<p>Valve Corporation tarafından sağlanan bir Steam hesabı aracılığıyla Hizmet'e giriş yapmayı ve kaydolmayı kabul edersiniz. Hesabınızı ve şifrenizi yönetmek ve şifrenizin gizliliğini korumak yalnızca sizin sorumluluğunuzdadır. Ayrıca hesabınıza erişimi kısıtlamaktan da yalnızca siz sorumlusunuz. </p>" +
            "<p>Kendiniz veya başkaları tarafından hesabınız üzerinden veya şifreniz kullanılarak gerçekleştirilen tüm faaliyetlerin sorumluluğunu kabul etmektesiniz. Üçüncü bir tarafın şifrenize erişimi olduğunu düşünüyorsanız, lütfen mümkün olan en kısa sürede yeni bir şifre edinmek için Hizmetin şifre yeniden oluşturma özelliğini kullanın. Hiçbir koşul altında, herhangi bir üçüncü tarafın Hizmeti kullanmasına veya Hizmete erişmesine izin vermeyi kabul etmezsiniz. </p>" +
            "<p>Bu Koşullar metninde yer alan tüm terimler ve tanımlar, taraflarca Singapur yasalarına ve internette kullanılan ilgili terimlerin yorumlanması kurallarına uygun olarak yorumlanacaktır. </p>" +
            "<p>Hizmetleri kullanmanızın bir koşulu olarak, şunları yapmayacağınızı kabul edersiniz:</p>" +
            "<p>Herhangi bir kişi veya kuruluşla olan ilişkinizi taklit etmek veya yanlış beyan etmek;</p>" +
            "<p>Hizmetlerin veya csgo8.com'un bilgisayar sistemlerinin kamuya açık olmayan alanlarına erişmek, bunları tahrif etmek veya kullanmak;</p>" +
            "<p>Hizmetleri sağlamak için kullanılan herhangi bir yazılımı veya bileşeni deşifre etmeye, kaynak koda dönüştürmeye, parçalarına ayırmaya, tersine mühendislik yapmaya veya başka bir şekilde araştırmaya teşebbüs etmek;</p>" +
            "<p>Diğer kullanıcılara zarar vermek veya zarar verme tehdidinde bulunmak veya virüs gönderme, aşırı yükleme, flooding, spam gönderme veya Hizmetleri mail bombardımanına tutma dahil ancak bunlarla sınırlı olmamak üzere herhangi bir şekilde herhangi bir kullanıcıya, ana bilgisayara veya ağa erişimi engellemek veya engellemeye teşebbüs etmek;</p>" +
            "<p>Üçüncü taraflara ait ödeme bilgilerinin sağlanması;</p>" +
            "<p>Hizmetleri, kullanım amaçlarını, csgo8 politikalarını ve talimatlarını ve yürürlükteki yasaları ihlal edecek şekilde kötüye kullanmak;</p>" +
            "<p>Doğrudan veya dolaylı olarak, tekli veya çoklu indirmeler, koleksiyonlar, derlemeler, veritabanları, dizinler vb. oluşturmak veya derlemek için, manuel yöntemlerle, robotlar, tarayıcılar veya örümcekler kullanarak veya başka bir şekilde Hizmetlerden sistematik olarak veri veya diğer içerikleri almak;</p>" +
            "<p>Hizmetleri, Hizmetlerin çalışması için gerekli tesisleri ve teknolojiyi sağlayan üçüncü bir tarafın (G2A veya Valve gibi) hüküm ve koşullarını ihlal edecek şekilde kullanmak;</p>" +
            "<p>Hizmetin kullanımı veya Hizmete erişimle bağlantılı olarak üçüncü bir tarafın fikri mülkiyet haklarını ihlal etmek, Hizmetin kullanımı yoluyla sanal ürünlerin kullanıma sunulması dahil ancak bununla sınırlı olmamak üzere;</p>" +
            "<p>Yasadışı içerik ve pornografi ve csgo8'e saldırgan veya zarar verici olduğu düşünülen içerik ve/veya hizmetler (örneğin Warez sitesi, IRC botları ve Bittorent sitesi) dahil ancak bunlarla sınırlı olmamak üzere, csgo8'in kendi takdirine bağlı olarak saldırgan veya csgo8'in itibarına zarar verici olduğunu düşündüğü materyalleri kullanmak, reklamını yapmak, bunlara bağlantı vermek veya bunlara erişim sağlamak. </p>" +
            "<p>Sonlandırma</p>" +
            "<p>Hizmetlerimize erişimi, bu Şartları ihlal etmeniz dahil ancak bununla sınırlı olmamak üzere herhangi bir nedenle bildirimde bulunmaksızın veya yükümlülük altına girmeksizin derhal sonlandırabilir veya askıya alabiliriz. </p>" +
            "<p>Mülkiyet Hakları Maddesi, garanti feragatnameleri, tazminatlar ve sorumluluk sınırlamaları dahil ancak bunlarla sınırlı olmamak üzere, bu Koşulların doğası gereği fesihten sonra da geçerli olması gereken tüm hükümleri fesihten sonra da geçerli olacaktır. </p>" +
            "<p>Bu Koşulları ihlal etmeniz dahil ancak bununla sınırlı olmamak üzere herhangi bir nedenle hesabınızı bildirimde bulunmaksızın veya yükümlülük altına girmeksizin derhal feshedebilir veya askıya alabiliriz. </p>" +
            "<p>Fesih üzerine, Hizmetleri kullanma hakkınız derhal sona erecektir. Hesabınızı sonlandırmak isterseniz, Hizmeti kullanmayı bırakabilirsiniz. </p>" +
            "<p>Açık Kasa Kuralları</p>" +
            "<p>Bir dava açarak, sayfada gösterilen oyun öğelerinden birini ve davada yer alan öğelerin açıklamasını almayı kabul edersiniz. Öğenin alınması otomatik olarak belirlenir. </p>" +
            "<p>1. Oyun öğelerinin alınması</p>" +
            "<p>1.1. Bir Oyun Öğesini kabul ederek, Sandık fiyatının (içerdiği Oyun Öğeleri dahil) beklentilerinizi karşıladığını kabul eder ve Site/Hizmet veya Şirketten herhangi bir ek tazminat alma hakkınız olmadığını onaylarsınız. </p>" +
            "<p>1.2. Bir oyun öğesi koruyucu kılıf kullanılarak alındıktan ve kişisel hesapta görüntülendikten sonra, kullanıcı oyun öğesini Steam hesabına getirebilir</p>" +
            "<p>1.3. Steam Hesabındaki Oyun Öğelerinin kabulü, Kullanıcının Web Sitesinde ve Kişisel Hesapta belirtilen Steam ve Kişisel Hesap ayarlarını yerine getirmesine bağlıdır. </p>" +
            "<p>Kumar Yasağı csgo8.com'u herhangi bir yasa veya yönetmeliği (yasadışı kumarı yasaklayan yasa veya yönetmelikler dahil) ihlal edecek şekilde kullanmak yasaktır Satın alınan bir oyun öğesi yalnızca kişisel amaçlar için kullanılabilir ve herhangi bir ticari kazanç için kullanılamaz. CS:GO oynamak gibi amacına uygun olarak kullanılmalıdır.Skinleri Steam Hizmetleri Abonelik Sözleşmesi'ne uygun olarak kullanmalısınız. csgo8.com'dan aldığınız skinleri üçüncü taraf pazarlarda satamazsınız veya bu skinleri değerli bir şeyle takas edemezsiniz. </p>" +
            "<p>2. Oyun Öğelerinin Reddedilmesi</p>" +
            "<p>2.1.Kullanıcılar, bir kutuyu açarken oyun öğelerini reddedebilir ve gelecekte bir kutu için ödeme yaparken kişisel hesaplarında öğe açıklamasında belirtilen miktarda indirim sağlamak için bonus puanlar için iade edebilirler. </p>" +
            "<p>Diğer sitelere bağlantılar</p>" +
            "<p>Hizmetimiz, csgo8'e ait olmayan veya csgo8 tarafından kontrol edilmeyen üçüncü taraf web sitelerine veya hizmetlerine bağlantılar içerebilir. </p>" +
            "<p>csgo8, herhangi bir üçüncü taraf web sitesinin veya hizmetinin içeriği, gizlilik politikaları veya uygulamaları üzerinde hiçbir kontrole sahip değildir ve hiçbir sorumluluk kabul etmez. Ayrıca, csgo8'in bu tür içerik, mal veya hizmetlerin kullanımından veya bunlara güvenilmesinden kaynaklanan veya bunlarla bağlantılı olduğu iddia edilen herhangi bir hasar veya kayıptan doğrudan veya dolaylı olarak sorumlu veya yükümlü olmayacağını kabul ve beyan edersiniz. bu tür herhangi bir site veya hizmet. </p>" +
            "<p>Ziyaret ettiğiniz tüm üçüncü taraf web sitelerinin veya hizmetlerinin hüküm ve koşullarını ve gizlilik politikalarını okumanızı şiddetle tavsiye ederiz. </p>" +
            "<p>Garanti Yok</p>" +
            "<p>Bu site, açık veya zımni herhangi bir beyan veya garanti olmaksızın olduğu gibi sağlanmaktadır.www.csgo8.com Bu site veya bu sitede sağlanan bilgi ve materyallerle ilgili olarak hiçbir beyan veya garanti verilmemektedir." +
            "Yukarıdaki paragrafların genelliği saklı kalmak kaydıyla.www.csgo8.com Bu sitenin sürekli olarak kullanılabilir veya tam olarak kullanılabilir olacağı; veya bu sitedeki bilgilerin eksiksiz, gerçek, doğru veya yanıltıcı olmadığı. Bu Sitedeki hiçbir şey herhangi bir tavsiye niteliği taşımaz veya taşıması amaçlanmamıştır. Herhangi bir yasal, mali veya tıbbi konuda tavsiyeye ihtiyacınız varsa, uygun bir uzmana danışmalısınız.</p>" +
            "<p>Hesap Güvenliği</p>" +
            "<p>Bu site Steam OpenID kullanmaktadır, bu da Steam hesabınızla giriş yaptığınız anlamına gelmektedir. </p>" +
            "<p>Steam hesabınızı veya giriş bilgilerinizi paylaşamazsınız, başka bir kişiye Steam hesabınıza erişim izni veremezsiniz veya Steam hesabınızın güvenliğini tehlikeye atabilecek herhangi bir eylemde bulunamazsınız. </p>" +
            "<p>Eğer Steam giriş bilgilerinin kaybı, çalınması veya yetkisiz ifşası dahil ancak bunlarla sınırlı olmamak üzere herhangi bir güvenlik ihlalinin farkına varırsanız veya bundan şüphelenmek için bir nedeniniz varsa, derhal Steam Destek (https://help.steampowered.com/) ile iletişime geçmelisiniz. </p>" +
            "<p>Steam hesap bilgilerinizin gizliliğini korumak yalnızca sizin sorumluluğunuzdadır ve sizin tarafınızdan yetkilendirilmiş olsun veya olmasın Steam giriş bilgilerinin tüm kullanımlarından (satın almalar dahil) siz sorumlu olacaksınız. </p>" +
            "<p>Steam hesabınız üzerinden gerçekleşen her şeyden siz sorumlusunuz. </p>" +
            "<p>İletişim</p>" +
            "<p>Valve Corporation, Counter-Strike: Global Offensive, Steam veya Valve Corporation'ın diğer ticari markaları ile hiçbir şekilde bağlantılı değiliz veya bunları desteklemiyoruz. </p>" +
            "<p>İade ve Geri Ödeme Politikası</p>" +
            "<p>csgo8.com Cüzdan csgo8.com hesap cüzdan tutarınızı yeniden yükleyerek, alınan puanların (IS logosu ile etiketlenmiş) gerçek para değerinde olmadığını ve herhangi bir geri ödeme almayacağınızı kabul etmiş olursunuz.</p>" +
            "<p>Sipariş onaylandıktan ve ürün gönderildikten sonra dijital ürünler için geri ödeme yapmıyoruz. Ürünlerimizi alırken veya indirirken herhangi bir sorun yaşarsanız, yardım için bizimle iletişime geçmenizi öneririz. </p>" +
            "<p>Force Majeure</p>" +
            "<p>csgo8'in makul kontrolü dışındaki nedenlerden kaynaklanan herhangi bir gecikme veya başarısızlıktan sorumlu olmayacağız. </p>" +
            "<p>Bu, öngörülemeyen koşullar veya kontrolümüz dışındaki nedenlerden (örneğin, doğal afetler, savaş, terörizm, ayaklanmalar, ambargolar, sivil veya askeri makamların eylemleri, yangınlar, seller, kazalar, grevler veya kıtlıklar) dolayı tesislerin, yakıtın, enerjinin, işçiliğin veya malzemelerin taşınması için bu Sözleşmenin yerine getirilememesini içerir, ancak bunlarla sınırlı değildir. </p>" +
            "<p>Sözleşme Değişikliği</p>" +
            "<p>Bu Sözleşmenin şartlarını tek taraflı olarak değiştirme hakkımız saklıdır ve bu tür değişiklikler, Sözleşmenin yeni bir sürümü internette https://csgo8.com/terms adresinde yayınlandığında geçerli olacaktır</p>" +
            "<p>Site'nin özelliklerini kullanmaya devam etmeniz, Sözleşme'nin yeni versiyonunun şartlarını kabul ettiğiniz anlamına gelecektir. Sözleşmenin yeni versiyonunun şartlarını kabul etmiyorsanız, Siteyi kullanmayı bırakmalısınız. </p>" +
            "<p>Telif hakkı ve diğer gayri maddi haklar</p>" +
            "<p>Site Steam (Valve Corporation'ın tescilli ticari markası) tarafından desteklenmektedir. </p>" +
            "<p>Valve, Valve logosu, Steam logosu, Counter-Strike ve Counter-Strike logosu Valve Corporation'ın ticari markaları ve/veya tescilli ticari markalarıdır. </p>" +
            "<p>Diğer tüm ticari markalar ilgili sahiplerinin mülkiyetindedir. Sitedeki hiçbir gayri maddi hak veya diğer materyaller Kullanıcıya devredilmez. </p>" +
            "<p>Apple, bu web sitesindeki/uygulamadaki hiçbir etkinliğin sponsoru değildir ve etkinliklere hiçbir şekilde dahil değildir. </p>" +
            "<p>Sevkiyat/Teslimat Politikası</p>" +
            "<p>1. csgo8 web sitesinden aldığınız Skinler, Skin Öğesi Envanterinizde görünecektir csgo8 Envanter durumu 4 kategoriye ayrılmıştır, bunlar (Stokta, Teslim Al, Stoğa İade Edildi, Teslim Al)</p>" +
            "<p>Eşya Envanteri: kutudan çıkarırken edindiğiniz demonte edilmemiş kaplamaları görüntüler, bunları demonte edebilir veya almayı talep edebilirsiniz;</p>" +
            "<p>Teslim Alma: gönderdiğiniz ve site yöneticisinin henüz gönderiyi işleme koymadığını veya stokta kalmaması veya diğer nedenlerden dolayı gönderiyi henüz koordine etmediğini temsil eder, bu durum sabırla beklemenizi gerektirir. </p>" +
            "<p>Depoya geri dönüş: aşağıdaki nedenlerden kaynaklanabilir: buhar durumunuz anormaldir ve işlem kısıtlamalarına neden olur; veya bağlantı hatası veya pazar stokta olmadığı için teslim alma talebinizi işleme koyamaz; veya sırt çantası öğeleriniz 7 gün boyunca alınmadığından ve fiyat çok fazla dalgalandığından, teslim alma ihtiyaçlarınızı tamamlayamamanıza neden olur (bu nedenle, teslim alma işleminiz için sorun yaratmamak için lütfen bibloları zamanında alın). </p>" +
            "<p>Toplandı: gönderinizin incelendiği ve bu siteden gönderildiği anlamına gelir, bu durum ekipmanınızı toplamak için zamanında steam platformuna gitmenizi gerektirir. </p>" +
            "<p>2. Teslim almadan önce hazırlık</p>" +
            "<p>Eşyalarınızı almadan önce, Steam ticaret teklifi bağlantınızı kurmak için lütfen profil sayfanıza gidin. Bununla birlikte, steam politika nedenleriyle Çinli oyuncular için açılamayabileceğinden, lütfen test etmek için UU web sitesi hızlandırıcısını da kullanın. </p>" +
            "<p>3. Nakliye süresi</p>" +
            "<p>Normal şartlar altında, cilt fiyat dalgalanmaları, envanter ve diğer özel durumlar nedeniyle 1 dakika içinde bir teslim alma bildirimi alacaksınız, maksimum teslim alma süresi 18 saattir, lütfen sabırlı olun. Başka sorularınız varsa, lütfen resmi müşteri hizmetleri ile iletişime geçmekten çekinmeyin (10:00-22:00)</p>" +
            "<p>Bilgi</p>" +
            "<p>csgo8.com, web sitesinin yöneticisi olarak, kullanıcının hak ve yükümlülüklerini belirleyen ve taraflar arasında yasal olarak bağlayıcı bir anlaşma teşkil eden bu kullanım koşullarını kabul eder. Bu Kullanım Koşulları, Kullanıcının haklarını etkiler ve Sitenin kullanımıyla bağlantılı olarak belirli yükümlülükler getirir ve bu nedenle Kullanıcı tarafından dikkatlice okunmalıdır. </p>" +
            "<p>Site bilgileri: csgo8.com</p>" +
            "<p>E-posta: --------------</p>" +
            "<p>Address: ---------------</p>",
        privacyAgreement: "<p>Gizlilik Politikası Şartları</p>" +
            "<p>csgo8, bu Gizlilik Politikasını zaman zaman değiştirebilir ve değişiklikler, revize edilmiş Gizlilik Politikası Uygulamada mevcut olduğunda geçerli olacaktır.son güncelleme Tarih, bu politikanın alt kısmında yer almaktadır. Bu nedenle, kişisel bilgilerinizi her gönderdiğinizde lütfen bu Gizlilik Politikasına bakın.</p>" +
            "<p>Veri Denetleyicisi ve Sahibi</p>" +
            "<p>csgo8.com</p>" +
            "<p>İletişim: müşteri hizmetleri</p>" +
            "<p>Toplanan veri türleri</p>" +
            "<p>Uygulama tarafından kendi başına veya üçüncü taraflar aracılığıyla toplanan kişisel veri türleri şunları içerir: çerezler ve kullanım verileri. </p>" +
            "<p>Toplanan diğer kişisel veriler, bu Gizlilik Politikasının diğer bölümlerinde veya veri toplama ile ilgili özel açıklayıcı metinler aracılığıyla açıklanabilir. </p>" +
            "<p>Kişisel veriler Kullanıcı tarafından serbestçe sağlanabilir veya Uygulama kullanılırken otomatik olarak toplanabilir. </p>" +
            "<p>Aksi belirtilmedikçe, Uygulama'nın sahipleri veya Uygulama tarafından kullanılan üçüncü taraf hizmetleri tarafından çerezlerin veya diğer izleme araçlarının herhangi bir şekilde kullanılması, yalnızca Kullanıcı'ya hizmet sağlamak amacıyla Kullanıcı'yı tanımlamak ve tercihlerini hatırlamak için kullanılır. </p>" +
            "<p>Belirli kişisel verilerin sağlanmaması, Uygulamanın Hizmetleri sağlayamamasına neden olabilir. </p>" +
            "<p>Kullanıcılar, Uygulama aracılığıyla elde edilen, yayınlanan veya paylaşılan tüm üçüncü taraf kişisel verilerinden sorumludur ve verileri sahibine sağlamak için üçüncü tarafın onayına sahip olduklarını kabul ederler. </p>" +
            "<p>Verilerin nasıl ve nerede işlendiği</p>" +
            "<p>İşleme yöntemleri</p>" +
            "<p>Veri sorumlusu, kullanıcının verilerini uygun bir şekilde işler ve verilere yetkisiz erişimi, ifşayı, değiştirmeyi veya yetkisiz imhayı önlemek için uygun güvenlik önlemlerini alır. </p>" + "<p>Veri işleme, bilgisayarlar ve/veya BT destekli araçlar kullanılarak, belirtilen amaçla kesinlikle ilgili olan organizasyonel prosedürler ve kalıplar izlenerek gerçekleştirilir. Veri denetleyicisine ek olarak, bazı durumlarda, verilere sitenin işleyişine dahil olan belirli sorumlu kişiler (yönetim, satış, pazarlama, hukuk, sistem yönetimi) veya harici taraflar (örneğin üçüncü taraflar) tarafından erişilebilir. Gerekirse, sahibi veri işleyici olarak belirlenir.Veri sorumlusu, bu tarafların güncellenmiş bir listesini istediği zaman veri sorumlusundan talep edebilir. </p>" +
            "<p>Yer</p>" + "<p>Veriler, Veri Kontrolörünün operasyonel ofislerinde ve işlemeye dahil olan tarafların bulunduğu diğer herhangi bir yerde işlenir.Daha fazla bilgi için lütfen Veri Denetleyicisi ile iletişime geçin. </p>" +
            "<p>Kalıcılık süresi</p>" + "<p>Veriler, Kullanıcı tarafından talep edilen hizmetleri sağlamak için gerekli olduğu veya bu belgede belirtilen amaçlar için belirtildiği sürece saklanacaktır ve Kullanıcı her zaman Veri Denetleyicisinden verileri askıya almasını veya silmesini talep edebilir.Her durumda, veriler Kullanıcının Web Sitesindeki en son faaliyetinden sonra 5 yıldan daha uzun süre saklanmayacaktır. </p>" +
            "<p>Bilgilerinizi nasıl koruyoruz? </p>" + "<p>Gizliliğinizi korumayı taahhüt ediyoruz ve kişisel verilerin işlenmesi ve iletilmesine ilişkin çeşitli güvenlik önlemleri uyguladık. Bununla birlikte, internetin doğası gereği veriler bazı durumlarda tam güvenlik önlemleri olmadan ağ üzerinden akabilir ve yetkisiz kişiler tarafından erişilebilir. </p>" +
            "<p>Bilgilerinizi nasıl kullanıyoruz? </p>" +
            "<p>Kişisel bilgilerinizi meşru menfaatlerimiz için, sorunuza yanıt vermek için, size bilgi göndermek için, size karşı herhangi bir sözleşme yükümlülüğünü yerine getirmek için, araştırma amacıyla, size ürünlerimiz, hizmetlerimiz ve ilgili fırsatlar hakkında pazarlama bilgileri göndermek için ve açık onayınızı verdiğiniz diğer makul amaçlar için kullanacağız. </p>" +
            "<p>Bizden mal veya hizmet satın alırsanız, size posta, anlık bildirim veya e-posta yoluyla satın alma işleminizle ilgili daha fazla bilgi gönderebiliriz. </p>" +
            "<p>Size anlık bildirimler ve e-postalar aracılığıyla pazarlama bilgileri göndermek istiyoruz. </p>" +
            "<p>Yukarıdaki bilgilerden herhangi birini almak istemiyorsanız veya yukarıda açıklandığı gibi sizinle iletişime geçmemizi istemiyorsanız, müşteri hizmetlerimizden isteyebilirsiniz. </p>" +
            "<p>Yorumlarınızı iletişim formumuz aracılığıyla bize gönderirseniz, bunları eğitim ve idari amaçlarla şirket içinde dağıtabiliriz. </p>" +
            "<p>Doğruluğunu ve silinip silinemeyeceğini belirlemek için topladığımız kişisel bilgileri düzenli olarak gözden geçiriyoruz. </p>" +
            "<p>Bize gönderdiğiniz bilgileri diğer verilerle bir araya getirebilir (böylece bu verilerden kimliğiniz belirlenemez) ve bu birleştirilmiş verileri idari amaçlarla kullanabiliriz. </p>" +
            "<p>Bildirimleri Pushla</p>" +
            "<p>Uygulama kullanıcılara anlık bildirimler gönderebilir. </p>" + "<p>Analiz</p>" +
            "<p>Bu bölümde yer alan hizmetler, sahibinin ağ trafiğini izlemesini ve analiz etmesini sağlar ve kullanıcı davranışını izlemek için kullanılabilir.</p>" +
            "<p>Google Analytics (Google Inc)</p>" +
            "<p>Google Analytics, Google Inc. (Google) tarafından sağlanan bir web analiz hizmetidir. Google, toplanan verileri uygulamanın kullanımını takip etmek ve incelemek, faaliyetleri hakkında raporlar hazırlamak ve diğer Google hizmetleri ile paylaşmak için kullanır. </p>" +
            "<p>Google, toplanan verileri kendi reklam ağındaki reklamları bağlamsallaştırmak ve kişiselleştirmek için kullanabilir.</p>" + "<p>Toplanan kişisel veriler: çerezler ve kullanım verileri. </p>" +
            "<p>Google AdWords dönüşüm izleme (Google Inc.)</p>" +
            "<p>Google AdWords Dönüşüm İzleme, Google Inc. tarafından sağlanan ve Google AdWords reklam ağındaki verileri bu uygulamada gerçekleştirilen eylemlere bağlayan bir analiz hizmetidir. </p>" +
            "<p>Toplanan kişisel veriler: çerezler ve kullanım verileri. </p>" +
            "<p>Twitter reklam dönüşüm takibi (Twitter, Inc.)</p>" +
            "<p>Twitter Reklamları Dönüşüm Takibi, Twitter reklam ağından alınan verileri bu uygulamada gerçekleştirilen eylemlerle ilişkilendiren ve Twitter, Inc. tarafından sağlanan bir analiz hizmetidir. </p>" +
            "<p>Toplanan kişisel veriler: çerezler ve kullanım verileri. </p>" +
            "<p>Kullanıcının hakları</p>" +
            "<p>Kullanıcı, sahibi tarafından işlenen veriler üzerinde belirli hakları kullanabilir. </p>" +
            "<p>Özellikle, kullanıcı aşağıdaki eylemleri gerçekleştirme hakkına sahiptir:</p>" + "<p>Rızalarını istedikleri zaman geri çekebilirler. Kullanıcılar, daha önce vermiş oldukları kişisel verilerinin işlenmesine ilişkin onaylarını geri çekme hakkına sahiptir.Verilerinin işlenmesine itiraz etme.Kullanıcılar, işlemenin rıza temelinde değil de yasal bir temelde gerçekleştirilmesi halinde verilerinin işlenmesine itiraz etme hakkına sahiptir. Daha ayrıntılı bilgi aşağıdaki özel bölümde verilmektedir.Verilerine erişim. Kullanıcılar, verilerin veri sahibi tarafından işlenip işlenmediğini öğrenme, işlemenin belirli yönleri hakkında açıklama alma ve işlenen verilerin bir kopyasını alma hakkına sahiptir.Doğrulama ve düzeltme talep etme. Kullanıcılar verilerinin doğruluğunu teyit etme ve güncelleme veya düzeltme talep etme hakkına sahiptir. Verilerinin işlenmesini kısıtlama. Belirli durumlarda, kullanıcılar verilerinin işlenmesini kısıtlama hakkına sahiptir. Bu durumda, sahibi verilerini depolama dışında herhangi bir amaç için işlemeyecektir. Kişisel verilerinin silinmesi veya başka bir şekilde silinmesi. Bazı durumlarda, kullanıcılar veri sahibinden verilerini silmesini talep etme hakkına sahiptir. Verilerini alma ve başka bir kontrolöre aktarma. Kullanıcılar, verilerini yapılandırılmış, yaygın olarak kullanılan ve makine tarafından okunabilir bir formatta alma ve teknik olarak mümkün olduğunda, engeller olmaksızın başka bir kontrolöre iletme hakkına sahiptir. Bu, verilerin otomatik yollarla işlenmesi ve işlemenin kullanıcının rızasına, kullanıcı tarafından akdedilen bir sözleşmeye veya sözleşme öncesi bir yükümlülüğe dayanması halinde geçerlidir. Şikayette bulunma. Kullanıcı, kendisinden sorumlu veri koruma yetkilisine bir talepte bulunma hakkına sahiptir. </p>" +
            "<p>Tanımlar ve yasal referanslar</p>" +
            "<p>Kişisel veriler (veya veriler)</p>" +
            "<p>Doğrudan, dolaylı olarak veya diğer bilgilerle ilişkili olarak bir gerçek kişiyi tanımlayan veya kimliğini belirleyen her türlü bilgi. Bu tür bilgiler adları, adresleri, pasaport veya kimlik kartı verilerini, kişisel kimlik numaralarını ve birlikte bir bireyi tanımlayabilecek diğer verileri içerebilir. </p>" +
            "<p>Verileri kullan</p>" +
            "<p>Site aracılığıyla otomatik olarak toplanan bilgiler, şunları içerebilir: Siteyi kullanan kullanıcı tarafından kullanılan bilgisayarın IP adresi veya alan adı, URI adresi (Tekdüzen Kaynak Tanımlayıcısı), talebin zamanı, talebi yapmak için kullanılan sunucuya talebin gönderilme yöntemi, yanıtta alınan dosyanın boyutu, sunucunun yanıtının durumunu gösteren sayısal kod (başarılı sonuç, hata vb.), menşe ülke, tarayıcının işlevselliği ve kullanıcı tarafından kullanılan işletim sistemi. işlevselliği ve kullanılan işletim sisteminin kullanıcısı, her ziyaretin çeşitli zamanlarına ilişkin ayrıntılar (örneğin, Site içindeki her sayfada geçirilen süre) ve Site içinde izlenen yollara ilişkin ayrıntılar, özellikle sayfalara erişilme sırası ve ayrıca cihazın işletim sistemi ve/veya kullanıcının BT ortamına ilişkin diğer parametreler. </p>" +
            "<p>Kullanıcı</p>" +
            "<p>Aksi belirtilmedikçe, bu siteyi kullanan kişiler veri sahipleriyle aynıdır. </p>" +
            "<p>Veri Konusu</p>" +
            "<p>Kişisel verilerin atıfta bulunduğu gerçek kişiler. </p>" +
            "<p>Veri işleyen (veya veri yöneticisi)</p>" + "<p>Bu Gizlilik Politikasında açıklandığı üzere, kontrolör adına kişisel verileri işleyen gerçek veya tüzel kişi, kamu otoritesi, ajans veya diğer organ.</p>" +
            "<p>Veri sorumlusu (veya sahibi)</p>" +
            "<p>Sitenin işletilmesi ve kullanımıyla ilgili güvenlik önlemleri de dahil olmak üzere kişisel verilerin işlenme amaçlarını ve şeklini tek başına veya başkalarıyla birlikte belirleyen gerçek veya tüzel kişi, kamu kurumu, ajans veya diğer organ.Aksi belirtilmedikçe, veri sorumlusu bu sitenin sahibidir.</p>" +
            "<p>Bu uygulama</p>" +
            "<p>Kullanıcının kişisel verilerinin toplanma ve işlenme şekli.</p>" +
            "<p>Hizmetler</p>" +
            "<p>Bu site tarafından sağlanan hizmetler, ilgili şartlarda (varsa) ve bu sitede açıklandığı gibidir. </p>" +
            "<p>AB (veya Avrupa Birliği)</p>" +
            "<p>Aksi belirtilmedikçe, bu belgede AB'ye yapılan tüm atıflar AB ve AEA'nın mevcut tüm üye ülkelerini kapsamaktadır. </p>" +
            "<p>Çerezler</p>" +
            "<p>Kullanıcının cihazında depolanan küçük veriler. </p>" +
            "<p>İletişim: çevrimiçi müşteri hizmetleri</p>" +
            "<p>E-posta: -------------------</p>" +
            "<p>Son güncelleme: 27 Ekim 2023</p>",
        aboutUs: "<p>Hakkımızda</p>" +
            "<p> csgo8.com profesyonel ve adil bir CS: GO skin unboxing web sitesidir, csgo8 web sitesinde hemen daha fazla csgo skin alabilirsiniz, kutudan çıkardıktan sonra skinler istediğiniz zaman Steam envanterine konulabilir. birçok oyuncu tarafından sevilen benzersiz bir satın alma modu kullanarak oyunculara skin elde etmenin daha ilginç yollarını sunmayı taahhüt eder! csgo8, birçok oyuncu tarafından sevilen benzersiz bir edinme modeli kullanarak oyunculara görünüm elde etmenin daha ilginç yollarını sunmaya kendini adamıştır. </p>" +
            "<p>Vakalara Giriş</p>" + "<p>csgo8 açık kutusu kesinlikle CS: GO cildini düşürecek, cilt tamamen rastgele düşecek, şirketimiz kullanıcılara Steam oyunu sanal cilt çıkarma hizmetleri sunmak için profesyonellik ve adalet ilkesine bağlı kalacaktır. Özel hatırlatma: tüketimin rasyonel, eğlence odaklı olması gerekir.</p>" +
            "<p>Kutu kuralları</p>" + "<p>Kullanıcılar, kutuyu açmak için farklı kutu türlerine göre karşılık gelen miktarda $ harcayabilir ($, oyuncunun elde etmek için yeniden şarj edilmesidir, para çekme işlemlerini yasaklar); kullanıcının rastgele elde etmesi için kutu içindeki cilt, cilt çizelgelerinde cilt düşme olasılığı kamuya açıklanır, bu platformun kutusu tarafından web sitesinin belirli çizelgelerine elde edilebilecek cilt aralığı geçerli olacaktır. Oyuncuların ilgili yasa ve yönetmeliklere uymasını, rasyonel tüketimi, medeni açık kutuyu korumasını umuyoruz.</p>" + "<p>Resmi site adresi: www.csgo8.com</p>" +
            "<p>İletişim bilgileri: çevrimiçi müşteri hizmetleri</p>" +
            "<p>Şirket adı: ------------</p>" +
            "<p>Şirket adresi: --------------</p>" +
            "<p>E-posta: ------------</p>",
        questions: "<h2>Steam Cüzdan fonlarını csgo8 kılıfları satın almak için kullanabilir miyim? </h2>" +
            "<p>Steam bakiyeleri bu site için geçerli değildir. Bakiyenizi doğrudan csgo8 web sitesinden yüklemeniz gerekecektir. </p>" + "<h2>Bakiyeme para yükledim ancak para profilime aktarılmadı. Ne yapmam gerekiyor?</h2>" + "<p>Ödemeniz 5-10 dakika gecikebilir. Para bir saat içinde aktarılmazsa, ödeme ayrıntılarını csgo8 desteğine yazmak için lütfen destek ekibiyle iletişime geçin.</p>" +
            "<h2>Çekim işleminiz 30 günden uzun süredir beklemede</h2>" + "<p>30 gün içinde alınmayan biblolar otomatik olarak eşdeğer değere dönüştürülecek ve hesabınıza bakiye olarak iade edilecektir. </p>" +
            "<h2>Ölü bir açılır pencere nedeniyle bibloyu satamadım! </h2>" +
            "<p>Pencereyi kapatın ve sayfayı yenileyin. Sorun devam ederse, tarayıcı önbelleğinizi temizleyin ve tekrar deneyin. </p>" +
            "<h2>Bir kutu satın almadan önce</h2>" +
            "<p>Steam hesabınızın pazar yeri ticaret izinlerinin kısıtlanmadığını kontrol edin, aksi takdirde botlarımız hesabınıza dış görünüm gönderemez. Steam hesabınıza giriş yapın ve Marketplace'i açarak Marketplace ticaret erişiminin çalıştığından ve şifrenizin 7 günden daha uzun süredir güncellendiğinden emin olun</p>",
        ruleDream: "<p>1. Her rüya, belirli bir değer elde etmek için rastgele bir işlev aracılığıyla bağımsız olarak hesaplanır ve mücevherin mevcut değeri, karşılaştırma için işgal edilen aralığın aralığının yüzdesini kontrol etmek için oyuncu ile çarpılır, eğer rastgele değer ve sahne alanındaki mücevherin değeri, mücevheri alırsınız, değilse, rastgele bir teselli mücevheri alırsınız. </p>" +
            "<p>2. Her rüya kapanı bağımsız olarak hesaplandığından, rüya kapanı mücevherini 3 kez üst üste %30 olasılıkla açtığınızda, mücevheri alma şansınızın %90 olduğu anlamına gelmez. Mücevheri arka arkaya 3 kez %30 olasılıkla düşürmeyi başaramadığınızda, dördüncü kez %10 olasılıkla açmanız mücevheri alma şansınızın %100 olduğu anlamına gelmez ve önceki %30 olasılıkla mücevheri alma şansınıza kıyasla daha yüksek bir başarısızlık şansıyla karşılaşabilirsiniz. </p>",

    }
}
