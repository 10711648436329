<!--
时间:2023/9/26/026
用法:消息确认弹窗
-->

<template>
  <div class='Confirm mask' v-if="show">
    <div class="confirmBody">
      <ConfirmClose close-class="close" @click="cancelCk"/>
      <div class="content">

        <p v-if="msg">{{ msg.text || msg }}</p>
        <div v-if="msg.money" class="moneyBox">
          <span>{{ i18n.global.t(`confirm.common`) }}</span>
          <ConfirmMoney :money="msg.money"/><span v-if="msg.give > 0"  class="giveS">+{{msg.give}}</span>
        </div>
        <slot>

        </slot>
      </div>

      <div class="btnBox" v-if="isBtn">
        <div class="cancel btn _btnClick1" @click="cancelCk">
          {{ btn.cancelText }}
        </div>
        <div class="confirm btn _btnClick1" @click="confirmCk">
          {{ btn.confirmText }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ConfirmClose from "@/components/IconComponents/CloseIcon.vue";
import ConfirmMoney from "@/components/IconComponents/MoneyCoin.vue";
import i18n from "@/i18n";
export default {
  name: "Confirm",
  components: {ConfirmClose, ConfirmMoney},
  props: {
    btn: {
      type: Object,
      default: {
        cancelText: i18n.global.t(`confirm.cancel`),
        confirmText: i18n.global.t(`confirm.confirm`)
      }
    },
    msg: {
      type: [Object, String],
      default: ''
    },
    close: {
      type: Function,
      default: () => {
      }
    },
    cancel: {
      type: Function,
      default: () => {
      }
    },
    confirm: {
      type: Function,
      default: () => {
      }
    },
    isBtn:{
      type:Boolean,
      default:true
    }
  },
  data() {
    return {
      show: true,
      i18n:i18n
    }
  },
  methods: {
    cancelCk() {
      this.cancel();
      this.closePopup();
    },
    confirmCk() {
      this.confirm();
      this.closePopup();
    },
    closePopup() {
      this.show = false
      setTimeout(() => {
        this.close()
      }, 500)
    }
  }
}
</script>

<style scoped lang="scss">
.mask{
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  background-color: rgba(0, 0, 0, 0.6)
}
.confirmBody {
  width: 19.81rem;
  background: #191B29 url("@/assets/home/loginbg.jpg") no-repeat center / cover;
  border-radius: 0.42rem;
  padding: 1.5rem 0.5rem 3.5rem;
  box-sizing: border-box;
  font-size: 0.83rem;
  @include center;
  @include p-ab-center;


  .content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 1rem;
    .moneyBox {
      @include center;

      span {
        margin-right: 0.33rem;
      }
      .giveS{
        margin-right: 0.33rem;
        color: #FBC300;
      }
    }

    p {
      margin-bottom: 1.2rem;
    }
  }

  .btnBox {
    display: flex;
    align-items: center;
    position: absolute;
    bottom: 1rem;
    left: 50%;
    transform: translateX(-50%);
    font-size: 0.67rem;

    .btn {
      width: 6.21rem;
      height: 1.91rem;
      border-radius: 0.96rem;
      @include center;
    }

    .cancel {
      background: rgba(255, 255, 255, 0.1);
      font-weight: bold;
      color: #A9ABBC;
      margin-right: 0.42rem;
    }

    .confirm {
      background: #FBC300;
      font-weight: bold;
      color: #191B29;
    }
  }
}

@media screen and (max-width: $windowSize-md) {
  .confirmBody {
    width: 26.46rem;
    background: #191B29 url("@/assets/home/loginbg.jpg") no-repeat center / cover;
    border-radius: 0.42rem;
    font-size: 1.25rem;
    padding: 3rem 0.5rem 5rem;
    .content {
      margin-bottom: 2rem;
      .moneyBox {
        @include center;

        span {
          margin-right: 0.33rem;
        }
      }

      p {
        margin-bottom: 1rem;
      }
    }
    .btnBox {
      display: flex;
      align-items: center;
      position: absolute;
      bottom: 1rem;
      left: 50%;
      transform: translateX(-50%);
      font-size: 1.17rem;
      font-weight: bold;

      .btn {
        width: 8.1rem;
        height: 3.32rem;
        border-radius: 1.66rem;
        @include center;
      }

      .cancel {
        background: rgba(255, 255, 255, 0.1);
        font-weight: bold;
        color: #A9ABBC;
        margin-right: 0.42rem;
      }

      .confirm {
        background: #FBC300;
        font-weight: bold;
        color: #191B29;
      }
    }
  }
}

.close {
  opacity: 0.5;
  @media screen and (max-width: $windowSize-md) {
    width: 1.5rem;
    height: 1.19rem;
  }
}
</style>
