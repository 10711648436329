<!--
时间:2023/11/1/001
用法:
-->

<template>
  <transition>
    <div class="mask animate__animated" v-show="show" :class="{'show':show,'hidde':!show}">
      <transition name="fadeScale">
        <div class="popupContent" v-show="show">
          <img class="close"
               @click="close"
               src="@/assets/icon/close.png" alt="">
          <slot>

          </slot>
        </div>
      </transition>
    </div>
  </transition>

</template>

<script>

export default {
  name: "PopupTemplate",
  props: {
    show: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {}
  },
  methods: {
    close() {
      this.$emit('close')
    },

  }
}
</script>

<style scoped lang="scss">
.mask {
  width: 100vw;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  background-color: rgba(0, 0, 0, 0.6);
  @include center;
}

.popupContent {
  position: relative;
  background: url('@/assets/home/loginbg.jpg') no-repeat center/cover;
  padding: 1rem;
  border-radius: 0.42rem;

  .close {
    width: 0.69rem;
    position: absolute;
    top: 0.63rem;
    right: 0.63rem;
    cursor: pointer;
    transition: transform 0.3s linear;
    opacity: 0.6;

    &:hover {
      transform: rotate(90deg);
    }
  }

}

@media screen and (max-width: $windowSize-md) {
  .popupContent {
    background: url('@/assets/home/loginbg.jpg') no-repeat center/cover;
    padding: 2rem;
    border-radius: 0.42rem;

    .close {
      width: 1.2rem;
      position: absolute;
      top: 1rem;
      right: 1rem;
      cursor: pointer;
      transition: transform 0.3s linear;
      opacity: 0.6;

      &:hover {
        transform: rotate(90deg);
      }
    }

  }
}


.show {
  background-color: rgba(0, 0, 0, 0.6);
  animation: fadeIn 0.5s;
}

.popup1Show {
  animation: zoomIn 0.5s;
}

.hidde {
  animation: fadeOut 0.5s;
}

.popup1Hidde {
  animation: zoomOut 0.5s;
}
</style>