import request from './index'

//首页轮播图
export const getBanner = () => {
    return request({
        url: '/api/home/banner',
        method: 'GET'
    })
}

//顶部最新的中奖名单
export const getNewSkin = (params) => {
    return request({
        url: '/api/home/newGetSkin',
        method: 'GET',
        loading:false,
        params
    })
}

export const getBlindBoxType = () => {
    return request({
        url: '/api/home/blindBoxType',
        method: 'GET'
    })
}


export const getBlindBox = (params) => {
    return request({
        url: '/api/home/blindBox',
        method: 'GET',
        params
    })
}


export const getStatistics = () => {
    return request({
        url: '/api/home/getStatistics',
        method: 'GET',
    })
}
