<!--
时间:2023/8/30/030
用法:左侧导航栏
-->

<template>
  <div class='leftNav _windows'>
    <div class="list">
      <div class="listItem"
           v-for="(item,index) in navList"
           :class="{
             'active':$route.path.indexOf(item.path) > -1 && item.path
           }"
           @click="goPath(item.path)"
           :key="index">
        <img :src="item.img" alt="">
        <span>{{$t(`nav.${item.name}`)}}</span>
      </div>
    </div>
  </div>
</template>

<script>

import {goPath} from "@/router/goPath";

export default {
  name: "LeftNav",
  data() {
    return {
      navList: [
        {
          name: 'blindBox',
          path: '/homes',
          img: require('../assets/icon/box@2x.png')
        },
        // {
        //   name: 'rollRoom',
        //   path: '/roll',
        //   img: require('../assets/icon/roll@2x.png')
        // },
        {
          name: 'dream',
          path: '/dreams',
          img: require('../assets/icon/Dreams@2x.png')
        },
        // {
        //   name: 'battle',
        //   path: '/battle',
        //   img: require('../assets/icon/Battles@2x.png')
        // },
       /* {
          name: 'bomb',
          path: '',
          img: require('../assets/icon/bomb@2x.png')
        },*/
      ]
    }
  },
  methods:{
    goPath
  }
}
</script>

<style scoped lang="scss">
.leftNav {
  width: var(--window-leftNav-width);
  height: calc(100% - var(--window-headerNav-height));
  overflow-y: auto;
  background-color: #1A1C24;
  display: inline-block;
  position: fixed;
  left: 0;
  top:var(--window-headerNav-height);
  z-index: 20;
  .list{
    .listItem{
      width: 4.92rem;
      height:5.08rem ;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      padding: 0.5rem;
      box-sizing: border-box;
      text-align: center;
      img{
        width: 1.67rem;
        height: 1.67rem
      }
      span{

      }
    }
    .active{
      background:url("../assets/home/navigation-bj@2x.png") no-repeat center/cover;
    }
  }
}
</style>
